import validator from 'validator';

export const youtubeLinkValidator = (input: string): boolean => {
  const youtubeRegex =
    /^(?:https?:\/\/)?(?:youtu\.be\/|(?:(www|m)\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))(.)+$/;
  // 检查链接是否匹配正则表达式
  return youtubeRegex.test(input);
};

export const tiktokLinkValidator = (input: string): boolean => {
  // tiktokRegex
  const tiktokRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:tiktok\.com\/@[\w.-]+\/video\/|vm\.tiktok\.com\/|m\.tiktok\.com\/v\/|vm\.tiktok\.com\/)([A-Za-z0-9]+)/;
  // douyinRegex
  const douyinRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:douyin\.com\/@[\w.-]+\/video\/|vm\.douyin\.com\/|m\.douyin\.com\/v\/|vm\.douyin\.com\/)([A-Za-z0-9]+)/;

  // 检查链接是否匹配正则表达式
  return tiktokRegex.test(input) || douyinRegex.test(input);
};

export const instagramReelsLinkValidator = (input: string): boolean => {
  // https://www.instagram.com/p/DEWTaKLtw_t/
  // https://www.instagram.com/reel/DEWTaKLtw_t/?utm_source=ig_web_copy_link
  // https://www.instagram.com/reels/DEWTaKLtw_t
  // 同时匹配 reel 和 reels 格式的链接
  const instagramReelsRegex =
    /https?:\/\/(www\.)?instagram\.com\/(reel|reels|p)\/[A-Za-z0-9_-]+/;
  return instagramReelsRegex.test(input);
};

export const twitterLinkValidator = (input: string): boolean => {
  const twitterRegex = /https?:\/\/(www\.)?(twitter|x)\.com\/[A-Za-z0-9_-]+/;

  // 检查链接是否匹配正则表达式
  return twitterRegex.test(input);
};

export function linkValidator(url: string): boolean {
  return validator.isURL(url);
}

export function emailValidator(email: string): boolean {
  return validator.isEmail(email);
}

export const whatAppsValidator = (input: string): boolean => {
  const reg = /^\+([1-9]{1,4})\s?(\d{1,14})$/;
  return reg.test(input);
};
