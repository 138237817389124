import React from 'react';
import { LeftIcon } from '../common/icon';

import styles from './influencerAgreement.module.less';

const boldStyle = {
  fontFamily: 'Geist-Medium',
  color: '#1C2024'
};

export const InfluencerAgreement: React.FC<{
  setIsShowAgreement: Function;
}> = ({ setIsShowAgreement }) => {
  return (
    <div>
      <div className={styles.drawerTitle}>
        Creator Agreement{' '}
        <LeftIcon
          className={styles.closeIcon}
          onClick={() => setIsShowAgreement(false)}
        />
      </div>
      <div className={styles.desc}>
        <p className={styles.text}>
          The Creator Agreement (hereinafter referred to as this "Agreement")
          shall constitute a legally binding electronic agreement between
          Ahalab.ai Inc. (hereinafter referred to as "Party A") and the Creator
          (hereinafter referred to as "Party B") to regulate the rights and
          obligations of both parties. If the Creator is finally selected by
          Party A to become the participant of the Campaign, this Agreement
          shall become effective immediately after Party A issues a confirmation
          notice to the Creator.{' '}
          <span style={boldStyle}>
            Please note that the articles in this Agreement which have a vital
            relationship with the rights and interests of the Creator have been
            distinguished by bold font. Please refer to the articles carefully.
          </span>
        </p>

        <div className={styles.subTitle}>WHEREAS:</div>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            Party A is a scientific and technological company specializing in
            marketing and product promotion, and is committed to finding
            high-quality creators for product suppliers/brands to provide
            product promotion services.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            Party B has a certain social media influence and can increase the
            exposure of the promoted products and attract potential consumers.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>3.</div>
          <p className={styles.text}>
            Both parties wish to establish a cooperative relationship to
            increase the exposure of the promoted products.
          </p>
        </div>
        <p className={styles.paragraph}>
          NOW, THEREFORE, the Parties hereby enter into this Agreement with the
          following terms and conditions.
        </p>

        <div className={styles.subTitle}>ARTICLE 1 – DEFINITIONS</div>
        <p className={styles.text}>
          Within this Agreement, unless the context otherwise requires, the
          following terms shall have the following meanings:
        </p>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            SNS Platform Account: Social Network Service Platform Account,
            specifically referring to internet application service platforms
            aimed at helping people build social networks, including but not
            limited to Facebook, TikTok, Instagram, Twitter, YouTube, LinkedIn,
            etc.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            Promotion Platform: refers to Party A's own or third-party platforms
            or channels that cooperate with Party A, through which Party B may
            post or launch the Creative Content.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>3.</div>
          <p className={styles.text}>
            Designated Platform: refers to the platform that is explicitly
            required to post the Creative Content in the Campaign.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>4.</div>
          <p className={styles.text}>
            Promoted Products: refers to products, services, or content for
            which Party A has legal authorization and is entitled to entrust
            Party B to provide promotion services.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>5.</div>
          <p className={styles.text}>
            Creative Content: refers to videos, audios, texts, or images created
            by Party B for promoting the products specified in this agreement,
            including but not limited to scripts, drafts, process drafts, and
            final versions.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>6.</div>
          <p className={styles.text}>
            Posting or Launching: refers to Party B publishing the Creative
            Content on the designated Promotion Platform through its own SNS
            Platform Account.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>7.</div>
          <p className={styles.text}>
            Product Supplier: refers to the producers, manufacturers, sales or
            agents of the product, who are the actual providers of the Promoted
            Products and hope to increase the exposure of the Promoted Products
            through marketing to attract more consumers or users.
          </p>
        </div>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>8.</div>
          <p className={styles.text}>
            Campaign: refers to the specific activities announced by Party A and
            voluntarily signed up by Party B.
          </p>
        </div>

        <div className={styles.subTitle}>ARTICLE 2 – COOPERATION PERIOD</div>
        <div className={styles.lastTextCtn}>
          <p className={styles.text}>
            In principle, the cooperation period shall commence on the Effective
            Date of this Agreement and shall remain in effect for [three]
            months. If services hereunder are completed in advance, the
            cooperation period will expire upon completion of the services.
          </p>
        </div>

        <div className={styles.subTitle}>ARTICLE 3 – COOPERATION CONTENT</div>
        <div className={styles.lastTextCtn}>
          <p className={styles.text}>See the Campaign for details. </p>
        </div>

        <div className={styles.subTitle}>
          ARTICLE 4 – NO EMPLOYMENT RELATIONSHIP
        </div>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            Nothing contained within this Agreement shall be construed to form
            any partnership, joint venture, agency, franchise, or employment
            relationship. Party B is an independent contractor and will, at all
            times, act as such.
          </p>
        </div>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            Party B shall be solely responsible for paying all taxes assessed or
            imposed on it under applicable laws arising from or related to this
            agreement. Party A shall not withhold any taxes from the fees
            payable to Party B, unless otherwise expressly agreed.
          </p>
        </div>

        <div className={styles.subTitle}>
          ARTICLE 5 – PARTY A’S RIGHTS AND OBLIGATIONS
        </div>
        <div className={styles.firstTextCtn} style={boldStyle}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            All copyright and other intellectual property rights, other rights,
            and interest in the materials (including but not limited to text,
            images, music, audio and video, if applicable) provided by Party A
            under this agreement, is the sole and exclusive property of Party A.
          </p>
        </div>
        <div className={styles.textCtn} style={boldStyle}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            By providing, delivering, and transmitting the Creative Content to
            Party A, Party B acknowledges and agrees <br />
            that:
          </p>
        </div>
        <div className={styles.subTextCtn} style={boldStyle}>
          <div className={styles.label}>2.1.</div>
          <p className={styles.text}>
            Party A owns the intellectual property rights and title of the
            content created by Party B. Party A is licensed to use Party B's
            image, portrait, name, voice, and other rights relating to the
            personality of Party B contained in the Creative Content.{' '}
          </p>
        </div>
        <div className={styles.subTextCtn} style={boldStyle}>
          <div className={styles.label}>2.2.</div>
          <p className={styles.text}>
            Party A is granted an irrevocable and worldwide license to
            reproduce, use, display, modify, distribute and create any
            derivative works from the Creative Content, with the right to
            sublicense such right to any third party designated by Party A for
            advertising, marketing, promotion, and any other commercial
            purposes.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>3.</div>
          <p className={styles.text}>
            During the cooperation period of this agreement, Party A has the
            right to provide suggestions or guidance on the Creative Content
            provided, delivered, and transmitted by Party B, including but not
            limited to content planning suggestions, copywriting topic
            suggestions, video editing support. Party A also has the right to
            standardize the editing requirements, including but not limited to
            formatting, opening and closing credits, subtitle specifications,
            etc. If Party B fails to meet Party A's requirements, Party A has
            the right to choose not to post, launch or release it publicly or
            regard Party B as failing to meet the cooperation requirements.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>4.</div>
          <p className={styles.text}>
            Party A has the right to request Party B to display the signs of the
            company belonging to the Product Supplier in the Creative Content,
            including but not limited to the company logo, etc.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>5.</div>
          <p className={styles.text}>
            During the cooperation period of this agreement, if there is any
            negative publicity or unfavorable news about Party B on any of the
            SNS platform, Party B shall try to eliminate the negative impact.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>6.</div>
          <p className={styles.text}>
            During the cooperation period of this agreement, Party A has the
            right to adjust or postpone the post launch time but shall notify
            Party B in writing (such as by email) 3 working days in advance. If
            Party A has fulfilled the obligation to notify in advance and Party
            B fails to complete the adjustment within the reserved time, the
            related costs and responsibilities arising therefrom shall be borne
            by Party B.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>7.</div>
          <p className={styles.text}>
            If Party B is selected by Party A as the participant of the Campaign
            and its Creative Content satisfies various requirements of the
            Campaign, Party A shall settle and pay remuneration to Party B in a
            timely manner based on the commitment of the Campaign.
          </p>
        </div>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>8.</div>
          <p className={styles.text}>
            If the promotion activity agreed in this agreement fails to proceed
            on time due to Party B (including but not limited to wrong posting,
            missed posting), Party A has the right to choose to change the post
            launch time or cancel the promotion service that was not performed
            on time. If Party A chooses to cancel the promotion, Party B shall
            bear the liability for breach of contract.
          </p>
        </div>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>9.</div>
          <p className={styles.text}>
            If the promotion activity agreed in this agreement fails to proceed
            on time due to Party B (including but not limited to wrong posting,
            missed posting, failure to submit scripts, drafts, or other
            materials by the required deadlines), Party A has the right to
            choose to change the post launch time or cancel the promotion
            service that was not performed on time. If Party A chooses to cancel
            the promotion, Party B shall return the advance payment made by
            Party A and compensate Party A for any losses incurred.
          </p>
        </div>

        <div className={styles.subTitle}>
          ARTICLE 6 – PARTY B’S RIGHTS AND OBLIGATIONS
        </div>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            Party B has the right to timely receive the remuneration in
            accordance with Article 7 of this Agreement.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            Party B shall create content in accordance with the Campaign
            requirements specified in Article 3 and improve it based on the
            suggestions or requirements of Party A. Before the Creative Content
            being posted, Party B shall provide, deliver, or transmit the script
            or draft to Party A within the agreed time for confirmation. Where
            available, Party B shall also upload the script or draft to the
            Designated Platform within the agreed time and send the related link
            to Party A for confirmation.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>3.</div>
          <p className={styles.text}>
            The Creative Content posted by Party B shall comply with the
            platform rules of the Designated Platform.{' '}
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>4.</div>
          <p className={styles.text}>
            Party B confirms and guarantees the Creative Content it provided,
            delivered, or transmitted, shall comply with the applicable local
            laws, regulations, and policies and shall not contain any illegal
            content. Party B guarantees that the Creative Content does not
            infringe the rights of third parties, including without limitation,
            intellectual property rights, trade secrets, rights and interests
            relating to personality and rights of privacy. Party B guarantees
            that the Creative Content has not been copied or plagiarized from a
            third party's work, including but not limited to, not directly
            copying a third party's video script, not plagiarizing a third
            party's video's script, characters and settings, plots, lines, flow
            design and sequences, etc., and not using without authorization, any
            third party’s copyrighted text, character, pictures, photographs,
            music, and any other copyrightable content or elements.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>5.</div>
          <p className={styles.text}>
            Party B undertakes to assume all responsibilities for any loss,
            damage and obligations caused by Creative Content’s violation of
            laws or regulation, infringement of third party’s rights, disputes
            arising thereof. For any losses caused to Party A, Party B shall
            make full compensation to Party A and ensure Party A takes no
            responsibility and assumes no liability arising from or relating to
            the Creative Content.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>6.</div>
          <p className={styles.text}>
            Party B shall ensure compliance with applicable laws and regulations
            and Party A's relevant requirements, and refrain from any illegal or
            criminal activities. Party B shall strictly exercise
            self-discipline, ensure no serious bad habits, abide by professional
            ethics, public order and good customs, and improve moral
            cultivation.
          </p>
        </div>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>7.</div>
          <p className={styles.text}>
            Party B has full civil capacity, legal capacity, and sufficient
            authorization to enter into and perform this agreement in accordance
            with its contents. Party B agrees with Party A on the content of
            this Agreement and voluntarily signs and performs this Agreement.
          </p>
        </div>

        <div className={styles.subTitle}>ARTICLE 7 – FEES AND PAYMENT</div>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            The service fees and payment terms shall be subject to the
            information displayed in the Campaign.
          </p>
        </div>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            For the service fees mentioned in Article 7 of this Agreement, Party
            A and Party B shall respectively bear the taxes payable according to
            the applicable law.
          </p>
        </div>

        <div className={styles.subTitle}>
          ARTICLE 8 – CONFIDENTIALITY OBLIGATION
        </div>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            The content of this Agreement, as well as all information known to
            the other party including but not limited to personnel, finance,
            partners, etc. during the signing and performance of this agreement,
            are confidential information. The receiving party shall use the
            Confidential Information only for the purpose of this Agreement, and
            shall not use, disclose, copy or replicate all or part of the
            Confidential Information for purposes other than the performance of
            the Services in this Agreement. The confidentiality period starts
            from the effective date of this Agreement and ends on the date when
            the Confidential Information becomes public information (that is,
            disclosed to or becomes known to the public). The confidentiality
            obligation shall not be terminated by the suspension, termination or
            discharge of this Agreement.
          </p>
        </div>
        <div className={styles.textCtn}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            The confidentiality obligations under this Agreement do not apply to
            any information which:
          </p>
        </div>
        <p className={styles.li}>
          {' '}
          is lawfully in possession of the Receiving Party prior to receipt from
          the Disclosing Party;
        </p>
        <p className={styles.li}>
          {' '}
          is or becomes publicly known, other than as a consequence of a breach
          of this Agreement;
        </p>
        <p className={styles.li}>
          {' '}
          is received from a third party without breach of any other relevant
          confidentiality obligation binding on such third party;
        </p>
        <p className={styles.li}>
          {' '}
          is disclosed with the consent of the Disclosing Party.
        </p>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>3.</div>
          <p className={styles.text}>
            If Party B violates the provisions of this Clause and causes Party A
            to suffer losses, Party B shall pay Party A corresponding
            compensation.
          </p>
        </div>

        <div className={styles.subTitle}>ARTICLE 9 – LIABILITY FOR BREACH</div>
        <div className={styles.lastTextCtn}>
          <p className={styles.text}>
            In the event of a breach of this Agreement, without prejudice to the
            rights of the non-defaulting Party set out in other provisions of
            this Agreement, the defaulting Party shall be liable to compensate
            the non-defaulting Party for any losses, damages, costs, expenses
            (including but not limited to reasonable attorney’s fee),
            liabilities or claims that the non-defaulting Party may suffer as a
            result of such breach.
          </p>
        </div>

        <div className={styles.subTitle}>
          ARTICLE 10 – DISSOLUTION AND TERMINATION OF THE AGREEMENT
        </div>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            This Agreement may be changed or terminated in advance by consensus
            between Party A and Party B.
          </p>
        </div>
        <div className={styles.textCtn} style={boldStyle}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            Party A may unilaterally terminate this Agreement at any time
            without any liability for breach of contract if any of the following
            circumstances occurs:
          </p>
        </div>
        <div className={styles.subTextCtn} style={boldStyle}>
          <div className={styles.label}>2.1.</div>
          <p className={styles.text}>
            At any time between the signing date of this Agreement and the
            Posting or Launching date of the Creative Content (including that
            date), the SNS Platform Account is obviously abnormal, blocked, or
            the exposure of which is restricted by the Platform due to Party B’s
            reason.
          </p>
        </div>
        <div className={styles.subTextCtn} style={boldStyle}>
          <div className={styles.label}>2.2.</div>
          <p className={styles.text}>
            If Party B's Creative Content is in violation of this Agreement, and
            Party B does not modify or adjust it according to Party A's
            requirements after Party A’s requests for correction and adjustment,
            Party A considers that Party B is not suitable to continue to
            perform this Agreement.
          </p>
        </div>
        <div className={styles.textCtn} style={boldStyle}>
          <div className={styles.label}>3.</div>
          <p className={styles.text}>
            This Agreement shall be terminated if one of the following
            circumstances occurs:
          </p>
        </div>
        <div className={styles.subTextCtn}>
          <div className={styles.label}>3.1.</div>
          <p className={styles.text}>
            If Party B is unable to continue to perform the Agreement due to
            illness or disability, mental problem, etc., Party A has the right
            to terminate this Agreement at any time without any breach of
            contract liability.
          </p>
        </div>
        <div className={styles.subTextCtn}>
          <div className={styles.label}>3.2.</div>
          <p className={styles.text}>
            If Party B is suspected of violating the law or committing a crime,
            Party A may unilaterally terminate this Agreement without assuming
            any breach of contract responsibility.
          </p>
        </div>
        <div className={styles.subTextCtn}>
          <div className={styles.label}>3.3.</div>
          <p className={styles.text}>
            If Party B fails to complete the relevant matters within the time
            specified in the Campaign for any reason, Party A may unilaterally
            terminate this Agreement without any breach of contract
            responsibility.
          </p>
        </div>
        <div className={styles.lastSubTextCtn}>
          <div className={styles.label}>3.4.</div>
          <p className={styles.text}>
            If all or part of the Contract cannot be implemented due to force
            majeure or relevant laws, regulations and policies, Party A and
            Party B may terminate the Contract through negotiation.
          </p>
        </div>

        <div className={styles.subTitle}>
          ARTICLE 11 – APPLICATION OF LAW AND DISPUTE RESOLUTION
        </div>
        <div className={styles.firstTextCtn}>
          <div className={styles.label}>1.</div>
          <p className={styles.text}>
            The formation, entry into force, performance, interpretation and
            dispute resolution of this Agreement shall be governed by the laws
            of the United States.
          </p>
        </div>
        <div className={styles.lastTextCtn}>
          <div className={styles.label}>2.</div>
          <p className={styles.text}>
            Any dispute arising from this Agreement and the Cooperation
            Agreement shall be settled by Party A and Party B through friendly
            negotiation. If the dispute cannot be settled through negotiation,
            either party shall have the right to file a lawsuit to the court of
            the place where Party A is located. The reasonable expenses, such as
            attorney fees and travel expenses, incurred by either party shall be
            borne by the losing party.
          </p>
        </div>

        <div className={styles.subTitle}>ARTICLE 12 – MISCELLANEOUS</div>
        <div className={styles.lastTextCtn}>
          <p className={styles.text}>
            For any matters not covered in this Agreement, Party A and Party B
            shall sign a separate supplementary agreement to determine such
            matters. In case of inconsistency between the supplementary
            agreement and this Agreement, the supplementary agreement shall
            prevail. The invalidity or pending effect of some provisions of this
            Agreement shall not affect the implementation of other provisions.
          </p>
        </div>
      </div>
    </div>
  );
};
