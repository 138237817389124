import React, { useEffect, useState } from 'react';
import { normalizePrice } from '@/utils/normalizePrice';
import { OrderStatus } from '@/interface/order';
import Big from 'big.js';

const PaymentInfo: React.FC<{ data: any }> = ({ data }) => {
  const { order } = data;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (order) {
      setLoading(false);
    }
  }, [order]);

  if (loading) return <PaymentInfoSkeleton />;

  // 价格 预付款 尾款 ，金额计算可能需要用 big.js
  let price = order?.price || 0;
  let prePaymentAmount = order?.prePaymentAmount || 0;
  let postDeliveryPaymentAmount =
    Big(price).minus(prePaymentAmount).toNumber() || 0;

  // Invited
  if (order?.status === OrderStatus.Invited && order?.priceFields?.[0]?.price) {
    const creatorPriceObj = order?.priceFields?.[0];
    price = creatorPriceObj?.price || 0;
    prePaymentAmount = Math.ceil(Big(price).times(0.5).toNumber()) || 0;
    postDeliveryPaymentAmount =
      Big(price).minus(prePaymentAmount).toNumber() || 0;
  }

  // 如果 creator 已经报价，则使用 creator 的报价
  if (
    (order?.status === OrderStatus.CreatorApplied ||
      order?.status === OrderStatus.ApplyRejected ||
      order?.status === OrderStatus.Cancelled) &&
    order?.interestedPriceFields?.length > 0
  ) {
    const creatorPriceObj = order.interestedPriceFields[0];
    price = creatorPriceObj?.price || 0;
    prePaymentAmount = Math.ceil(Big(price).times(0.5).toNumber()) || 0;
    postDeliveryPaymentAmount =
      Big(price).minus(prePaymentAmount).toNumber() || 0;
  }

  return (
    <>
      <div className="text-[26px] font-semibold font-['Geist'] leading-10 mt-10 mb-4">
        Payment info
      </div>

      <div className="w-full  flex-col justify-start items-start gap-2 inline-flex">
        <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
          <div className="text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
            Total payment
          </div>
          <div className="text-right text-base font-medium font-['Inter'] leading-normal">
            {normalizePrice(price)}
          </div>
        </div>
        <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
          <div className="text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
            50% upfront payment
          </div>
          <div className="text-right text-base font-medium font-['Inter'] leading-normal">
            {normalizePrice(prePaymentAmount)} paid after application approval
          </div>
        </div>
        <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
          <div className="text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
            50% post-delivery payment
          </div>
          <div className="text-right text-base font-medium font-['Inter'] leading-normal">
            {normalizePrice(postDeliveryPaymentAmount)} paid within 1 week of
            posting
          </div>
        </div>
        <div className="self-stretch h-[72px] p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] flex-col justify-center items-start flex">
          <div className="self-stretch justify-between items-center inline-flex">
            <div className="text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
              Payment method
            </div>
            <div className="flex-col justify-start items-end inline-flex">
              <div className="justify-end items-center gap-2 inline-flex">
                <div className="text-right text-base font-medium font-['Inter'] leading-normal">
                  Bank Transfer or Paypal
                </div>
              </div>
              <div className="text-xs">
                <span className="text-[#60646c] font-normal font-['Inter'] leading-none">
                  Fees may apply.{' '}
                </span>
                <a
                  target="_blank"
                  className="ml-1 underline text-[#0c67e6]"
                  href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInfo;

const PaymentInfoSkeleton = () => {
  return (
    <>
      <div className="text-[26px] font-semibold font-['Geist'] leading-10 mt-10 mb-4">
        Payment info
      </div>
      <div className="flex flex-col gap-2">
        <div className="h-[20px] w-[203px] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[203px] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
      </div>
    </>
  );
};
