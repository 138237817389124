import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '@/biz/tool';
import WebModal from '@/components/web/components/Modal';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { useOrderListStore } from '@/hooks/useOrderListStore';
import ProductLogo from '@/components/web/components/ProductLogo';
import Button from '@material-ui/core/Button';
import { normalizePriceUsd } from '@/utils/normalizePriceUsd';

const ModalSelectPlatform: React.FC<{}> = () => {
  const { showSelectInvitedOrderModal, setShowSelectInvitedOrderModal } =
    useWebModalStore();
  const { orderListObject } = useOrderListStore();
  const { invites = [] } = orderListObject || {};

  return (
    <>
      <WebModal
        title={
          <div
            className={`text-[#1c2024] font-semibold font-['Geist'] leading-loose
            ${isMobile() ? 'text-xl' : 'text-2xl'}`}
          >
            More exclusive invitations tailored for you
          </div>
        }
        visible={showSelectInvitedOrderModal}
        isShowFooter={false}
        width={isMobile() ? 402 : 624}
        handleClose={() => {
          setShowSelectInvitedOrderModal(false);
        }}
        canClose={false}
        keepMounted={true}
      >
        <div
          className={`w-full flex-col justify-start items-start inline-flex  overflow-y-auto
            ${isMobile() ? 'max-h-[70vh]' : 'max-h-[80vh]'}`}
        >
          <div className="text-[#60646c] text-xs mb-6">
            Review the invitation details and unlock incredible opportunities!
            Remember, don’t wait too long—competition can become fierce, so act
            quickly to secure your next big collaboration.
          </div>
          <div
            className={`w-full grid gap-x-8 gap-y-3 mt-2 mb-10
              ${isMobile() ? 'grid-cols-1' : 'grid-cols-1'}`}
          >
            {invites?.map((item: any) => (
              <div
                className={`min-h-24 p-4 bg-[#f7f8ff] rounded-lg justify-between items-center inline-flex
                  ${isMobile() ? 'w-full' : 'w-[560px]'}`}
              >
                <div className="justify-start items-center gap-4 flex">
                  <ProductLogo src={item?.campaign?.product?.logo} size={64} />
                  <div className="flex-col justify-start items-start gap-1 inline-flex flex-1">
                    <div className="self-stretch justify-start items-center gap-1 inline-flex">
                      <div className="text-[#1c2024] text-base font-semibold font-['Plus Jakarta Sans'] leading-snug">
                        {normalizePriceUsd(item?.priceFields?.[0]?.price)}
                      </div>
                    </div>
                    <div className="self-stretch justify-start items-center gap-2 inline-flex">
                      <div className="justify-start items-start flex flex-wrap">
                        <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
                          {item?.campaign?.product?.name}
                        </div>
                        <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
                          ｜
                        </div>
                        <a
                          className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight break-all hover:text-[#0c67ef]"
                          href={item?.campaign?.product?.website}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item?.campaign?.product?.website}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  variant="contained"
                  className={`mui-btn-black !w-[77px] !max-w-[77px] !h-[32px] !px-0 flex-shrink-0`}
                  onClick={() => {
                    // 关闭弹窗
                    setShowSelectInvitedOrderModal(false);
                    window.location.href = `/campaign/invites/detail/${item?.id}`;
                  }}
                >
                  View
                </Button>
              </div>
            ))}
          </div>
        </div>
      </WebModal>
    </>
  );
};

export default ModalSelectPlatform;
