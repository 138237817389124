export const getTimezoneName = (timestamp: string | Date) => {
  // console.log(timestamp);
  const today = new Date(timestamp);
  const short = today.toLocaleDateString('en-US');
  const full = today.toLocaleDateString('en-US', { timeZoneName: 'long' });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed =
      full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
};

export const formatDateWithSuffix = (time: string | Date) => {
  const date = new Date(time);
  const day = date.getDate();
  // 获取日期的数字部分和序数后缀
  let suffix = 'th'; // 默认后缀
  if (day % 10 === 1 && day !== 11) {
    suffix = 'st';
  } else if (day % 10 === 2 && day !== 12) {
    suffix = 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    suffix = 'rd';
  }

  // 使用 toLocaleDateString 获取格式化日期
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  // 使用 toLocaleTimeString 获取格式化时间
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });

  // 格式化日期部分，替换数字日期部分，添加序数后缀
  const formattedDateWithSuffix = formattedDate.replace(
    /\d+/,
    (match) => match + suffix
  );

  // 合并日期和时间部分
  return `${formattedDateWithSuffix} ${formattedTime}`;
};

export const getTime = (timestamp: string | Date) => {
  const today = new Date(timestamp);
  const time = today
    .toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })
    .replace(',', '')
    .replace(/(\d+)\/(\d+)\/(\d+)(.*)/, '$2/$1/$3 at$4');

  return time;
  // 输出格式: '05/10/2024 at 9:00 AM'
};

export const getDuration = (hours: number) => {
  // if (hours > 48) return `${Math.ceil(hours / 24)} days`;
  // if (hours > 1) return `${Math.ceil(hours)} hours`;
  // return `${Math.ceil(hours)} hour`;
  if (hours === 24) return `1 day`;
  if (hours > 24) return `${Math.ceil(hours / 24)} days`;
  if (hours > 1) return `${hours} hours`;
  return `${Math.ceil(hours / 24)} day`;
};

export const getDurationFormatBusinessDays = (hours: number) => {
  if (hours === 24) return `1 business day`;
  if (hours > 24) return `${Math.ceil(hours / 24)} business days`;
  if (hours > 1) return `${hours}  hours`;
  return `${Math.ceil(hours / 24)} business days`;
};

// 使用 toLocaleDateString 获取格式化日期
export const formattedDate = (time: string | Date) => {
  const date = new Date(time);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};
