import React, { useEffect, useState } from 'react';
import { isMobile } from '@/biz/tool';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import styles from './notices.module.less';

const Notices: React.FC<{
  title: string;
  desc: string | React.ReactNode;
  backgroundColor?: string;
  isShowClose?: boolean;
  Icon: any;
  marginTop?: number;
  marginBottom?: number;
  handleClose?: () => void;
}> = ({
  title,
  desc,
  isShowClose,
  Icon,
  marginTop = 0,
  marginBottom = 0,
  handleClose,
  backgroundColor = '#e4e7ff'
}) => {
  return (
    <div
      className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
      style={{ backgroundColor, marginTop, marginBottom }}
    >
      <div className={styles.logo}>
        <Icon />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
      </div>
      {isShowClose && (
        <div className={styles.close} onClick={handleClose}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default Notices;
