import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

import styles from './modal.module.less';

interface Props {
  children: any;
  width?: number;
  title?: string | React.ReactNode;
  isShowHeader?: boolean;
  isShowFooter?: boolean;
  visible: boolean;
  submitDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  handleClose?: () => void;
  handleSubmit?: () => void;
  okText?: string;
  cancelText?: string;
  keepMounted?: boolean;
  canClose?: boolean;
}

const WebModal: React.FC<Props> = ({
  children,
  title,
  visible,
  width = 624,
  isShowHeader = true,
  isShowFooter = true,
  submitDisabled,
  isLoading,
  className,
  handleClose,
  handleSubmit,
  okText = 'Submit',
  cancelText = 'Cancel',
  keepMounted = false,
  canClose = false
}) => {
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={visible}
      onClose={canClose && handleClose ? handleClose : undefined}
      keepMounted={keepMounted}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      disablePortal
    >
      <div
        className={`bg-white relative rounded-[16px] left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center items-center ${styles.modalContainer} ${className}`}
        style={{ width: width + 'px' }}
      >
        {isShowHeader && (
          <div className={styles.modalHead}>
            <div className={styles.title}>{title}</div>
            <div className={styles.close} onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
        )}
        {children}
        {isShowFooter && (
          <div className={styles.footer}>
            <Button className={styles.cancel} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={submitDisabled || isLoading}
              className={`${styles.submit} ${submitDisabled || isLoading ? styles.submitDisabled : ''}`}
              onClick={handleSubmit}
            >
              {isLoading && (
                <CircularProgress className={styles.loading} disableShrink />
              )}
              {okText ? (
                okText
              ) : (
                <span>{isLoading ? 'Submitting' : 'Submit'}</span>
              )}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WebModal;
