import React, { useEffect } from 'react';
import {
  getClerkSignInRedirectUrl,
  getClerkSignupRedirectUrl
} from '@/biz/tool';
import { useClerk, useAuth } from '@clerk/clerk-react';

interface Props {
  visible?: boolean;
  onClose?: () => void;
}

const LoginModal: React.FC<Props> = ({ visible, onClose }) => {
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn } = useAuth();

  useEffect(() => {
    // 非订单页面
    if (isLoaded && !isSignedIn) {
      openSignIn({
        withSignUp: true,
        forceRedirectUrl: getClerkSignInRedirectUrl(),
        fallbackRedirectUrl: getClerkSignInRedirectUrl(),
        // 注册
        signUpForceRedirectUrl: getClerkSignupRedirectUrl(),
        signUpFallbackRedirectUrl: getClerkSignupRedirectUrl(),
        //
        appearance: {
          elements: {
            // 按钮隐藏
            modalCloseButton: 'hidden',
            // 蒙版不可点击
            modalBackdrop: 'pointer-events-none',
            // 内容可点击
            modalContent: 'pointer-events-auto'
          }
        }
      });
    }
  }, [isLoaded, isSignedIn]);

  return null;
};

export default LoginModal;
