import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type OrderStore = {
  orderIds: any;
  setOrderIds: (orderIds: any) => void;
};

export const useOrderIdStore = create(
  persist<OrderStore>(
    (set) => ({
      orderIds: null,
      setOrderIds: (orderIds: any) => set({ orderIds })
    }),
    {
      name: 'orderId-list' // 存储的唯一名称
    }
  )
);
