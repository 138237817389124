import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { OrderStatus } from '@/interface/order';
import { isMobile } from '@/biz/tool';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import InterestContent from './InterestContent';
import ModalSelectContentPrice from './ModalSelectContentPrice';
import ModalAddress from './ModalAddress';

const ModalShowAnInterest: React.FC<{ data: any }> = ({ data }) => {
  const { order, product, campaign } = data;
  const [showSelectContentPrice, setShowSelectContentPrice] = useState(false);
  const [showAnInterestOpen, setShowAnInterestOpen] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);

  if (![OrderStatus.Invited].includes(order?.status)) {
    return null;
  }

  return (
    <>
      <>
        <Button
          variant="contained"
          className={`mui-btn-primary ${isMobile() ? '!h-10' : ''}`}
          onClick={() => {
            if (order?.priceFields?.length) {
              // 多价格模式 new
              setShowSelectContentPrice(true);
              return;
            } else {
              // 单价格模式 old
              setShowAnInterestOpen(true);
            }
          }}
        >
          I’m interested
        </Button>
        {/* 需要先选择内容类型 */}
        <ModalSelectContentPrice
          isOpen={showSelectContentPrice}
          setIsOpen={setShowSelectContentPrice}
          setShowAnInterestOpen={setShowAnInterestOpen}
          setShowAddressModal={setShowAddressModal}
          product={product}
        />
        {/* 实体广告主的达人需要填写地址 */}
        <ModalAddress
          isOpen={showAddressModal}
          setIsOpen={setShowAddressModal}
          setShowAnInterestOpen={setShowAnInterestOpen}
          campaign={campaign}
        />

        {isMobile() ? (
          <BottomDrawer
            title="Confirm your interest"
            visible={showAnInterestOpen}
            handleClose={() => setShowAnInterestOpen(false)}
            isShowFooter={false}
          >
            <InterestContent
              data={data}
              setShowAnInterestOpen={setShowAnInterestOpen}
            />
          </BottomDrawer>
        ) : (
          <WebModal
            title="Confirm your interest"
            visible={showAnInterestOpen}
            handleClose={() => setShowAnInterestOpen(false)}
            isShowFooter={false}
          >
            <InterestContent
              data={data}
              setShowAnInterestOpen={setShowAnInterestOpen}
            />
          </WebModal>
        )}
      </>
    </>
  );
};

export default ModalShowAnInterest;
