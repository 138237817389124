import React, { useEffect, useState, useCallback } from 'react';
import { getOrderId } from '@/biz/tool';
import toast from 'react-hot-toast';
import { useRequest } from 'ahooks';
import { isMobile } from '@/biz/tool';
import { useOrderIdStore } from '@/hooks/orderIdStore';

import { OrderStatus } from '../../../interface/order';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import SubmitModalContent from './SubmitModalContent';
import SuccessModal from './SuccessModal';
import axiosInstance from '../../../apis/axiosInstance';
import { submitTextMap, SubmitStatusEnum, PostTimeType } from '../config';
import confetti from 'canvas-confetti';
import { getPlatformName } from '@/biz/platform';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';

interface Props {
  order: any;
  user: any;
  submitStatus: string;
  refreshAllData: Function;
  disabled?: boolean;
  postTimeType?: string;
  postTimeRange?: number[];
  handleRequirement?: Function;
}

const SubmitButton: React.FC<Props> = ({
  order,
  user,
  submitStatus,
  refreshAllData,
  disabled,
  postTimeType,
  postTimeRange,
  handleRequirement
}) => {
  const orderId = getOrderId();
  const [isVisible, setIsVisible] = useState(false);
  const [link, setLink] = useState('');
  const [desc, setDesc] = useState('');
  const [formIdea, setFormIdea] = useState('');
  const [isCanSubmit, setIsCanSubmit] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [isShowSuccessModal, setShowSuccessModal] = useState(false);
  const [isVideoSuccess, setVideoSuccess] = useState(false);

  const handleClick = () => {
    setIsVisible(true);
  };

  // draft提交
  const handleVideoSubmit = async (type?: string) => {
    try {
      await axiosInstance.post(`/order/${orderId}/draft`, {
        url: link,
        note: desc,
        creativeId: formIdea
      });
      setIsVisible(false);
      toast.success('Submit successfully.');
      return;
    } catch (error: any) {
      if (type === 'script') {
        toast.error(error?.message || 'Failed to submit script.');
        console.error(
          `Failed to fetch : (/order/${orderId}/draft - script) \n`,
          error
        );
      } else {
        toast.error(error?.message || 'Failed to submit content.');
        console.error(
          `Failed to fetch : (/order/${orderId}/draft - draft) \n`,
          error
        );
      }
    } finally {
      setLoading(false);
      // 刷新页面数据
      refreshAllData(orderId);
    }
  };

  const [errorTitle, setErrorTitle] = useState<string>('');
  const { orderIds, setOrderIds } = useOrderIdStore();
  // 1s 一个轮询  5次
  const getFinalLinkStatus = async () => {
    setErrorTitle('');
    const {
      data: { data }
    } = await axiosInstance.get(`/order/detail/${orderId}`);

    if (data.order.status === OrderStatus.PendingPayment) {
      cancel();
      setLoading(false);
      console.log('success');
      if (isMobile()) {
        // 成功 撒花 移动端
        confetti({
          particleCount: 200,
          spread: 60,
          origin: { y: 0.6 },
          zIndex: 9999
        });
      } else {
        // 成功 撒花 pc
        confetti({
          particleCount: 200,
          spread: 200,
          origin: { y: 0.4 },
          zIndex: 9999
        });
      }

      // 关闭弹窗
      setIsVisible(false);
      // 展示提交成功弹窗
      setVideoSuccess(false);
      setShowSuccessModal(true);
      // 清空当前orderId下的缓存
      setOrderIds({
        ...orderIds,
        [orderId]: null
      });
    }

    if (data.order.status === OrderStatus.PublishRejected) {
      setLoading(false);
      console.log('failed');
      if (
        data.order?.extraInfo?.extraMessage ===
        'Please check whether your link can be accessed.'
      ) {
        setErrorTitle('Please check whether your link can be accessed.');
        // setErrorTitle('Video does not exist');
      } else if (
        data.order?.extraInfo?.extraMessage ===
        'Please submit a link from the account associated with this Aha collaboration.'
      ) {
        setErrorTitle(
          'Please submit a link from the account associated with this Aha collaboration.'
        );
        // setErrorTitle('Incorrect YouTube account');
      } else if (
        data.order?.extraInfo?.extraMessage ===
        "Please check if you've submitted the correct video with the promotional link in description."
      ) {
        setErrorTitle(
          "Please check if you've submitted the correct video with the promotional link in description."
        );
        // setErrorTitle('Promotional link not detected');
      } else {
        setErrorTitle('Connection timed out');
      }

      cancel();
    }
  };

  const [pollingCount, setPollingCount] = useState(0); // 用于跟踪轮询次数

  const { run: runQueryLinkStatus, cancel } = useRequest(
    useCallback(() => {
      if (pollingCount < 5) {
        setPollingCount((prevCount) => prevCount + 1); // 增加轮询次数
        return getFinalLinkStatus();
      }
      // 五次还没有返回数据
      // 弹窗重新提交
      if (isLoading) {
        setLoading(false);
        setErrorTitle('Connection timed out');
      }
      cancel();
      return Promise.resolve(null); // 超过5次后不再发起请求
    }, [pollingCount]),
    {
      pollingInterval: 1000,
      pollingWhenHidden: false,
      manual: true
    }
  );

  // final link 提交
  const handleFinalLinkSubmit = async () => {
    setPollingCount(0);
    setErrorTitle('');
    try {
      await axiosInstance.put(`/order/${orderId}/promotionalLink`, {
        promotionalLink: link
      });
      // 轮询 finalLink 校验接口
      setTimeout(() => {
        runQueryLinkStatus();
      }, 1000);
    } catch (error: any) {
      setLoading(false);
      setErrorTitle(`${getPlatformName(order?.platform)} Link Upload Failed.`);

      toast.error(error?.message || 'Failed to submit final link.');
      console.error(
        `Failed to fetch : (/order/${orderId}/promotionalLink) \n`,
        error
      );
    }
  };

  const handleSubmit = () => {
    if (!isCanSubmit) {
      return;
    }
    setLoading(true);
    if (submitStatus === SubmitStatusEnum.video) {
      handleVideoSubmit();
    } else if (submitStatus === SubmitStatusEnum.script) {
      handleVideoSubmit('script');
    } else if (submitStatus === SubmitStatusEnum.finalLink) {
      handleFinalLinkSubmit();
    }
  };

  useEffect(() => {
    if (order?.status === OrderStatus.PendingPublish) {
      // 当前video审核通过的弹窗只给用户展示一次，目前是通过浏览器缓存来实现的
      const value = orderIds?.[orderId]?.videoApprovedSuccessModal;
      const isFixedTime = PostTimeType.FIXED === postTimeType;
      // 是否禁止提交final link
      const isDisabledSubmit =
        isFixedTime &&
        (dayjs().isBefore(postTimeRange?.[0]) ||
          dayjs().isAfter(postTimeRange?.[1]));
      if (value !== '1' && !isDisabledSubmit) {
        setVideoSuccess(true);
        setShowSuccessModal(true);
        // 添加缓存
        setOrderIds({
          ...orderIds,
          [orderId]: {
            ...orderIds?.[orderId],
            videoApprovedSuccessModal: '1'
          }
        });
      }
    }
  }, [order?.status]);

  return (
    <>
      <Button
        className="mui-btn-primary"
        onClick={handleClick}
        disabled={disabled}
      >
        {submitTextMap[submitStatus]}
      </Button>
      {isMobile() ? (
        <BottomDrawer
          title={submitTextMap[submitStatus]}
          visible={isVisible}
          handleClose={() => {
            setIsVisible(false);
            setErrorTitle('');
          }}
          handleSubmit={handleSubmit}
          submitDisabled={!isCanSubmit}
          isLoading={isLoading}
        >
          <SubmitModalContent
            platform={order?.platform}
            setLink={setLink}
            setDesc={setDesc}
            setFormIdea={setFormIdea}
            setIsCanSubmit={setIsCanSubmit}
            submitStatus={submitStatus}
            errorTitle={errorTitle}
          />
        </BottomDrawer>
      ) : (
        <WebModal
          title={submitTextMap[submitStatus]}
          visible={isVisible}
          handleClose={() => {
            setIsVisible(false);
            setErrorTitle('');
          }}
          handleSubmit={handleSubmit}
          submitDisabled={!isCanSubmit}
          isLoading={isLoading}
        >
          <SubmitModalContent
            platform={order?.platform}
            setLink={setLink}
            setDesc={setDesc}
            setIsCanSubmit={setIsCanSubmit}
            submitStatus={submitStatus}
            errorTitle={errorTitle}
            setFormIdea={setFormIdea}
          />
        </WebModal>
      )}

      <SuccessModal
        isVisible={isShowSuccessModal}
        isVideoSuccess={isVideoSuccess}
        handleClose={() => setShowSuccessModal(false)}
        setShowSuccessModal={setShowSuccessModal}
        setIsVisible={setIsVisible}
        refreshAllData={refreshAllData}
        handleRequirement={handleRequirement}
      />
    </>
  );
};

export default SubmitButton;
