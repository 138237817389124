import { create } from 'zustand';

type WebModalStore = {
  showNotInterestSubmitModal: boolean;
  setShowNotInterestSubmitModal: (showNotInterestSubmitModal: boolean) => void;
  showFlowGuideModal: boolean;
  setShowFlowGuideModal: (showFlowGuideModal: boolean) => void;
  showSelectPlatformModal: boolean;
  setShowSelectPlatformModal: (showSelectPlatformModal: boolean) => void;
  showContactInformationModal: boolean;
  setShowContactInformationModal: (
    showContactInformationModal: boolean
  ) => void;
  showSelectInvitedOrderModal: boolean;
  setShowSelectInvitedOrderModal: (
    showSelectInvitedOrderModal: boolean
  ) => void;
  showModalJoinSuccess: boolean;
  setShowModalJoinSuccess: (showModalJoinSuccess: boolean) => void;
};

export const useWebModalStore = create<WebModalStore>()((set) => ({
  showNotInterestSubmitModal: false,
  setShowNotInterestSubmitModal: (showNotInterestSubmitModal: boolean) =>
    set({ showNotInterestSubmitModal }),
  showFlowGuideModal: false,
  setShowFlowGuideModal: (showFlowGuideModal: boolean) =>
    set({ showFlowGuideModal }),
  showSelectPlatformModal: false,
  setShowSelectPlatformModal: (showSelectPlatformModal: boolean) =>
    set({ showSelectPlatformModal }),
  showContactInformationModal: false,
  setShowContactInformationModal: (showContactInformationModal: boolean) =>
    set({ showContactInformationModal }),
  showSelectInvitedOrderModal: false,
  setShowSelectInvitedOrderModal: (showSelectInvitedOrderModal: boolean) =>
    set({ showSelectInvitedOrderModal }),
  showModalJoinSuccess: false,
  setShowModalJoinSuccess: (showModalJoinSuccess: boolean) =>
    set({ showModalJoinSuccess })
}));
