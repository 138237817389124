import { AdsPlatform } from '@/interface/order';
import {
  YouTubeIcon,
  connectSocialIcon,
  EmailInvitesIcon,
  ResponseInvitesIcon,
  CreateSubmitIcon,
  PublishRewardsIcon,
  AhaSupportIcon,
  CommunityIcon,
  CareerIcon
} from '@/components/common/icon';
import { SubmitStatusEnum } from '../config';
import { getShowScript } from '@/biz/order';

// HANS_TODO:这里文案 图片 要改
export const creatorStepsItems = [
  {
    Icon: connectSocialIcon,
    title: 'Connect your social account',
    desc: 'Connect more social accounts to get more cooperation opportunities!'
  },
  {
    Icon: EmailInvitesIcon,
    title: 'Keep an eye on email invites',
    desc: 'Most brand deals are highly competitive. Make sure to respond promptly!'
  },
  {
    Icon: ResponseInvitesIcon,
    title: 'Respond to new invites',
    desc: "Join deals you're interested in early — spots fill up fast!"
  },
  {
    Icon: CreateSubmitIcon,
    title: 'Create and submit content',
    desc: 'Follow the content requirements and submit it for review.'
  },
  {
    Icon: PublishRewardsIcon,
    title: 'Publish and earn rewards',
    desc: 'Post approved content and claim your well-earned reward!'
  }
];

export const resourcesItems = [
  {
    Icon: AhaSupportIcon,
    title: 'Contact your Aha support',
    desc: 'As a new creator, you have access to our specialized support service.',
    link: 'mailto:support@creator.ahaglobal.io'
  },
  {
    Icon: CommunityIcon,
    title: 'Join the community',
    desc: 'Join Aha discord channel for more discussions and events!',
    link: 'https://discord.com/invite/EtcfjsFWq4'
  }
];

/**
 * displayStatus 的枚举
 */
export enum ActionsStatusEnum {
  CreatorApplied = 'Creator_applied',
  ApplyRejected = 'Apply_rejected',
  UnderScriptProduction = 'Under_script_production',
  UnderScriptReview = 'Under_script_review',
  UnderVideoProduction = 'Under_video_production',
  UnderVideoReview = 'Under_video_review',
  PendingPublish = 'Pending_publish',
  ReviewRejected = 'Review_rejected',
  PublishRejected = 'Publish_rejected',
  PendingPayment = 'Pending_payment',
  PaymentSubmitted = 'Payment_submitted',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  PendingProductShipment = 'Pending_Product_shipment', // 待发货
  PendingProductArrival = 'Pending_Product_Arrival' // 发货中
}

export const actionsStatusLab: any = {
  [ActionsStatusEnum.UnderScriptProduction]: SubmitStatusEnum.script,
  [ActionsStatusEnum.UnderVideoProduction]: SubmitStatusEnum.video,
  [ActionsStatusEnum.PublishRejected]: SubmitStatusEnum.finalLink,
  [ActionsStatusEnum.PendingPublish]: SubmitStatusEnum.finalLink
};

export const actionsSubmitLab: any = {
  [ActionsStatusEnum.UnderScriptProduction]: 'Submit script',
  [ActionsStatusEnum.UnderVideoProduction]: 'Submit draft',
  [ActionsStatusEnum.PendingPublish]: 'Final post'
};

export const getDealsStatus: any = ({ order }: { order: any }) => {
  const showScript = getShowScript(order);
  const displayStatus = order?.displayStatus;

  const dealsStatusLab = {
    [ActionsStatusEnum.CreatorApplied]: {
      text: 'Application under review',
      type: 2
    },
    [ActionsStatusEnum.ApplyRejected]: {
      text: 'Deal canceled',
      type: 4
    },
    [ActionsStatusEnum.UnderScriptProduction]: {
      text: 'Application approved',
      type: 1
    },
    [ActionsStatusEnum.UnderScriptReview]: {
      text: 'Under script review',
      type: 2
    },
    [ActionsStatusEnum.UnderVideoProduction]: {
      text: showScript ? 'Script approved' : 'Application approved',
      type: 3
    },
    [ActionsStatusEnum.UnderVideoReview]: {
      text: 'Under video review',
      type: 2
    },
    [ActionsStatusEnum.PendingPublish]: {
      text: 'Video approved',
      type: 3
    },
    [ActionsStatusEnum.ReviewRejected]: {
      // 提交了不能通过的连接
      text: 'Video approved',
      type: 3
    },
    [ActionsStatusEnum.PublishRejected]: {
      text: 'Video approved',
      type: 3
    },
    [ActionsStatusEnum.PendingPayment]: {
      text: 'Under payment',
      type: 2
    },
    [ActionsStatusEnum.PaymentSubmitted]: {
      text: 'Under payment',
      type: 2
    },
    [ActionsStatusEnum.Paid]: {
      text: 'Payment succeed',
      type: 3
    },
    [ActionsStatusEnum.Completed]: {
      text: 'Finished',
      type: 2
    },
    [ActionsStatusEnum.Cancelled]: {
      text: 'Deal canceled',
      type: 4
    },
    [ActionsStatusEnum.PendingProductShipment]: {
      text: 'Pending product shipment',
      type: 1
    },
    [ActionsStatusEnum.PendingProductArrival]: {
      text: 'Pending product arrival',
      type: 3
    }
  };

  return dealsStatusLab[displayStatus as keyof typeof dealsStatusLab];
};
