import React, { useState, useEffect } from 'react';
// import { Image } from 'antd';
import { CloseBgIcon } from '@/components/common/icon';
import VideoPreview from './VideoPreview';

import './feedbackMediaList.less';
interface Props {
  imageList: string[];
  videoList: string[];
  index?: number;
  handleDeleteImage?: Function;
  handleDeleteVideo?: Function;
  isShowDelete?: boolean;
}

const FeedbackMediaList: React.FC<Props> = ({
  imageList,
  videoList,
  index,
  handleDeleteImage,
  handleDeleteVideo,
  isShowDelete = true
}) => {
  return (
    <div className="feedback-media-list">
      {!!videoList?.length && (
        <div className="images-list">
          {videoList?.map((item, videoIndex) => (
            <VideoPreview
              key={item}
              src={item}
              handleDeleteVideo={handleDeleteVideo}
              index={index}
              videoIndex={videoIndex}
              isShowDelete={isShowDelete}
            />
          ))}
        </div>
      )}
      {!!imageList?.length && (
        <div className="images-list">
          {imageList?.map((item, imageIndex) => (
            <div className="images-list-item" key={item}>
              {/* <Image width={56} src={item} /> */}
              <VideoPreview
                isImage={true}
                src={item}
                handleDeleteVideo={handleDeleteVideo}
                index={index}
                videoIndex={imageIndex}
                isShowDelete={isShowDelete}
              />
              {/* <img src={item} />
              {isShowDelete && (
                <span
                  className="close"
                  onClick={() =>
                    handleDeleteImage
                      ? handleDeleteImage(index, imageIndex)
                      : () => {}
                  }
                >
                  <CloseBgIcon className="close-icon" />
                </span>
              )} */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeedbackMediaList;
