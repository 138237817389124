import React, { useEffect, useState } from 'react';
import {
  SignInButton,
  SignOutButton,
  SignedOut,
  SignedIn,
  useAuth
} from '@clerk/clerk-react';
import { usePlatformStore } from '@/hooks/usePlatformStore';
import { useLocation } from 'react-router-dom';
import { getRealRouterFromUrl } from '@/biz/tool';
import { getHomeUrl } from '@/biz/tool';
import axiosInstance from '@/apis/axiosInstance';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import { useNavigate } from 'react-router-dom';
import {
  NavDealsIcon,
  NavWalletIcon,
  HelpIcon,
  LeftArrowIcon,
  RightArrowIcon,
  ExitIcon,
  LikeIcon,
  LogoutIcon,
  HomeIcon,
  InvitesIcon
} from '@/components/common/icon';
import {
  getOrderId,
  hasClerkUser,
  isMobile,
  notMatch,
  getClerkSignOutRedirectUrl,
  getClerkSignInRedirectUrl,
  getClerkSignupRedirectUrl,
  localStorageSaveUtmMediumAndSource
} from '@/biz/tool';
import { useNavStore } from '@/hooks/useNavStore';
import { useLoginStore } from '@/hooks/useLoginStore';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import Tooltip from '@material-ui/core/Tooltip';
import { LoginDetailBottomButton } from '@/components/web/components/loginDetailBottomButton';
import toast from 'react-hot-toast';
import ModalLoginSuccess from '@/components/web/components/ModalLoginSuccess';
import ModalJoinSuccess from '@/components/web/components/ModalJoinSuccess';
import ModalLoginAndJoinCampaign from '@/components/web/components/ModalLoginAndJoinCampaign';
import ModalNotInterestSubmit from '@/components/web/components/ModalNotInterestSubmit';
import ModalFlowGuide from '@/components/web/components/ModalFlowGuide';
import ModalSelectPlatformContent from '@/components/web/components/ModalSelectPlatformContent';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import ModalSelectPlatform from '@/components/web/components/ModalSelectPlatform';
import ContactInformationModal from '@/components/web/components/contactInformationModal';

const ModalBox = () => {
  return (
    <>
      {/* 登录成功弹窗 */}
      <ModalLoginSuccess />
      {/* join 通过弹窗 */}
      <ModalJoinSuccess />
      {/* 登录并加入活动弹窗 */}
      <ModalLoginAndJoinCampaign />
      {/* 不感兴趣 提交后弹窗 */}
      <ModalNotInterestSubmit />
      {/* 流程引导弹窗 */}
      <ModalFlowGuide />
      {/* 回到顶部按钮 */}
      <ScrollToTop />
      {/* 授权弹窗 */}
      <ModalSelectPlatform />
      {/* 添加新的联系方式弹窗 */}
      <ContactInformationModal />
    </>
  );
};

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      setVisible(scrolled > 300);
    };

    window.addEventListener('scroll', toggleVisible);
    return () => window.removeEventListener('scroll', toggleVisible);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (!visible) return null;

  return (
    <div
      onClick={scrollToTop}
      className={`fixed w-10 h-10 bg-white rounded-full shadow-lg cursor-pointer flex items-center justify-center hover:bg-gray-50 transition-all z-50
      ${isMobile() ? 'bottom-[74px] right-[20px]' : 'bottom-28 right-[54px]'}
      `}
    >
      <svg
        className="w-5 h-5 text-gray-600"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 10l7-7m0 0l7 7m-7-7v18"
        />
      </svg>
    </div>
  );
};

const Header: React.FC = () => {
  const {
    setInfluencers,
    influencers: storeInfluencers,
    setUser
  } = usePlatformStore();
  const { detailData } = useDetailDataStore();
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  // 获取达人信息
  const router = useLocation();
  const { setChannelAvatar, setChannelName, setUserEmail, setUserAddressInfo } =
    useLoginStore();
  const { showContactInformationModal } = useWebModalStore();

  const refreshCreatorInfo = async () => {
    const pathname = window.location.pathname;
    const search = window.location.search;
    const current = `${pathname}${search}`;
    try {
      const { data } = await axiosInstance.get('/influencer/info');

      if (data.code !== 0) {
        // 接口错误
        toast.error(data.message);
        return;
      }

      const { influencers, user } = data?.data || {};
      // console.log(window.location.pathname.includes('detail'));
      if (influencers) {
        setInfluencers(influencers);
      }
      if (influencers && !influencers?.length) {
        if (pathname === '/') {
          // 没找到达人信息， 需要跳转到达人连接页面
          window.location.replace('/connect');
        }
      }
      setUser(user);
      const info: any = influencers?.[0] || {};

      // 设置info, 登录没授权则没有
      if (info) {
        setChannelAvatar(info?.profilePictureLink);
        setChannelName(info?.name);
      }
      // 设置邮箱，登录就有
      if (user) {
        setUserEmail(user?.email);
        setUserAddressInfo(user?.addressInfo);
      }
    } catch (error) {
      console.error('Influencer information query error:', error);
      const target = getHomeUrl({ showLoginModal: true });
      if (
        !current.includes('detail') &&
        !current.includes('connect') &&
        !current.includes('contactInfo') &&
        !current.includes('/?showLoginModal=1') &&
        !current.includes('/callback') &&
        !current.includes('/error') &&
        !current.includes('/loginError') &&
        current !== target
      ) {
        // window.location.href = getHomeUrl({ showLoginModal: true });
      }
      // 存储 utm_medium
      // 存储 utm_source
      localStorageSaveUtmMediumAndSource();
      //
      if (current.includes('/?showLoginModal=1')) {
        // 清除url中的loginSuccess参数
        window.history.pushState({}, '', getHomeUrl());
      }
    }
  };

  // 监听路由变化
  useEffect(() => {
    // 设置 tab 激活
    const router = getRealRouterFromUrl();
    if (router) {
      useNavStore.setState({ activity: router });
    }

    // if (pathname.includes('/clerk')) {
    //   return;
    // }

    // 全局整体鉴权
    refreshCreatorInfo();
  }, [router]);

  useEffect(() => {
    // 这里的 showContactInformationModal 是当前值

    return () => {
      // 这里的 showContactInformationModal 是变化前的值
      if (showContactInformationModal === true) {
        // 说明从 true 变为 false
        // 刷新一下 info
        refreshCreatorInfo();
      }
    };
  }, [showContactInformationModal]);

  useEffect(() => {
    // 仅在移动端时，当 detailData 更新时滚动到顶部
    if (isMobile()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    if (detailData?.user && pathname.includes('detail')) {
      const isNotMatch = notMatch({ detailData });
      if (isNotMatch) {
        setTimeout(() => {
          toast.error('Please login with the same account.');
        }, 0);
      }
    }
  }, [detailData]);

  if (isMobile()) {
    return (
      <>
        <HeaderMobile />
        {/* 弹窗都放这里 */}
        <ModalBox />
      </>
    );
  }

  return (
    <>
      <div className="bg-white h-20" />
      <div className="bg-white fixed top-0 left-0 right-0 z-50">
        {/* <div className="bg-white sticky top-0 left-0 right-0 z-50"> */}
        <div className="min-w-[1280px] max-w-[1440px] mx-auto  border-b border-[#e8e8ec]">
          <div className="h-20 flex justify-between items-center mx-20">
            {/* logo */}
            <div className="cursor-pointer" onClick={() => navigate('/')}>
              <img alt="ahaLogo" src="/c-ahaLogo.png" className={'w-28'} />
            </div>
            {/* menu */}
            <div className="">
              <NavbarComponent />
            </div>
            {/* user */}
            <div className="">
              <div className="h-10 justify-start items-center gap-6 inline-flex">
                <div className="justify-start items-center gap-1 flex text-[#0c67e6] cursor-pointer">
                  <HelpIcon className="w-4 h-4" />
                  <a
                    href="mailto:support@creator.ahaglobal.io"
                    className=" text-sm font-normal font-['Geist'] leading-tight hover:underline"
                  >
                    Help & Feedback
                  </a>
                </div>
                {/* avatar 登录 登出 */}
                <Avatar />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 弹窗都放这里 */}
      <ModalBox />
    </>
  );
};

export default Header;

const HeaderMobile: React.FC = () => {
  const { activity } = useNavStore();
  const [menuOpen, setMenuOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const { isSignedIn } = useAuth();
  const { channelAvatar, channelName, userEmail } = useLoginStore();
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setMenuOpen((prev) => !prev); // 切换菜单状态
  };

  const handleShare = () => {
    navigate('/campaign/share');
    setMenuOpen(false);
  };
  const menu = getMenu(activity, getOrderId());
  const headerName = menu.find((el) => el.active)?.label;

  return (
    <div className="z-50 bg-white h-16">
      {/* 占位的 div */}
      <div className="h-16" />
      <div className="fixed top-0 left-0 right-0 w-[390px] h-16 p-4 bg-white justify-between items-center inline-flex z-[9999]">
        <div className="">
          <img
            alt="avatar"
            src={'/c-logo.jpg'}
            className="w-10 h-10 rounded-full cursor-pointer"
          />
        </div>
        <div className="text-[#1c2024] text-xl font-semibold font-['Geist'] leading-7 capitalize">
          {headerName}
        </div>
        <div className="w-7 h-7 relative" onClick={handleMenuToggle}>
          {/* 这里放移动端菜单,三个横向的menu菜单，点击从上弹出菜单区域 */}
          <div className=" h-full w-full flex flex-col gap-[5px] justify-center items-center">
            {menuOpen ? ( // 根据菜单状态渲染不同的图标
              <>
                <div className="w-5 h-[2px] rounded-sm bg-black rotate-45 translate-y-[5px] transition-transform duration-300"></div>
                <div className="w-5 h-[2px] rounded-sm bg-black  transition-transform duration-300 hidden"></div>
                <div className="w-5 h-[2px] rounded-sm bg-black -rotate-45 translate-y-[-2px] transition-transform duration-300"></div>
              </>
            ) : (
              <>
                <div className="w-6 h-[2px] rounded-sm bg-black transition-transform duration-300"></div>
                <div className="w-6 h-[2px] rounded-sm bg-black transition-transform duration-300"></div>
                <div className="w-6 h-[2px] rounded-sm bg-black transition-transform duration-300"></div>
              </>
            )}
          </div>
        </div>
      </div>
      <Drawer
        anchor={'top'}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{
          style: {
            marginTop: '64px',
            borderRadius: '0 0 16px 16px',
            boxShadow: 'none',
            padding: '40px 0 32px 0',
            height: '-webkit-fill-available'
          } // 设置顶部边距
        }}
      >
        {isSignedIn ? (
          // {/* 已登录 */}
          <div className="mb-8 mx-4 flex flex-col space-y-4 border-b border-[#eeeeee] pb-4">
            <Button
              className="mui-btn-default !w-[358px] !h-full"
              onClick={() => {
                setTimeout(() => {
                  setAccountOpen(true);
                }, 200);
              }}
            >
              <div className="w-full flex justify-between items-center gap-3">
                <img
                  alt="avatar"
                  className="w-12 h-12 rounded-[999px]"
                  src={channelAvatar}
                />
                <div className="grow shrink basis-0 h-11 justify-between items-center flex">
                  <div className="flex-col justify-start items-start inline-flex">
                    <div className="text-[#1c2024] text-base font-semibold font-['Geist'] leading-normal">
                      {channelName || '--'}
                    </div>
                    <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
                      {userEmail || '--'}
                    </div>
                  </div>
                  <RightArrowIcon
                    className={`w-6 h-6 ${isMobile() ? '' : 'hidden'}`}
                  />
                </div>
              </div>
            </Button>
          </div>
        ) : (
          // {/* 未登录 */}
          <div className="font-['Geist'] mx-4 mb-8">
            <div className="text-[#1c2024] text-2xl font-semibold leading-loose">
              Welcome to Aha
            </div>
            <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-[18px]">
              Log in with your Google account to start your high cashback
              journey on Aha.
            </div>
          </div>
        )}

        {/*  */}
        <NavbarComponent menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        {/*  */}
        <div
          className="h-[88px] mx-4 my-8 px-4 py-3 bg-[#eff1ff] hover:bg-[#e0e2f0] rounded-2xl justify-start items-center gap-1 inline-flex"
          onClick={() => {
            window.open('mailto:support@creator.ahaglobal.io', '_blank');
          }}
        >
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
            <div className="flex items-center gap-2">
              <LikeIcon className="w-6 h-6" />
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-[#1c2024] text-sm font-semibold font-['Geist'] leading-snug">
                  Contact your Aha support
                </div>
              </div>
            </div>
            <div className="self-stretch text-[#60646c] text-xs font-normal font-['Inter'] leading-[18px]">
              As a new creator, you have access to our specialized support
              service.
            </div>
          </div>
          <RightArrowIcon className="w-6 h-6" />
        </div>
        {isSignedIn && (
          <div className="mx-4 mb-8 pb-8 border-b border-[#eeeeee] relative">
            <img src="/mobile-navbar-banner.png" alt="banner" />
            <div className="absolute left-0 top-0 w-full flex flex-col justify-center p-4">
              <div className="text-[#1c2024] text-base font-semibold font-['Geist'] leading-normal">
                Invite and Earn
              </div>
              <div>
                <span className="text-[#60646c] text-sm font-normal font-['Inter'] leading-snug">
                  Refer creators and earn up to{' '}
                </span>
                <span className="text-[#5b3de9] text-sm font-semibold font-['Geist'] leading-snug">
                  10,000 USD
                </span>
                <span className="text-[#60646c] text-sm font-normal font-['Inter'] leading-snug">
                  . For details, see the
                </span>
                <span className="text-[#0c67e6] text-sm font-normal font-['Inter'] underline leading-snug">
                  <a
                    href="https://chartreuse-wrinkle-697.notion.site/Aha-Docs-11104daded68803fb924f34bbac22a76#b426e324cc1c4ec6b3e58d9f1171a771"
                    target="__blank"
                  >
                    {' '}
                    Referral Terms
                  </a>
                </span>
              </div>
              <div className="h-8 px-4 w-full py-2 bg-zinc-950 rounded-md flex-col justify-center items-center gap-2.5 inline-flex mt-[14px]">
                <div className="justify-center items-center gap-2.5 inline-flex">
                  <div
                    onClick={handleShare}
                    className="text-neutral-50 text-sm font-medium font-['Inter']"
                  >
                    Invite today
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isSignedIn ? (
          <div className="mx-4 h-10 pl-[14px] py-2 justify-between items-center inline-flex hover:bg-[#f7f7f7] rounded-lg">
            <SignedIn>
              <SignOutButton redirectUrl={getClerkSignOutRedirectUrl()}>
                <div className="flex justify-between items-center w-full">
                  <div className="justify-start items-center gap-[11px] flex">
                    <LogoutIcon className="w-5 h-5" />
                    <div className="text-[#1c2024] text-base font-normal font-['Inter'] leading-normal">
                      Log out
                    </div>
                  </div>
                  <RightArrowIcon className="w-6 h-6" />
                </div>
              </SignOutButton>
            </SignedIn>
          </div>
        ) : (
          <>
            <div className="flex-1"></div>
            <div className="flex justify-center items-center">
              <LoginDetailBottomButton desc={''} />
            </div>
          </>
        )}
      </Drawer>
      {/* Drawer Account */}
      <Drawer
        anchor={'top'}
        open={accountOpen}
        onClose={() => setAccountOpen(false)}
        ModalProps={{
          style: {
            zIndex: 99999
          }
        }}
        PaperProps={{
          style: {
            // marginTop: '64px',
            borderRadius: '0 0 16px 16px',
            boxShadow: 'none',
            padding: '0 0 32px 0',
            height: '-webkit-fill-available',
            zIndex: 99999
          } // 设置顶部边距
        }}
        keepMounted={true}
      >
        <div className="h-full">
          <div className="w-[390px] h-16 p-4 bg-white justify-between items-center inline-flex mb-6">
            <div className="" onClick={() => setAccountOpen(false)}>
              <LeftArrowIcon className="w-6 h-6" />
            </div>
            <div className="text-[#1c2024] text-xl font-semibold font-['Geist'] leading-7">
              Account
            </div>
            <div className="w-7 h-7 p-[5.83px] opacity-0 justify-center items-center flex" />
          </div>
          <div className="flex flex-col p-4">
            <div className="h-10 py-2 justify-between items-center inline-flex">
              <div className="justify-start items-center gap-3 flex">
                <div className="text-[#1c2024] text-base font-normal font-['Inter'] leading-normal">
                  Account
                </div>
              </div>
              <div className="justify-start items-center gap-2 flex">
                <div className="text-[#1c2024] text-base font-normal font-['Inter'] leading-normal">
                  {userEmail || '--'}
                </div>
              </div>
            </div>
            <div className="h-px bg-[#eeeeee] mt-4 mb-8"></div>
            <div className="">
              <div className="w-[307px] text-[#1c2024] text-base font-semibold font-['Geist'] leading-normal">
                Connect social accounts
              </div>
              <div className="text-[#60646c] text-xs mb-6">
                <a
                  target="_blank"
                  className="ml-1 underline text-[#0c67e6]"
                  href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
                  rel="noreferrer"
                >
                  Learn more
                </a>{' '}
                about how we protect your privacy.
              </div>
              <div className="flex flex-col gap-2">
                <ModalSelectPlatformContent />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const NavbarComponent: React.FC<{
  menuOpen?: boolean;
  setMenuOpen?: Function;
}> = ({ menuOpen, setMenuOpen }) => {
  const { isSignedIn } = useAuth();
  const { activity, setActivity } = useNavStore();
  const orderId = getOrderId();
  const navigate = useNavigate();
  const { setShowLoginModal } = useNavStore();
  const menu = getMenu(activity, orderId);
  const pathname = window.location.pathname;

  if (
    !isMobile() &&
    (pathname.includes('/connect') ||
      pathname.includes('/contactInfo') ||
      pathname.includes('/match') ||
      pathname.includes('/clerk') ||
      pathname.includes('/auth'))
  ) {
    return null;
  }

  return (
    <div
      className={`flex
          ${isMobile() ? 'flex-col bg-white z-[49] px-4 rounded-b-2xl' : 'gap-8'}
        `}
    >
      {menu
        .filter((el) => !el.hidden)
        .map((el: any) => {
          return (
            <div key={el.key}>
              <Button
                key={el.key}
                onClick={() => {
                  if (el.mustLogin && !isSignedIn) {
                    if (isMobile()) {
                      return;
                    } else {
                      // 跳转首页
                      navigate('/');
                      return;
                    }
                  }

                  if (isMobile()) {
                    setMenuOpen && setMenuOpen(false);
                  }

                  if (el.menuClick) {
                    el.menuClick();
                  } else if (el.target === '_blank') {
                    window.open(el.navigate, '_blank');
                  } else {
                    setActivity(el.key);
                    navigate(el.navigate);
                  }
                }}
                className={`mui-btn-default !w-full !h-full !p-0 ${isMobile() ? '!mb-1' : ''}`}
              >
                <div
                  className={`w-full flex items-center gap-2 text-base cursor-pointer font-['Geist']  py-2 hover:bg-[#f7f7f7]
                    ${el.active ? 'bg-[#f7f7f7] text-[#4515de]' : 'text-[#1c2024]'}
                    ${isMobile() ? 'font-normal pl-[14px] pr-0  rounded-md justify-between' : 'px-4 rounded-lg font-medium'}
                  `}
                >
                  <div className="flex gap-2">
                    {el.icon}
                    <div className={`${isMobile() ? 'flex-1' : ''}`}>
                      {el.label}
                    </div>
                  </div>
                  <RightArrowIcon
                    className={`w-6 h-6 ${isMobile() ? '' : 'hidden'}`}
                  />
                </div>
              </Button>
            </div>
          );
        })}
    </div>
  );
};

const Avatar: React.FC = () => {
  const anchorRef = React.useRef<HTMLImageElement>(null);
  const { avatarInformationOpen, setAvatarInformationOpen } = useNavStore();
  const { isSignedIn } = useAuth();
  const { detailData } = useDetailDataStore();
  const { showLoginModal, setShowLoginModal } = useNavStore();
  const { channelAvatar, channelName, userEmail } = useLoginStore();
  const { setShowSelectPlatformModal, setShowContactInformationModal } =
    useWebModalStore();
  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setAvatarInformationOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAvatarInformationOpen(false);
    }
  }

  return (
    <>
      {isSignedIn ? (
        <>
          <img
            ref={anchorRef}
            alt="avatar"
            src={
              hasClerkUser()
                ? channelAvatar || '/c-avatar-empty.svg'
                : '/c-avatar-empty.svg'
            }
            className="w-10 h-10 rounded-full border border-[#d9d9e0] cursor-pointer"
            onClick={() => {
              setAvatarInformationOpen(!avatarInformationOpen);
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/c-avatar-empty.svg';
              console.error('youtube avatar failed!!! ' + channelAvatar);
            }}
          />
          <Popper
            open={avatarInformationOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={'bottom-end'}
            onMouseLeave={() => setAvatarInformationOpen(false)}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper
                  className="!rounded-lg px-3 py-1"
                  onMouseLeave={() => setAvatarInformationOpen(false)}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={avatarInformationOpen}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <div className="grow shrink basis-0 h-11 justify-start items-center flex p-4 mb-4 min-w-[196px]">
                        <div className="flex-col justify-start items-start inline-flex">
                          <div className="text-[#1c2024] text-base font-semibold font-['Geist'] leading-normal">
                            {channelName || '--'}
                          </div>
                          <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
                            {userEmail || '--'}
                          </div>
                        </div>
                      </div>
                      <MenuItem
                        className="!w-full h-10 flex !justify-between !hover:bg-[#F9F9FB]"
                        onClick={() => {
                          setShowSelectPlatformModal(true);
                        }}
                      >
                        <span>Connect socials</span>
                        <RightArrowIcon />
                      </MenuItem>
                      <MenuItem
                        className="!w-full h-10 flex !justify-between !hover:bg-[#F9F9FB]"
                        onClick={() => {
                          setShowContactInformationModal(true);
                        }}
                      >
                        <span>Contact information</span>
                        <RightArrowIcon />
                      </MenuItem>
                      <div className="h-px w-full bg-[#f7f7f7] my-1"></div>
                      <SignOutButton redirectUrl={getClerkSignOutRedirectUrl()}>
                        <MenuItem className="!w-full h-10 flex !justify-between !hover:bg-[#F9F9FB]">
                          <span>Log out</span>
                          <ExitIcon />
                        </MenuItem>
                      </SignOutButton>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      ) : (
        <>
          {/* 调试用 */}
          {/* <ClerkLoading></ClerkLoading>
          <ClerkLoaded>
            <SignedOut>
              <SignInButton />
            </SignedOut>
            <SignedIn>
              <UserButtons />
            </SignedIn>
          </ClerkLoaded> */}
          {/*  */}
          <Tooltip title="Login">
            <SignedOut>
              <SignInButton
                withSignUp={true}
                forceRedirectUrl={getClerkSignInRedirectUrl({
                  payload: {
                    orderId: detailData?.order?._id,
                    influencerId: detailData?.influencer?.influencerId,
                    influencerUserEmail: detailData?.influencer?.user?.email
                  }
                })}
                fallbackRedirectUrl={getClerkSignInRedirectUrl({
                  payload: {
                    orderId: detailData?.order?._id,
                    influencerId: detailData?.influencer?.influencerId,
                    influencerUserEmail: detailData?.influencer?.user?.email
                  }
                })}
                // 注册
                signUpForceRedirectUrl={getClerkSignupRedirectUrl({
                  payload: {
                    orderId: detailData?.order?._id,
                    influencerId: detailData?.influencer?.influencerId,
                    influencerUserEmail: detailData?.influencer?.user?.email
                  }
                })}
                signUpFallbackRedirectUrl={getClerkSignupRedirectUrl({
                  payload: {
                    orderId: detailData?.order?._id,
                    influencerId: detailData?.influencer?.influencerId,
                    influencerUserEmail: detailData?.influencer?.user?.email
                  }
                })}
                mode="modal"
              >
                <div className="">
                  <img
                    alt="avatar"
                    src={
                      isSignedIn
                        ? channelAvatar || '/c-avatar-empty.svg'
                        : '/c-avatar-empty.svg'
                    }
                    style={{
                      width: '40px',
                      height: '40px',
                      cursor: 'pointer'
                    }}
                    // className="w-10 h-10 rounded-full border border-[#d9d9e0] cursor-pointer"
                    // onClick={() => setShowLoginModal(true)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = '/c-logo.jpg';
                    }}
                  />
                </div>
              </SignInButton>
            </SignedOut>
          </Tooltip>
        </>
      )}
      {/* 登录弹窗 */}
      {/* <LoginModal
        visible={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      /> */}
    </>
  );
};

const getMenu = (activity: string | null, orderId: string) => {
  return [
    {
      label: 'Today',
      key: 'today',
      icon: <HomeIcon />,
      active: activity === 'today',
      navigate: `/`
    },
    {
      label: 'Invites',
      key: 'invites',
      icon: <InvitesIcon />,
      mustLogin: true,
      active: activity === 'invites',
      navigate: `/campaign/invites`
    },
    {
      label: 'Deals',
      key: 'deals',
      icon: <NavDealsIcon />,
      mustLogin: true,
      active: activity === 'deals',
      navigate: `/campaign/deals`
    },
    {
      label: 'Wallet',
      key: 'wallet',
      icon: <NavWalletIcon />,
      mustLogin: true,
      active: activity === 'wallet',
      navigate: `/campaign/wallet/${orderId}`
    },
    {
      label: 'Logout',
      key: 'logout',
      active: false,
      hidden: true,
      navigate: '',
      menuClick: () => {
        // window.location.reload();
      }
    }
  ];
};

// const UserButtons = () => {
//   const navigate = useNavigate();
//   return (
//     <UserButton
//       appearance={{
//         elements: {
//           avatarBox: 'h-10 w-10'
//         }
//       }}
//     >
//       <UserButton.MenuItems>
//         <UserButton.Action
//           label="connect social"
//           onClick={() => {
//             if (window.location.pathname.includes('/organization')) {
//               // navigate("#/payment");
//               window.location.hash = '#/payment';
//             } else {
//               navigate('/organization#/payment');
//             }
//           }}
//           labelIcon={<HelpIcon />}
//         />
//       </UserButton.MenuItems>
//     </UserButton>
//   );
// };
