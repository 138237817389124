import React, { useEffect, useState } from 'react';
import { useOrderIdStore } from '@/hooks/orderIdStore';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { OrderStatus } from '@/interface/order';
import { getOrderId, isMobile } from '@/biz/tool';

const TooltipFirstOrder: React.FC<{}> = () => {
  return null;
  const { orderIds } = useOrderIdStore();
  const { detailData, isLoadingData } = useDetailDataStore();
  const orderId = getOrderId();
  const [firstOrderTooltipUp10Percent, setFirstOrderTooltipUp10Percent] =
    useState(false);

  useEffect(() => {
    const orderIdLocalStorage = orderIds?.[orderId];
    if (orderIdLocalStorage) {
      setFirstOrderTooltipUp10Percent(
        orderIdLocalStorage?.firstOrderTooltipUp10Percent
      );
    }
  }, [orderIds]);

  if (detailData?.order?.status !== OrderStatus.Invited) {
    return null;
  }

  if (!isLoadingData && !firstOrderTooltipUp10Percent) {
    return <Up10Percent />;
  }

  return null;
};

export default TooltipFirstOrder;

const Up10Percent = () => {
  const orderId = getOrderId();
  const { orderIds, setOrderIds } = useOrderIdStore();
  return (
    <>
      <div className={`absolute mt-[-158px] ${isMobile() ? '' : ''}`}>
        <img
          src={'/modal-up-10-percent-mobile.png'}
          alt="upMoney"
          className="w-[282px] h-[150px]"
        />
        <span
          className="absolute top-[14px] right-[12px] w-[26px] h-[26px] cursor-pointer hover:underline"
          onClick={() => {
            setOrderIds({
              ...orderIds,
              [orderId]: {
                ...orderIds?.[orderId],
                firstOrderTooltipUp10Percent: true
              }
            });
          }}
        >
          {' '}
        </span>
      </div>
    </>
  );
};
