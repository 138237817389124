import React, { useState } from 'react';
import { getDuration } from '@/biz/time';
import Radio from '@/components/Radio/index';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InfluencerAgreement } from '@/components/campaign/influencerAgreement';
import {
  isMobile,
  getClerkSignInRedirectUrl,
  getClerkSignupRedirectUrl,
  notMatch
} from '@/biz/tool';
import toast from 'react-hot-toast';
import Modal from '@/components/web/components/Modal';
import BottomDrawer from './BottomDrawer';
import { Tracker } from '../../../utils';
import axiosInstance from '../../../apis/axiosInstance';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { useOrderListStore } from '@/hooks/useOrderListStore';
import {
  JoinDrawEmaiIcon,
  JoinDrawClockIcon,
  JoinDrawFrontPaymentIcon,
  ScriptIcon,
  DedicatedVideoFilledIcon
} from '../../common/icon';
import { getFrontPaymentRate } from '../../../pages/campaign/config';
import ModalLoginAndJoinCampaignContent from './ModalLoginAndJoinCampaignContent';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import styles from './interestContent.module.less';
import { AdsPlatform } from '@/interface/order';
import { useClerk, useAuth } from '@clerk/clerk-react';

interface Props {
  data: any;
  setShowAnInterestOpen: Function;
}

const initialScriptDraftDeadline = (hours: number | null) => {
  if (hours) return getDuration(hours);
  return '-';
};

const InterestContent: React.FC<Props> = ({
  data: { order, influencer, payment, timeline } = {},
  setShowAnInterestOpen
}) => {
  const clerk = useClerk();
  const { isSignedIn } = useAuth();
  const { duringFirstOrder, reQueryDetailData, detailData } =
    useDetailDataStore();
  const rate = getFrontPaymentRate(order?.prepaymentRate);
  const { setShowModalJoinSuccess } = useWebModalStore();
  const [isAgree, setIsAgree] = useState(false);
  const [isShowAgreement, setIsShowAgreement] = useState(false);
  const [joinBtnLoading, setJoinBtnLoading] = useState(false);
  const { selectedPriceFields, addressInfo } = useMultiplePricesStore();
  const [skipScript, setSkipScript] = useState(false);

  const joinCampaign = async () => {
    const orderId = order.id;
    const campaignId = order.campaignId;
    const platform = order.platform;
    setJoinBtnLoading(true);
    try {
      const params: any = {
        campaignId,
        platform,
        orderId
      };

      if (selectedPriceFields?.length > 0) {
        params.interestedPriceFields = selectedPriceFields;
      }
      if (addressInfo?.phone) {
        params.addressInfo = addressInfo;
      }
      if (skipScript) {
        params.skipScript = true;
      }

      const {
        data: { code, message }
      } = await axiosInstance.post(`/campaign/join`, params);
      toast.success('You have successfully applied!', { duration: 2000 });
      setShowAnInterestOpen(false);
      setJoinBtnLoading(false);
      setShowModalJoinSuccess(true);

      // if (duringFirstOrder) {
      //   // 首单 不走如下步骤
      //   return;
      // }
      if (payment) {
        reQueryDetailData(orderId);
      } else {
        setTimeout(() => {
          window.location.href = `/campaign/wallet/${orderId}`;
        }, 2000);
      }
    } catch (error: any) {
      setJoinBtnLoading(false);
      if (error.code === 40010) {
        // The campaign quota is full: Join quota is not enough
        toast.error(
          'We sincerely apologize, but this campaign has reached its capacity and no longer has available spots. Thank you for your understanding!'
        );
        setShowAnInterestOpen(false);
        reQueryDetailData(orderId);
      }
      console.error(`Failed to fetch : (/campaign/join) \n`, error);
    }
  };

  return (
    <div className={isMobile() ? styles.container : styles.webContainer}>
      <div
        className={`
          ${isMobile() ? 'gap-2' : 'gap-8'}
        }`}
        style={{
          display: order?.platform === AdsPlatform.Youtube ? 'flex' : 'none'
        }}
      >
        <div
          className={`w-full p-4 bg-white rounded-2xl border  flex flex-col gap-4 cursor-pointer
            ${!skipScript ? 'border-[#5a31f0]' : 'border-[#e0e0e0]'}
            ${isMobile() ? '!p-3' : ''}
            `}
          onClick={() => {
            if (skipScript) {
              setSkipScript(false);
            }
          }}
        >
          <div className="flex justify-between items-center gap-2">
            <ScriptIcon
              className={`w-6 h-6 ${!skipScript ? 'text-[#5B3DE9]' : ''}`}
            />
            <Radio checked={!skipScript} />
          </div>
          <div className="flex flex-col gap-2">
            <div
              className="text-[#1c2024] font-semibold font-['Plus Jakarta Sans'] leading-normal"
              style={{
                fontSize: isMobile() ? '14px' : '16px'
              }}
            >
              I would like to create a script.
            </div>
            <div
              className="text-[#1c2024] font-normal font-['Plus Jakarta Sans'] leading-snug"
              style={{
                fontSize: isMobile() ? '12px' : '14px'
              }}
            >
              Creating a script for your video ensures that key concepts and
              creative directions are aligned with your brand.
            </div>
          </div>
        </div>
        <div
          className={`w-full p-4 bg-white rounded-2xl border  flex flex-col gap-4 cursor-pointer
            ${skipScript ? 'border-[#5a31f0]' : 'border-[#e0e0e0]'}
            ${isMobile() ? '!p-3' : ''}
            `}
          onClick={() => {
            if (!skipScript) {
              setSkipScript(true);
            }
          }}
        >
          <div className="flex justify-between items-center gap-2">
            <DedicatedVideoFilledIcon
              className={`w-6 h-6 ${skipScript ? 'text-[#5B3DE9]' : ''}`}
            />
            <Radio checked={skipScript} />
          </div>
          <div className="flex flex-col gap-2">
            <div
              className="text-[#1c2024] font-semibold font-['Plus Jakarta Sans'] leading-normal"
              style={{
                fontSize: isMobile() ? '14px' : '16px'
              }}
            >
              I would like to skip the script.
            </div>
            <div
              className="text-[#1c2024] font-normal font-['Plus Jakarta Sans'] leading-snug"
              style={{
                fontSize: isMobile() ? '12px' : '14px'
              }}
            >
              You can forgo the scripting process and provide your video draft
              directly to the brand for feedback.
            </div>
          </div>
        </div>
      </div>
      <div
        className="h-[1px] w-full bg-[#e8e8ec] mt-6 mb-4"
        style={{
          display: order?.platform === AdsPlatform.Youtube ? 'flex' : 'none'
        }}
      ></div>
      <div className={styles.confirmDesc}>
        <div className={styles.li}>
          <JoinDrawEmaiIcon className={styles.joinDrawIcon} />
          <span>
            We'll review your application and send results within 1-2 days.
          </span>
        </div>
        <div className={styles.li}>
          <JoinDrawClockIcon className={styles.joinDrawIcon} />
          <div>
            {order?.platform === AdsPlatform.Youtube ? (
              <>
                <span>If accepted, submit your script draft within </span>
                <span className={styles.timeText}>
                  {initialScriptDraftDeadline(
                    timeline?.initialScriptDraft?.maxWaitInHours
                  )}{' '}
                </span>
                of approval.
              </>
            ) : (
              <>
                <span>If accepted, submit your draft within </span>
                <span className={styles.timeText}>
                  {initialScriptDraftDeadline(
                    timeline?.firstVideoDraft?.maxWaitInHours
                  )}{' '}
                </span>
                of approval.
              </>
            )}
          </div>
        </div>
        <div className={styles.li}>
          <JoinDrawFrontPaymentIcon className={styles.joinDrawIcon} />
          <span>
            You'll receive{' '}
            <span style={{ fontWeight: '600', color: '#1C2024' }}>
              {rate}
              {/* ({normalizePrice(order?.prePaymentAmount)}) */}
            </span>{' '}
            after your application is approved and another{' '}
            <span style={{ fontWeight: '600', color: '#1C2024' }}>
              {rate}
              {/* ({normalizePrice(order?.price - order?.prePaymentAmount)}) */}
            </span>{' '}
            within one week of your post going live.
          </span>
        </div>
      </div>
      <div className={styles.agree}>
        <Radio
          checked={isAgree}
          onClick={() => {
            setIsAgree(!isAgree);
          }}
        />
        <span className="ml-1">I consent to Aha's</span>
        <span
          className={styles.linkText}
          onClick={() => setIsShowAgreement(!isShowAgreement)}
        >
          Creator Agreement
        </span>
        .
      </div>
      <div className="flex gap-2 mt-4 w-full">
        <Button
          variant="contained"
          className={styles.cancelButton}
          onClick={() => {
            Tracker.click('cancel on confirm to join in');
            setShowAnInterestOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={joinBtnLoading}
          className={styles.submitButton}
          onClick={async () => {
            // report the price change
            if (selectedPriceFields) {
              try {
                console.log('Tracker', Tracker);
                Tracker.growthbookConfirmPrice({
                  event_params: {
                    platform_price_d: order?.priceFields.find(
                      (el: any) =>
                        el.contentType === 'Dedicated video' ||
                        el.contentType === 'Video' ||
                        el.contentType === 'Reels' ||
                        el.contentType === 'Post'
                    )?.price,
                    platform_price_i: order.priceFields.find(
                      (el: any) => el.contentType === 'Integrated video'
                    )?.price,
                    creator_price_d: selectedPriceFields.find(
                      (el: any) =>
                        el.contentType === 'Dedicated video' ||
                        el.contentType === 'Video' ||
                        el.contentType === 'Reels' ||
                        el.contentType === 'Post'
                    )?.price,
                    creator_price_i: selectedPriceFields.find(
                      (el: any) => el.contentType === 'Integrated video'
                    )?.price,
                    order
                  }
                });
              } catch (error) {
                console.error('Failed to report the price change', error);
              }
            }

            if (!isAgree) {
              toast.error('Please agree to the Creator Agreement.');
              return;
            }
            setJoinBtnLoading(true);

            if (!(!isAgree || (isSignedIn && !notMatch({ detailData })))) {
              const payload = {
                duringFirstOrder,
                orderId: order.id,
                campaignId: order.campaignId,
                platform: order.platform,
                joinCampaign: true,
                interestedPriceFields:
                  selectedPriceFields?.length > 0 ? selectedPriceFields : null,
                influencerId: influencer?.influencerId,
                influencerUserEmail: influencer?.user?.email,
                skipScript: skipScript
              };

              let signInUrl = await clerk.buildSignInUrl({
                // withSignUp: true,
                signInForceRedirectUrl: getClerkSignInRedirectUrl({ payload }),
                signInFallbackRedirectUrl: getClerkSignInRedirectUrl({
                  payload
                }),
                // 注册
                signUpForceRedirectUrl: getClerkSignupRedirectUrl({ payload }),
                signUpFallbackRedirectUrl: getClerkSignupRedirectUrl({
                  payload
                })
              });
              console.log('signInUrl', signInUrl);
              clerk.navigate(signInUrl);
              // 这个条件是 进入 LoginButtonNoStyle 事件
              setTimeout(() => {
                setJoinBtnLoading(false);
              }, 3000);
            }

            //
            Tracker.click('confirm on confirm to join in');
            if (isSignedIn && !notMatch({ detailData })) {
              await joinCampaign();
              setJoinBtnLoading(false);
            }
          }}
        >
          {joinBtnLoading && (
            <CircularProgress
              className={'!w-[14px] !h-[14px] mr-2 !text-[#fafafa]'}
              disableShrink
            />
          )}
          {joinBtnLoading ? 'Confirming' : 'Confirm'}
        </Button>
      </div>
      {isMobile() ? (
        <BottomDrawer
          visible={isShowAgreement}
          isShowFooter={false}
          isShowHeader={false}
        >
          <InfluencerAgreement setIsShowAgreement={setIsShowAgreement} />
        </BottomDrawer>
      ) : (
        <Modal
          visible={isShowAgreement}
          isShowFooter={false}
          isShowHeader={false}
        >
          <InfluencerAgreement setIsShowAgreement={setIsShowAgreement} />
        </Modal>
      )}
    </div>
  );
};

export default InterestContent;
