import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '@/biz/tool';
import { normalizePriceUsd } from '@/utils/normalizePriceUsd';
import { RightSubmitIcon } from '@/components/common/icon';
import CountDown from '../CountDown';
import {
  actionsStatusLab,
  ActionsStatusEnum,
  getDealsStatus
} from '../../home/config';
import ProductLogo from '@/components/web/components/ProductLogo';
import { PlatformIconComponent } from '@/biz/platform';
import styles from './invitesCard.module.less';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { growthbook } from '@/index';
import { useGrowthBookStore } from '@/hooks/useGrowthBookStore';
import { GbPricingSchemaFlag } from '@/hooks/useMultiplePricesStore';
import { getPlatformVideoContentType } from '@/biz/platform';
import getEnv from '@/utils/getEnv';

interface Props {
  item: any;
  className?: string;
  // 是否是在deals页展示
  isDeals?: boolean;
  index: number;
  isOnlyOne?: boolean;
}

const InvitesCard: React.FC<Props> = ({
  item,
  className,
  isDeals,
  index,
  isOnlyOne
}) => {
  const navigate = useNavigate();
  const { getCampaignAndOrderId } = useGrowthBookStore();

  const detailData = {
    order: item
  };
  // 同时获取多个flag的时候 setAttributes 的组件 必须调用 useFeatureValue
  useFeatureValue('pricing_schema', 'display_price_cannot_change');

  const handleDetails = () => {
    if (isDeals) {
      navigate(`/campaign/deals/detail/${item?._id}`);
    } else {
      navigate(`/campaign/invites/detail/${item?._id}`);
    }
  };

  const { name, logo, website } = item?.campaign?.product || {};
  const creatorName = item?.influencer?.name;
  const isNotInterest = !!item?.notInterested;
  const displayStatus: ActionsStatusEnum = item?.displayStatus;

  const submitStatus = actionsStatusLab[displayStatus];
  const timeline = item?.timeline;
  //
  const dealsStatus: any = getDealsStatus({ order: item });
  // 默认绿色 - 3
  const isBlueStatus = dealsStatus?.type === 2; // 蓝色
  const isScript = dealsStatus?.type === 1; // 橙色
  const isCancel = dealsStatus?.type === 4; // 黑色
  const isShowTimeLine = !(isBlueStatus || isCancel);
  const [uploadedRecords, setUploadedRecords] = useState<any[]>([]);

  const getDealsStatusText = () => {
    let text = dealsStatus?.text || '';
    if (
      uploadedRecords?.length &&
      displayStatus !== ActionsStatusEnum.Cancelled
    ) {
      if (
        uploadedRecords?.[uploadedRecords?.length - 1]?.status ===
        'Feedback_provided'
      ) {
        text = 'Feedback provided';
      }
    }
    return text;
  };

  useEffect(() => {
    if (item?.contents) {
      const records = item?.contents || [];
      setUploadedRecords(records);
    }
  }, [item?.contents]);

  const campaignAndOrderId_pricing_schema = getCampaignAndOrderId(
    detailData.order.campaignId + detailData.order._id,
    'pricing_schema'
  );
  if (!campaignAndOrderId_pricing_schema) {
    growthbook.setAttributes({
      id: detailData.order.campaignId + detailData.order._id,
      url: window.location.href,
      path: window.location.pathname,
      host: window.location.host,
      query: window.location.search,
      deviceType: 'foo',
      browser: navigator.userAgent,
      utmSource: 'foo',
      utmMedium: 'foo',
      utmCampaign: 'foo',
      utmTerm: 'foo',
      utmContent: 'foo',
      campaign_id: detailData.order.campaignId
    });
  }

  const pricing_schema_value = getCampaignAndOrderId(
    detailData.order.campaignId + detailData.order._id,
    'pricing_schema'
  );

  return (
    <div
      className={`
        ${styles.container} 
        ${isMobile() ? styles.mobileContainer : ''} ${className}
        ${(index + 1) % 3 === 0 ? styles.last : ''}
        ${isMobile() && isOnlyOne ? styles.oneContainer : ''}
        `}
      onClick={handleDetails}
    >
      <div>
        <div className={styles.logo}>
          <ProductLogo src={item?.campaign?.product?.logo} size={80} />
        </div>
        <div className={styles.content}>
          <div className={`${styles.title} flex !items-start`}>
            <div className="h-7 w-7 flex items-center justify-center">
              <PlatformIconComponent
                platform={item?.platform}
                className="w-4 flex-shrink-0"
              />
            </div>
            <span>
              {name} x {creatorName} brand deal
            </span>
            {isNotInterest && (
              <span className={styles.notInterest}>Not interested</span>
            )}
          </div>
          {isDeals ? (
            <div
              className={`${styles.dealsStatus}
                ${isBlueStatus ? styles.dealsStatusBlue : ''}
                ${isScript ? styles.scriptStatus : ''}
                ${isCancel ? styles.cancelStatus : ''}
              `}
            >
              <div className={styles.cycle}></div>
              <div>{getDealsStatusText()}</div>
            </div>
          ) : (
            <div className={styles.desc}>
              {name} | {website}
            </div>
          )}
        </div>
        {/* 测试环境展示campaignId 为测试方便 */}
        {getEnv() === 'development' && <div>{item?.campaignId}</div>}
      </div>
      {isDeals ? (
        <div className={styles.footer}>
          {isShowTimeLine ? (
            <CountDown
              isShowSeconds={false}
              timeline={timeline}
              submitStatus={submitStatus}
            />
          ) : (
            <div></div>
          )}
          <div
            className={`${styles.submit} ${!isShowTimeLine ? styles.blueSubmit : ''}`}
            onClick={handleDetails}
          >
            <span>
              {!isShowTimeLine
                ? 'Details'
                : getDealsStatusText() === 'Feedback provided'
                  ? 'Check'
                  : 'Submit'}
            </span>
            <RightSubmitIcon className={styles.rightSubmit} />
          </div>
        </div>
      ) : (
        <div className={`${styles.footer} flex-col`}>
          {/* 多价格 */}
          <FooterPrice order={item} />
          <div
            className={`${styles.button} ${isNotInterest ? styles.notInterestButton : ''} !w-full`}
          >
            Details
          </div>
        </div>
      )}
    </div>
  );
};

export default InvitesCard;

const FooterPrice: React.FC<{ order: any }> = ({ order }) => {
  const { getCampaignAndOrderId } = useGrowthBookStore();
  const pricing_schema_value = getCampaignAndOrderId(
    order.campaignId + order._id,
    'pricing_schema'
  );
  if (!order?.priceFields?.length) {
    return (
      <div className="flex flex-col gap-1 mx-3 items-center mb-6">
        <div className="text-center text-[#1c2024] text-xl font-medium font-['Geist'] leading-7">
          {normalizePriceUsd(order?.price)}
        </div>
        <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
          {getPlatformVideoContentType({
            platform: order?.platform,
            contentType: order?.contentType
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-center w-full mb-6">
        {pricing_schema_value ===
        GbPricingSchemaFlag.NOT_DISPLAY_PRICE_CAN_CHANGE ? (
          'Open budget'
        ) : pricing_schema_value ===
            GbPricingSchemaFlag.DISPLAY_PRICE_CAN_CHANGE ||
          pricing_schema_value ===
            GbPricingSchemaFlag.DISPLAY_PRICE_CANNOT_CHANGE ? (
          <div
            className={`flex w-full items-center gap-2 ${order?.priceFields?.length <= 1 ? 'justify-center' : 'justify-between'}`}
          >
            {order?.priceFields?.map((item: any, index: number) => {
              return (
                <React.Fragment key={`${item.contentType}-${index}`}>
                  {index > 0 && (
                    <div className="text-[#8b8d98] text-xs font-semibold font-['Inter']">
                      OR
                    </div>
                  )}
                  <div className="flex flex-col gap-1 mx-3 items-center">
                    <div className="text-center text-[#1c2024] text-xl font-medium font-['Geist'] leading-7">
                      {normalizePriceUsd(item?.price)}
                    </div>
                    <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
                      {getPlatformVideoContentType({
                        platform: order?.platform,
                        contentType: item.contentType
                      })}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          normalizePriceUsd(order?.price)
        )}
      </div>
    </>
  );
};
