import { create } from 'zustand';
import axiosInstance from '@/apis/axiosInstance';
import toast from 'react-hot-toast';
import { growthbook } from '@/index';

const reQueryDetailData = async (orderId: string) => {
  try {
    window.localStorage.setItem('auth_redirect', `/campaign/detail/${orderId}`);
    window.localStorage.setItem('homepage', `/campaign/detail/${orderId}`);

    // 重新进入 置空
    useDetailDataStore.setState({ isLoadingData: true });
    useDetailDataStore.setState({ detailData: {} });
    //
    // 同时发出请求
    const [detailResponse] = await Promise.allSettled([
      axiosInstance.get(`/order/detail/${encodeURIComponent(orderId)}`)
    ]);

    // 处理订单详情
    if (detailResponse.status === 'fulfilled') {
      const detailData = detailResponse.value.data.data;

      if (!detailData.order) {
        toast.error(
          'Unable to find this order, please contact customer service.'
        );
        return;
      } else {
        // 获取
        getGrowthbookFlag(detailData);

        detailData.order.priceFields.forEach((el: any) => {
          // 添加 originPrice 字段，用于备份原始价格
          el.originPrice = el.price;
        });
      }

      useDetailDataStore.setState({ detailData });
    } else {
      console.error('Failed to get order details:', detailResponse.reason);
      toast.error('Failed to get order details.');
    }
  } catch (e: any) {
    toast.error(e.message || 'Failed to get data.');
  } finally {
    useDetailDataStore.setState({ isLoadingData: false });
  }
};

type DetailDataStore = {
  detailData: any;
  setDetailData: (detailData: any) => void;
  duringFirstOrder: boolean;
  setDuringFirstOrder: (duringFirstOrder: boolean) => void;
  reQueryDetailData: (orderId: string) => void;
  isLoadingData: boolean;
  setIsLoadingData: (isLoadingData: boolean) => void;
};

export const useDetailDataStore = create<DetailDataStore>((set) => ({
  isLoadingData: true,
  setIsLoadingData: (isLoadingData: boolean) => set({ isLoadingData }),
  detailData: {},
  setDetailData: (detailData: any) => set({ detailData }),
  duringFirstOrder: true,
  setDuringFirstOrder: (duringFirstOrder: boolean) => set({ duringFirstOrder }),
  reQueryDetailData
}));

const getGrowthbookFlag = (detailData: any) => {
  if (detailData?.order?.campaignId && detailData?.order?._id) {
    growthbook.setAttributes({
      id: detailData.order.campaignId + detailData.order._id,
      url: window.location.href,
      path: window.location.pathname,
      host: window.location.host,
      query: window.location.search,
      deviceType: 'foo',
      browser: navigator.userAgent,
      utmSource: 'foo',
      utmMedium: 'foo',
      utmCampaign: 'foo',
      utmTerm: 'foo',
      utmContent: 'foo',
      platform_user_id: detailData?.user?.__id || 'foo',
      campaign_id: detailData.order.campaignId
    });
  }
};
