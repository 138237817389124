import React from 'react';
import Button from '@material-ui/core/Button';
import WebModal from './Modal';
import { isMobile } from '@/biz/tool';
import { getOrderId } from '@/biz/tool';
import { PostTimeType } from '../config';

import { ReactComponent as CongratulationIcon } from '@/assets/icons/congratulation.svg';
import { ReactComponent as FlowerIcon } from '@/assets/icons/flower.svg';

import styles from './successModal.module.less';

interface Props {
  isVisible: boolean;
  isVideoSuccess: boolean;
  handleClose?: () => void;
  setShowSuccessModal: Function;
  setIsVisible: Function;
  refreshAllData: Function;
  handleRequirement?: Function;
}

const SuccessModal: React.FC<Props> = ({
  handleClose,
  isVisible,
  isVideoSuccess,
  setShowSuccessModal,
  setIsVisible,
  refreshAllData,
  handleRequirement
}) => {
  const orderId = getOrderId();
  const handleSubmit = () => {
    setShowSuccessModal(false);
    // video 阶段的弹窗提交
    if (isVideoSuccess) {
      setIsVisible(true);
    }
    // final link 提交成功后的弹窗确认
    else {
      // 刷新页面数据
      refreshAllData(orderId);
    }
  };

  return (
    <WebModal
      visible={isVisible}
      isShowFooter={false}
      isShowHeader={false}
      width={isMobile() ? 342 : 624}
      className={styles.modalContainer}
    >
      <div
        className={`
          ${styles.container}
          ${isMobile() ? styles.mobileContainer : ''}
        `}
      >
        {isVideoSuccess ? <FlowerIcon /> : <CongratulationIcon />}
        <div className={styles.title}>
          {isVideoSuccess
            ? isMobile()
              ? 'Celebrate! Video approved'
              : "Congratulations! You're video's approved"
            : 'Congratulations!'}
        </div>
        <div className={styles.desc}>
          {isVideoSuccess ? (
            <div className={styles.descSuccess}>
              <span>
                Before posting your content, please ensure that you use the
                promotional link provided by Aha Creator and that your CTA is
                displayed in accordance with the{' '}
              </span>
              <span
                className={styles.requirements}
                onClick={() => {
                  handleRequirement && handleRequirement();
                  handleClose && handleClose();
                }}
              >
                Content Requirements.
              </span>
            </div>
          ) : (
            'You’ve successfully submitted the final link. We will deliver the payment to you in 7 days.'
          )}
        </div>
        <Button className={styles.button} onClick={handleSubmit}>
          {isVideoSuccess ? 'Submit final link' : 'Complete deal'}
        </Button>
        {isVideoSuccess && (
          <Button onClick={handleClose} className={styles.maybe}>
            Maybe later
          </Button>
        )}
      </div>
    </WebModal>
  );
};

export default SuccessModal;
