import React, { useEffect } from 'react';
import styles from './error.module.less';
import { getDetailPageUrl, getHomeUrl } from '@/biz/tool';
import Button from '@material-ui/core/Button';
import { Tracker } from '@/utils';
import { useLocation, useParams } from 'react-router-dom';
import { isMobile, getOrderId } from '@/biz/tool';
import { maskEmail } from '@/biz/tool';
import { PlatformIconComponent } from '@/biz/platform';
import { ExchangeIcon } from '@/components/common/icon';

const ErrorPage: React.FC = () => {
  const { platform = '' } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type');
  const message = query.get('message');
  const orderId = getOrderId();

  let payload = query.get('payload') as any;
  if (payload) {
    payload = JSON.parse(payload);
  }

  return (
    <div
      className={` w-full h-[calc(100vh-72px)]
        ${isMobile() ? 'px-4 bg-white' : 'bg-[#f9f9fb]'}`}
    >
      <div className={'flex h-full flex-col items-center justify-center'}>
        <div
          className={`flex items-center
            ${isMobile() ? 'gap-3' : 'gap-12'}
          `}
        >
          <div className="w-[72px] h-[72px] p-[17.05px] bg-white rounded-[20px] justify-center items-center gap-[17.05px] inline-flex">
            <PlatformIconComponent platform={platform} className="w-8" />
          </div>
          <ExchangeIcon className="w-7" />
          <div className="w-[72px] h-[72px] p-[17.05px] bg-white rounded-[20px] justify-center items-center gap-[17.05px] inline-flex">
            <img src="/c-logo.png" alt="" />
          </div>
        </div>
        <div
          className={`text-center text-[#1c2024] font-semibold font-['Geist'] leading-9 
            ${isMobile() ? 'text-2xl mb-[6px] mt-10' : 'text-[28px] mb-8 mt-12'}
          `}
        >
          Account successfully linked
        </div>
        <p className="mb-32 text-center text-base font-normal">
          After login, you can also connect more social account on the home
          page.
        </p>
        {/* {buttonComponent} */}
      </div>
    </div>
  );
};

export default ErrorPage;
