import React from 'react';

import { isMobile } from '@/biz/tool';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';

import { ReactComponent as FlowerIcon } from '@/assets/icons/flower.svg';

const ModalLoginAndJoinCampaignContent: React.FC<{}> = () => {
  const { detailData } = useDetailDataStore();
  const productName = detailData?.product?.name;

  return (
    <div className="flex-col justify-start items-center gap-8 inline-flex mb-2">
      <div className="self-stretch flex-col justify-start items-center gap-2 flex">
        {/* icon FlowerIcon */}
        <FlowerIcon />
        <div
          className={`self-stretch text-center text-[#1c2024] font-semibold font-['Geist'] leading-loose ${isMobile() ? 'text[20px]' : 'text-xl'}`}
        >
          Thanks for showing interest in this deal!
        </div>
        <div
          className={`self-stretch text-[#60646c] text-center font-normal font-['Inter'] leading-normal ${isMobile() ? 'text[14px]' : 'text-base'}`}
        >
          Thanks for showing interest in{' '}
          {productName ? `${productName}'s ` : 'this '}deal! We'll email the
          next steps in 1-2 business days.
        </div>
      </div>
    </div>
  );
};

export default ModalLoginAndJoinCampaignContent;
