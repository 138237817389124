import { useState } from 'react';
import styles from './segmentedControl.module.less';

type SegmentedControlProps = {
  defaultValue?: string | number;
  options: { id: string | number; label: string }[];
  onSelect: (id: string | number) => void;
};

export const SegmentedControl = (props: SegmentedControlProps) => {
  const { defaultValue, options, onSelect } = props;
  const [selected, setSelected] = useState<number | string | undefined>(
    defaultValue
  );

  return (
    <div className={styles.segmentedControl}>
      {options.map((option) => (
        <div
          key={option.id}
          className={`${styles.option} ${selected === option.id ? styles.selected : ''}`}
          onClick={() => {
            setSelected(option.id);
            onSelect(option.id);
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};
