import React, { useEffect, useState, useRef } from 'react';

const ProductLogo = ({ src, size = 80 }: { src: string; size?: number }) => {
  const [isLoading, setIsLoading] = useState(true);
  const emptyLogo = '/product-logo-empty.svg';
  const imgSrc = src || emptyLogo;
  const errorRef = useRef(false);
  const roundSize = Math.floor(size / 6);

  // src更新后，重新设置imgSrc
  useEffect(() => {
    const img = new Image();
    img.src = imgSrc;

    // 如果图片已经被缓存，complete 会立即为 true
    if (img.complete) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    errorRef.current = false;
  }, [src, imgSrc]);

  return (
    <div
      className={`box-border border border-[#E8E8EC] overflow-hidden flex flex-shrink-0 justify-center items-center bg-white`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${roundSize}px`,
        overflow: 'hidden'
      }}
    >
      <img
        alt="logo"
        style={{
          width: '100%',
          maxWidth: `${size}px`,
          maxHeight: `${size}px`,
          display: isLoading ? 'none' : 'block'
        }}
        src={imgSrc}
        onLoad={(e) => {
          setIsLoading(false);
          const img = e.target as HTMLImageElement;
          if (img?.src.includes(emptyLogo)) {
            return;
          }
          const aspectRatio = Math.abs(img.naturalWidth / img.naturalHeight);
          if (aspectRatio === 1) {
            img.style.padding = '20%'; // 正方形图片 1:1
          } else if (aspectRatio > 2 || aspectRatio < 0.5) {
            img.style.padding = '5%'; // 长宽比 > 2:1 或 < 1:2
          } else {
            img.style.padding = '10%'; // 1:2 <= 比例 <= 2:1
          }
        }}
        onError={(e) => {
          const img = e.target as HTMLImageElement;
          if (!errorRef.current) {
            errorRef.current = true;
            img.src = emptyLogo;
          }
        }}
      />
      {/*  */}
      <div
        className="w-full h-full bg-gray-200 animate-pulse"
        style={{
          display: isLoading ? 'block' : 'none'
        }}
      />
    </div>
  );
};

export default ProductLogo;
