import React, { useState, useEffect } from 'react';
import axiosInstance from '../../apis/axiosInstance';
import { isMobile } from '@/biz/tool';
import { useNavStore } from '@/hooks/useNavStore';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {}

const ShareComponent: React.FC<Props> = () => {
  const { setAvatarInformationOpen } = useNavStore();

  useEffect(() => {
    getData();
  }, []);

  const [fullEmbedUrl, setFullEmbedUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      const { data } = await axiosInstance.get('/user/referral');
      const { fullEmbedUrl } = data?.data;
      setFullEmbedUrl(fullEmbedUrl);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        height: isMobile() ? 'calc(100vh - 64px)' : 'calc(100vh - 85px)',
        position: 'relative'
      }}
    >
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1
          }}
        >
          <CircularProgress />
        </div>
      )}
      <iframe
        title="share"
        src={fullEmbedUrl}
        style={{ width: '100%', height: '100%' }}
        onMouseEnter={() => setAvatarInformationOpen(false)}
        onLoad={() => setLoading(false)}
      ></iframe>
    </div>
  );
};

export default ShareComponent;
