import React from 'react';
import { useNavigate } from 'react-router-dom';
import CountDown from '../CountDown';

import styles from './todosCard.module.less';
import { isMobile } from '@/biz/tool';
import { RightSubmitIcon } from '@/components/common/icon';
import {
  actionsStatusLab,
  ActionsStatusEnum,
  actionsSubmitLab
} from '../../home/config';

interface Props {
  item: any;
}

const InvitesCard: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate();

  const handleDetails = () => {
    navigate(`/campaign/deals/detail/${item?._id}`);
  };

  const { name } = item?.campaign?.product || {};
  const creatorName = item?.influencer?.name;
  const displayStatus: ActionsStatusEnum = item?.displayStatus;
  const submitStatus = actionsStatusLab[displayStatus];
  const timeline = item?.timeline;

  return (
    <div
      className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
      onClick={handleDetails}
    >
      <div className={styles.header}></div>
      <div className={styles.content}>
        <div className={styles.title}>{actionsSubmitLab[displayStatus]}</div>
        <div className={styles.desc}>
          {' '}
          {name} x {creatorName} brand deal
        </div>
        <div className={styles.footer}>
          <CountDown
            isShowSeconds={false}
            timeline={timeline}
            submitStatus={submitStatus}
          />
          <div className={styles.submit}>
            <span>Submit</span>
            <RightSubmitIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitesCard;
