import React, { useState, useEffect } from 'react';
import { isMobile } from '@/biz/tool';
import BrandResources from './BrandResources';
import InfluencerAndTimeline from './InfluencerAndTimeline';
import PaymentInfo from './PaymentInfo';
import { ProductHonor, YouTubeIcon } from '../common/icon';
import ProductLogo from '@/components/web/components/ProductLogo';

const Main: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div
      className={`flex flex-col flex-1 w-full min-h-screen bg-white rounded-2xl  pb-20 mb-10
                ${isMobile() ? '' : 'p-8'}
    `}
    >
      <ProductInfo data={data} />
      <BrandResources data={data} />
      <PaymentInfo data={data} />
    </div>
  );
};

export default Main;

const ProductInfo: React.FC<{ data: any }> = ({ data }) => {
  const { product, campaign } = data;
  const [loading, setLoading] = useState(true);
  const productInfoList =
    campaign?.introduction?.split('\n').filter(Boolean) || [];
  const productCoreFeaturesList =
    campaign?.sellingPoint?.split('\n').filter(Boolean) || [];
  const productValueList =
    product?.productValue?.split('\n').filter(Boolean) || [];
  const endorsementList =
    product?.endorsement?.split('\n').filter(Boolean) || [];
  const productVideo = campaign?.productVideo;

  const [productVideoInfo, setProductVideoInfo] = useState<{
    title: string;
    authorName: string;
    thumbnailUrl: string;
  } | null>(null);
  useEffect(() => {
    fetch(`https://noembed.com/embed?url=${productVideo}`)
      .then((res) => res.json())
      .then((data) => {
        // console.log('data', data);
        setProductVideoInfo({
          title: data?.title,
          authorName: data?.author_name,
          thumbnailUrl: data?.thumbnail_url
        });
      })
      .catch((error) => {
        console.error('获取视频信息失败:', error);
      });
  }, [productVideo]);

  const productVideoClick = () => {
    window.open(productVideo, '__blank');
  };

  useEffect(() => {
    if (product) {
      setLoading(false);
    }
  }, [product]);

  return (
    <>
      {isMobile() && <InfluencerAndTimeline data={data} />}
      {/* 标题 */}
      <div className="text-[26px] font-semibold font-['Geist'] leading-10 mb-4">
        Product info
      </div>
      {/* 产品 */}
      <div className="w-full justify-between items-center inline-flex">
        <div className="justify-start items-center gap-4 flex">
          <ProductLogo src={product?.logo} />
          <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className="w-full text-xl font-semibold font-['Geist'] leading-loose">
              {!loading ? (
                product?.name
              ) : (
                <div className="flex flex-col gap-2 w-full">
                  <div className="h-[24px] w-[203px] bg-gray-200 rounded animate-pulse" />
                  <div className="h-[16px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
                </div>
              )}
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href={product?.website}
              className="text-[#0c67e6] text-sm font-normal font-['Inter'] leading-tight hover:underline break-all"
            >
              {product?.website}
            </a>
          </div>
        </div>
      </div>
      {/* line */}
      <div className="w-full h-[1px] bg-[#eeeeee] my-6" />
      {!!product?.endorsement && (
        <>
          <div className="text-[#60646c] text-base font-normal font-['Inter'] leading-normal inline-flex items-start">
            <ProductHonor
              style={{ marginTop: '2px', marginRight: '3px', flexShrink: '0' }}
            />
            {!loading ? (
              endorsementList?.map((el: string) => {
                return <p key={el}>{el}</p>;
              })
            ) : (
              <div className="flex flex-col gap-2">
                <div className="h-[18px] w-[200px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[480px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[200px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[480px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[200px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[480px] bg-gray-200 rounded animate-pulse" />
              </div>
            )}
          </div>
          <div className="w-full h-[1px] bg-[#eeeeee] my-6" />
        </>
      )}
      <div className="flex-col justify-start items-start gap-4 inline-flex mb-6">
        <div className="text-xl font-semibold font-['Geist'] leading-loose">
          Product intro
        </div>
        <div className="w-full text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
          {!loading ? (
            productInfoList?.map((el: string) => {
              return <p key={el}>{el}</p>;
            })
          ) : (
            <div className="flex flex-col gap-2 w-full">
              <div className="h-[18px] w-[203px] bg-gray-200 rounded animate-pulse" />
              <div className="h-[18px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
              <div className="h-[18px] w-[203px] bg-gray-200 rounded animate-pulse" />
              <div className="h-[18px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
            </div>
          )}
        </div>
      </div>
      {!!productValueList?.length && (
        <div className="flex-col justify-start items-start gap-4 inline-flex mb-6">
          <div className="text-xl font-semibold font-['Geist'] leading-loose">
            Product value
          </div>
          <div className="text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
            {!loading ? (
              productValueList?.map((el: string) => {
                return <p key={el}>{el}</p>;
              })
            ) : (
              <div className="flex flex-col gap-2">
                <div className="h-[18px] w-[200px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[480px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[200px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[480px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[200px] bg-gray-200 rounded animate-pulse" />
                <div className="h-[18px] w-[480px] bg-gray-200 rounded animate-pulse" />
              </div>
            )}
          </div>
        </div>
      )}
      {productVideoInfo?.title && (
        <div
          className="h-[180px] flex-col justify-start items-start gap-4 inline-flex mb-6 cursor-pointer"
          onClick={productVideoClick}
        >
          <div className="self-stretch text-[#1c2024] text-xl font-semibold font-['Geist'] leading-loose">
            Product/campaign video
          </div>
          <div className="w-[460px] h-[132px] p-4 bg-[#f9f9fb] rounded-xl justify-start items-center gap-4 inline-flex">
            <div className="w-[100px] h-[100px] relative">
              <img
                className="w-[100px] h-[100px] left-0 top-0 absolute rounded"
                src={productVideoInfo?.thumbnailUrl}
                alt=""
              />
              <div className="w-4 h-4 left-[78px] top-[6px] absolute">
                <div className="w-4 h-4 left-0 top-0 absolute bg-black/40 rounded-full backdrop-blur-[9.48px]"></div>
                <div className="w-[13.33px] h-[13.33px] left-[2px] top-[1.33px] absolute"></div>
              </div>
            </div>
            <div className="grow shrink basis-0 self-stretch flex-col justify-between items-start inline-flex">
              <div className="self-stretch text-zinc-950 text-sm font-medium font-['Plus Jakarta Sans']">
                {productVideoInfo?.title}
              </div>
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="opacity-0 justify-start items-center gap-3 flex">
                  <div className="justify-start items-center gap-2 flex">
                    <div className="justify-start items-center gap-1 flex">
                      <div className="w-3.5 h-3.5 px-[1.31px] py-[2.48px] justify-center items-center flex"></div>
                    </div>
                  </div>
                </div>
                <div className="justify-start items-center gap-1.5 flex">
                  <YouTubeIcon className="w-4 h-4" />
                  <div className="text-[#60646c] text-xs font-normal font-['Inter'] underline leading-tight">
                    @{productVideoInfo?.authorName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 产品核心功能 */}
      <div className="flex-col justify-start items-start gap-4 inline-flex">
        <div className="text-xl font-semibold font-['Geist'] leading-loose">
          Core selling points
        </div>
        <div className="w-full flex-col justify-start items-start gap-1.5 flex">
          {!loading ? (
            productCoreFeaturesList?.map((el: string, index: number) => {
              return (
                <div
                  key={el}
                  className={`w-full text-[#60646c] text-base font-['Inter'] leading-normal 
                  ${index === productCoreFeaturesList.length - 1 ? '' : 'border-b border-[#eeeeee]'}`}
                >
                  <span>{index + 1}. </span>
                  <span>{el}</span>
                </div>
              );
            })
          ) : (
            <div className="flex flex-col gap-2 w-full">
              <div className="h-[18px] w-[203px] bg-gray-200 rounded animate-pulse" />
              <div className="h-[18px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
              <div className="h-[18px] w-[203px] bg-gray-200 rounded animate-pulse" />
              <div className="h-[18px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
