import dayjs from 'dayjs';
import { datafluxRum } from '@cloudcare/browser-rum';
import { useLoginStore } from '../hooks/useLoginStore';

const orderIdRegex =
  /\/campaign\/(home|invites|deals|detail|delivery|wallet|share|draft\/detail)(?:\/detail)?(?:\/([a-f0-9]{24}))?/;

export const isMobile = () => {
  if (window.navigator) {
    const ua = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipod|phone|ipad/i.test(ua);
  } else {
    return false;
  }
};

export const isWebPage = () => {
  const pathname = window.location.pathname;
  return pathname.startsWith('/web/campaign/') || pathname.startsWith('/web');
};

export const isWebOnPc = () => {
  return !isMobile() && isWebPage();
};

export const getOrderIdFromUrl = () => {
  try {
    // orderIdFromUrlSearch 访问链接
    // /campaign/home/xxx
    // /campaign/detail/xxx
    // /campaign/delivery/xxx
    // /draft/detail/xxx
    const pathname = window.location.pathname;
    const regArray = pathname.match(orderIdRegex);
    return regArray?.[2] || '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getOrderIdFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('orderId');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getRouterFromUrlSearch = () => {
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('router') || 'today';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getOrderIdFromGoogleState = () => {
  try {
    const query = new URLSearchParams(window.location.hash.replace(/^#/, ''));
    const state = query.get('state') || '';
    let orderId;
    if (state) {
      try {
        const payload = JSON.parse(state);
        orderId = payload.orderId;
      } catch (e) {
        console.error('failed to parse state', e);
      }
    }
    return orderId;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getOrderId = () => {
  const orderId =
    getOrderIdFromUrl() ||
    getOrderIdFromUrlSearch() ||
    getOrderIdFromGoogleState() ||
    '';
  try {
    if (!orderId) {
      const error = new Error(
        `MOBILE: No orderid!!!, homepage is ${window.localStorage.getItem('homepage')}`
      );
      datafluxRum && datafluxRum.addError(error);
    }
  } catch (e) {
    console.log(e);
  }

  return orderId;
};

const getGoogleState = (type: 'joinCampaign' | 'orderId') => {
  try {
    const query = new URLSearchParams(window.location.hash.replace(/^#/, ''));
    const state = query.get('state') || '';
    let payload;
    let result;
    if (state) {
      try {
        payload = JSON.parse(state);
        result = payload[type];
      } catch (e) {
        console.error('failed to parse state', e);
      }
    }
    return result;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getJoinCampaign = () => {
  // 获取 ?joinCampaign=xxx 和 ?=xxx&joinCampaign=xxx 的情况
  // and 获取 google 返回的 joinCampaign 参数
  const joinCampaignFromGoogleState = getGoogleState('joinCampaign');
  if (
    joinCampaignFromGoogleState === true ||
    joinCampaignFromGoogleState === 'true'
  ) {
    return true;
  }

  try {
    const search = window.location.search;
    const joinCampaign = new URLSearchParams(search)?.get('joinCampaign');
    if (joinCampaign === 'true') {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getConnectErrorPageUrl = (params?: any) => {
  const { platform, type, message, payload } = params;
  let url = `/auth/${platform}/connectError?type=${type}`;
  if (message) {
    url += `&message=${message}`;
  }
  if (payload) {
    url += `&payload=${payload ? encodeURIComponent(JSON.stringify(payload)) : ''}`;
  }
  return url;
};

export const getLoginPageUrl = (params?: any) => {
  const orderId = getOrderId();
  // 需要优化链接拼接参数。
  let loginPageUrl = `/campaign/login`;
  if (orderId) {
    if (params?.joinCampaign) {
      loginPageUrl = `/campaign/login?orderId=${orderId}&joinCampaign=true`;
    } else {
      loginPageUrl = `/campaign/login?orderId=${orderId}`;
    }
  }
  return loginPageUrl;
};

export const getAuthPageUrl = () => {
  const orderId = getOrderId();

  let authPageUrl = `/auth`;
  if (orderId) {
    authPageUrl = `/auth?orderId=${orderId}`;
  }
  return authPageUrl;
};

export const getHomePageUrl = () => {
  const orderId = getOrderId();
  return `/campaign/home/${orderId}`;
};

export const getDetailPageUrl = (params?: any) => {
  const orderId = params?.orderId || getOrderId();
  if (params?.joinCampaign && params?.loginSuccess) {
    return `/campaign/detail/${orderId}?joinCampaign=true&loginSuccess=true`;
  }
  if (params?.joinCampaign) {
    return `/campaign/detail/${orderId}?joinCampaign=true`;
  }
  if (params?.loginSuccess) {
    return `/campaign/detail/${orderId}?loginSuccess=true`;
  }
  if (params?.showLoginModal) {
    return `/campaign/detail/${orderId}?showLoginModal=true`;
  }
  return `/campaign/detail/${orderId}`;
};

export const appendUrlParams = (
  baseUrl: string,
  params: Record<string, any>
) => {
  try {
    const url = new URL(baseUrl, window.location.origin);
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        url.searchParams.append(key, value);
      }
    });
    return url.pathname + url.search;
  } catch (error) {
    console.error('Failed to append URL params:', error);
    return baseUrl;
  }
};

export const getHomeUrl = (params?: any) => {
  const urlParams: any = {};
  if (params?.showLoginModal) {
    urlParams.showLoginModal = 'true';
  }
  const REFERRALCODE = getKeyFromUrlSearch('REFERRALCODE');
  if (REFERRALCODE) {
    urlParams.REFERRALCODE = REFERRALCODE;
  }

  return appendUrlParams('/', urlParams);
};

export const getJoinCampaignFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('joinCampaign');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getNoQuotaFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('noQuota');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getLoginSuccessFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('loginSuccess');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getShowLoginModalFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('showLoginModal');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getAppliedFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('applied');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getKeyFromUrlSearch = (key: string) => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get(key);
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getQrCodeLink = () => {
  const origin = window.location.origin;
  const router = getRouterFromUrlSearch();
  const orderId = getOrderIdFromUrlSearch();
  if (router && orderId) {
    return `${origin}/campaign/${router}/${orderId}`;
  }
  return '';
};

export const getOrderIdAndRouterFromUrl = () => {
  const pathname = window.location.pathname;
  const regArray = pathname.match(orderIdRegex);
  const router = regArray?.[1] === 'detail' ? 'detail' : 'home';
  const orderId = getOrderId();
  return {
    router,
    orderId
  };
};

export const getRouterFromUrl = (): 'home' | 'detail' => {
  // orderIdFromUrl 直接访问 /home /detail /delivery 可取的
  const pathname = window.location.pathname;
  const regArray = pathname.match(orderIdRegex);
  const router = regArray?.[1] === 'detail' ? 'detail' : 'home';
  return router as 'home' | 'detail';
};

export const getRealRouterFromUrl = ():
  | 'today'
  | 'invites'
  | 'deals'
  | 'detail'
  | 'delivery'
  | 'wallet'
  | 'share'
  | 'draft/detail'
  | '' => {
  const pathname = window.location.pathname;
  const regArray = pathname.match(orderIdRegex);
  const router = regArray?.[1] || 'today';
  return router as
    | 'today'
    | 'invites'
    | 'deals'
    | 'detail'
    | 'delivery'
    | 'wallet'
    | 'share'
    | 'draft/detail'
    | '';
};

export const getToken = () => {
  return window.localStorage.getItem('token');
};

export const getClerkSignInRedirectUrl = (params?: any) => {
  const orderId = params?.payload?.orderId || getOrderId();
  const REFERRALCODE = getKeyFromUrlSearch('REFERRALCODE');
  //
  const urlParams: any = {
    orderId: orderId,
    campaignId: params?.payload?.campaignId,
    platform: params?.payload?.platform,
    duringFirstOrder: params?.payload?.duringFirstOrder,
    contentType: params?.payload?.interestedPriceFields?.[0]?.contentType,
    contentGenre: params?.payload?.interestedPriceFields?.[0]?.contentGenre,
    price: params?.payload?.interestedPriceFields?.[0]?.price,
    influencerId: params?.payload?.influencerId,
    influencerUserEmail: params?.payload?.influencerUserEmail,
    skipScript: params?.payload?.skipScript,
    REFERRALCODE: REFERRALCODE,
    isSignup: params?.payload?.isSignup
  };

  urlParams.ahaRedirect = window.location.pathname;

  if (orderId) {
    // 有orderId 说明是详情页进入
    if (params?.payload) {
      if (!params.payload?.joinCampaign) {
        urlParams.ahaRedirect = appendUrlParams(window.location.pathname, {
          loginSuccess: true
        });
      }
    }
  }
  const result = appendUrlParams('/clerk', urlParams);

  if (params?.payload?.hans === 123) {
    console.log('aha-result2', result);
  }
  return result;
};

export const getClerkSignupRedirectUrl = (params?: any) => {
  const obj = { ...params, payload: { ...params?.payload, isSignup: true } };
  console.log('aha-getClerkSignupRedirectUrl', obj);
  return getClerkSignInRedirectUrl(obj);
};

export const getClerkSignOutRedirectUrl = () => {
  return window.location.pathname.includes('/detail')
    ? window.location.href
    : '/';
};

export const hasClerkUser = () => {
  //@ts-ignore
  const token = window?.Clerk?.session?.user;
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const getLinkPageUrl = ({
  status,
  platform,
  redirect,
  payload
}: any) => {
  // 目前认为所有走到授权都是 达人 onboarding, 所以成功后跳到 match 页面
  if (status === 'success') {
    return '/match';
  }
  return `/campaign/error?type=connectError&payload=${payload ? encodeURIComponent(JSON.stringify(payload)) : ''}`;
  // // eg `/campaign/linked?status=fail&platform=${encodeURIComponent(orderPlatform)}&redirect=${loginPageUrl}`
  // let url = `/campaign/linked?status=${status}&platform=${encodeURIComponent(platform)}`;
  // const orderId = getOrderId();
  // if (orderId) {
  //   url += `&orderId=${orderId}`;
  // }
  // // redirect参数是链接，要加在最后面，或者后面优化用encodeURIComponent传输
  // if (redirect !== false) {
  //   url += `&redirect=${redirect || getLoginPageUrl()}`;
  // }

  // return url;
};

export const getErrorPageUrl = () => {
  return `/campaign/error?orderId=${getOrderId()}`;
};

export const getLoginErrorPageUrl = (params?: any) => {
  if (params?.type) {
    return `/campaign/loginError?orderId=${getOrderId()}&type=${params?.type}`;
  } else if (params?.orderId) {
    return `/campaign/loginError?orderId=${params?.orderId}`;
  } else {
    return '/campaign/error?type=loginError';
  }
};

export const notMatch = ({
  influencers,
  detailData,
  influencerId
}: {
  influencers?: any;
  detailData?: any;
  influencerId?: any;
}) => {
  //
  const orderInfluencerId =
    detailData?.influencer?.influencerId || influencerId;
  //
  if (detailData) {
    const { user, order } = detailData;
    const orderHasUser = Boolean(order?.user?._id);
    if (orderHasUser && Number(user?.id) !== Number(order?.user?._id)) {
      return true;
    }
  }
  //
  if (!orderInfluencerId) {
    return false;
  }
  //
  if (orderInfluencerId && influencers?.length) {
    const isMatch = influencers.find(
      (el: any) => Number(el.influencerId) === Number(orderInfluencerId)
    );
    return !isMatch;
  }
  return false;
};

export const maskEmail = (email: string): string => {
  if (!email) {
    return '';
  }
  const [username, domain] = email?.split('@');
  let maskedUsername = username;

  if (username.length > 4) {
    const start = username.slice(0, 2);
    const end = username.slice(-2);
    maskedUsername = `${start}***${end}`;
  } else if (username.length > 1) {
    maskedUsername = `${username[0]}***${username[username.length - 1]}`;
  } else {
    maskedUsername = '***';
  }

  return `${maskedUsername}@${domain}`;
};

export const clearToken = () => {
  useLoginStore.getState().clearLoginInfo();
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('platform');
};

export const downloadAndProgress = (
  url: string,
  filename: string,
  onProgress?: (progress: number) => void
) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.responseType = 'blob';

  // 监听下载进度
  xhr.onprogress = (event) => {
    if (event.lengthComputable && onProgress) {
      const percentComplete = Math.round((event.loaded / event.total) * 100);
      onProgress(percentComplete);
    }
  };

  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = xhr.response;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  xhr.send();
};

/**
 * 格式化日期时间 YYYY-MM-DD HH:mm:ss
 * @param date 日期时间字符串
 * @returns 格式化后的日期时间字符串
 */
export const formatDateTime_YMD_HMS = (date: string) => {
  const d = dayjs(date);
  return `${d.format('YYYY-MM-DD HH:mm:ss')}`;
};

export const localStorageSaveUtmMediumAndSource = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmMedium = searchParams.get('utm_medium');
  const utmSource = searchParams.get('utm_source');
  if (utmMedium) {
    window.localStorage.setItem('utm_medium', utmMedium);
  }
  if (utmSource) {
    window.localStorage.setItem('utm_source', utmSource);
  }
};
