import React, { useState, useEffect } from 'react';
import { isMobile } from '@/biz/tool';
import WebModal from '@/components/web/components/Modal';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { emailValidator, whatAppsValidator } from '@/biz/validator';
import Button from '@material-ui/core/Button';
import axiosInstance from '@/apis/axiosInstance';
import toast from 'react-hot-toast';
import { usePlatformStore } from '@/hooks/usePlatformStore';

import styles from './index.module.less';

interface Props {}

const ContactInformationModal: React.FC<Props> = ({}) => {
  const { showContactInformationModal, setShowContactInformationModal } =
    useWebModalStore();
  const { user } = usePlatformStore();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const handleEmailChange = (e: any) => {
    const value = e?.target?.value;
    setEmail(value);
    if (emailValidator(value)) {
      setEmailError('');
    }
  };

  const [whatApps, setWhatApps] = useState('');
  const [whatAppsError, setWhatAppsError] = useState('');
  const handleWhatAppsChange = (e: any) => {
    const value = e?.target?.value;
    setWhatApps(value);
    if (whatAppsValidator(value)) {
      setWhatAppsError('');
    }
  };

  useEffect(() => {
    if (showContactInformationModal) {
      const { contact } = user || {};
      const em =
        contact?.filter((item: any) => item?.type === 'email')?.[0]?.value ||
        '';
      const app =
        contact?.filter((item: any) => item?.type === 'whatsapp')?.[0]?.value ||
        '';
      setEmail(em);
      setWhatApps(app);
    }
  }, [showContactInformationModal]);

  const handleSubmit = async () => {
    if (email && !emailValidator(email)) {
      setEmailError('Please fill in the correct email address.');
      return;
    }
    if (whatApps && !whatAppsValidator(whatApps)) {
      setWhatAppsError('Please fill in the correct number.');
      return;
    }
    const params = [];
    if (email) {
      params.push({
        type: 'email',
        value: email
      });
    }
    if (whatApps) {
      params.push({
        type: 'whatsapp',
        value: whatApps
      });
    }
    try {
      await axiosInstance.post('/user/profile', { contact: params });
      toast.success('success');
      setShowContactInformationModal(false);
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <WebModal
      title={
        <div
          className={`text-[#1c2024] font-semibold font-['Geist'] leading-loose
          ${isMobile() ? 'text-xl' : 'text-2xl'}`}
        >
          Add contact information
        </div>
      }
      visible={showContactInformationModal}
      isShowFooter={false}
      width={isMobile() ? 342 : 624}
      handleClose={() => {
        setShowContactInformationModal(false);
      }}
      keepMounted={true}
    >
      <div className={styles.content}>
        <div className={styles.contentDesc}>
          To ensure you receive brand invitations and deal updates promptly,
          please add your preferred contact information below.
        </div>
        <div className={styles.desc}>Email</div>
        <div className={styles.itemInput}>
          <input
            type="text"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter new email"
          />
        </div>
        {emailError && <div className={styles.itemError}>{emailError}</div>}
        <div className={styles.desc}>WhatsApp</div>
        <div className={styles.itemInput}>
          <input
            type="text"
            value={whatApps}
            onChange={handleWhatAppsChange}
            placeholder="Please enter your number..."
          />
        </div>
        {whatAppsError && (
          <div className={styles.itemError}>{whatAppsError}</div>
        )}
        <div className={styles.footer}>
          <Button
            className={`mui-btn-primary ${isMobile() ? '!h-10 !w-full' : '!w-[134px]'}`}
            onClick={handleSubmit}
            disabled={!email && !whatApps}
          >
            Confirm
          </Button>
        </div>
      </div>
    </WebModal>
  );
};

export default ContactInformationModal;
