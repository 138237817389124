import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

import styles from './bottomDrawer.module.less';

interface Props {
  children: any;
  width?: number;
  title?: string | React.ReactNode;
  isShowHeader?: boolean;
  isShowFooter?: boolean;
  visible: boolean;
  submitDisabled?: boolean;
  isLoading?: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  okText?: string;
  cancelText?: string;
}

const BottomDrawer: React.FC<Props> = ({
  children,
  title,
  visible,
  isShowHeader = true,
  isShowFooter = true,
  submitDisabled,
  isLoading,
  handleClose,
  handleSubmit,
  okText = 'Submit',
  cancelText = 'Cancel'
}) => {
  return (
    <Drawer id="drawerCtn" anchor="bottom" open={visible} onClose={handleClose}>
      {isShowHeader && (
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.close} onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
      )}
      {children}
      {isShowFooter && (
        <div className={styles.footer}>
          <Button className={styles.cancel} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={submitDisabled || isLoading}
            className={`${styles.submit} ${submitDisabled || isLoading ? styles.submitDisabled : ''}`}
            onClick={handleSubmit}
          >
            {isLoading && (
              <CircularProgress className={styles.loading} disableShrink />
            )}
            {okText ? (
              okText
            ) : (
              <span>{isLoading ? 'Submitting' : 'Submit'}</span>
            )}
          </Button>
        </div>
      )}
    </Drawer>
  );
};

export default BottomDrawer;
