import React, { useEffect, useState } from 'react';
import { isMobile } from '@/biz/tool';
import WebModal from '@/components/web/components/Modal';
import ModalSelectPlatformContent from './ModalSelectPlatformContent';
import { useWebModalStore } from '@/hooks/useWebModalStore';

const ModalSelectPlatform: React.FC<{}> = () => {
  const { showSelectPlatformModal, setShowSelectPlatformModal } =
    useWebModalStore();

  return (
    <>
      <WebModal
        title={
          <div
            className={`text-[#1c2024] font-semibold font-['Geist'] leading-loose
            ${isMobile() ? 'text-xl' : 'text-2xl'}`}
          >
            Connect social accounts
          </div>
        }
        visible={showSelectPlatformModal}
        isShowFooter={false}
        width={isMobile() ? 342 : 405}
        handleClose={() => {
          setShowSelectPlatformModal(false);
        }}
        canClose={true}
        keepMounted={true}
      >
        <div
          className={`w-full flex-col justify-start items-start inline-flex
            ${isMobile() ? '' : ''}`}
        >
          <div className="text-[#60646c] text-xs mb-6">
            <a
              target="_blank"
              className="ml-1 underline text-[#0c67e6]"
              href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
              rel="noreferrer"
            >
              Learn more
            </a>{' '}
            about how we protect your privacy.
          </div>
          <div
            className={`w-full grid gap-x-8 gap-y-3 mt-2 mb-10
              ${isMobile() ? 'grid-cols-1' : 'grid-cols-1'}`}
          >
            <ModalSelectPlatformContent />
          </div>
        </div>
      </WebModal>
    </>
  );
};

export default ModalSelectPlatform;
