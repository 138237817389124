import React, { useState, useEffect } from 'react';
import { EarnUpIcon, CloseIcon } from '@/components/common/icon';

import styles from './firstOrderBanner.module.less';
import { isMobile } from '@/biz/tool';

interface Props {
  handleClick: Function;
  userId: string;
}

const FirstOrderBanner: React.FC<Props> = ({ handleClick, userId }) => {
  return null;
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    localStorage.setItem(`${userId}-first-banner`, '1');
  };

  const isFirst = localStorage.getItem(`${userId}-first-banner`) === '1';

  if (!visible || isFirst) {
    return null;
  }

  if (isMobile()) {
    return (
      <div className={styles.mobile} onClick={() => handleClick()}>
        <div className="h-9 w-full py-2 bg-gradient-to-r from-[#e4e7ff] via-[#eff1ff] to-[#f7f8ff] rounded-lg border border-black/20 justify-between items-center inline-flex">
          <div className="flex-col w-full gap-1 inline-flex">
            <div className="text-center text-[#1c2024] text-sm font-medium font-['Inter'] leading-tight">
              Earn additional 10% for your first deal
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>Earn a bonus 10% on your first deal!</div>
        <div className={styles.desc}>
          <span>Enjoy an extra </span>
          <span className={styles.purple}>10%</span>
          <EarnUpIcon />
          <span style={{ marginLeft: '2px' }}>
            bonus on your deal price when you land your first deal!
          </span>
        </div>
      </div>
      <div className={styles.right}>
        <span onClick={() => handleClick()}>Check new invites now</span>
        <CloseIcon className={styles.close} onClick={handleClose} />
      </div>
    </div>
  );
};

export default FirstOrderBanner;
