export const normalizePrice = (price: number | string) => {
  const cleanPrice =
    typeof price === 'string' ? parseFloat(price.replace('$', '')) : price;

  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currencyDisplay: 'narrowSymbol'
    })
      .format(cleanPrice)
      .replace('$', '')
      .trim() + ' USD'
  );
};
