import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import scanLottie from './LottieClerkLoading.json';

const MatchLottieLoading: React.FC = () => {
  return (
    <div className="h-24 w-24 flex justify-center items-center">
      <Lottie
        animationData={scanLottie}
        loop={true}
        style={{ height: 182, width: 182 }}
      />
    </div>
  );
};

export default MatchLottieLoading;
