import React, { useState } from 'react';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import axiosInstance from '@/apis/axiosInstance';
import toast from 'react-hot-toast';

const ModalConfirmPaymentMethod: React.FC = () => {
  const { detailData, reQueryDetailData } = useDetailDataStore();
  const { order } = detailData;
  const orderId = order?._id;
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = async () => {
    if (submitBtnLoading) return;
    setSubmitBtnLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/order/${orderId}/confirmPaymentAccount`
      );
      if (data?.code === 0) {
        toast.success('Confirm success.');
        // 提交后刷新页面数据
        reQueryDetailData(detailData?.order?._id);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error('Confirm failed.');
      console.error(err);
    } finally {
      setSubmitBtnLoading(false);
    }
  };

  return (
    <>
      <>
        <a onClick={() => handleSubmit()}>
          <span
            className={`text-[#5b3de9]  ml-1 ${submitBtnLoading ? '' : 'cursor-pointer hover:underline'}`}
          >
            {submitBtnLoading ? 'Confirming...' : 'Confirm'}
          </span>
        </a>
      </>
    </>
  );
};

export default ModalConfirmPaymentMethod;
