import React from 'react';
import Button from '@material-ui/core/Button';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { getHomeUrl, isMobile } from '@/biz/tool';
import WebModal from '@/components/web/components/Modal';

const ModalNotInterestSubmit: React.FC<{}> = () => {
  const {
    showNotInterestSubmitModal,
    setShowNotInterestSubmitModal,
    setShowFlowGuideModal
  } = useWebModalStore();

  return (
    <>
      <WebModal
        visible={showNotInterestSubmitModal}
        isShowHeader={false}
        isShowFooter={false}
        width={isMobile() ? 342 : 624}
      >
        <div
          className={`w-full max-w-[400px] flex-col justify-start items-center gap-6 inline-flex
            ${isMobile() ? 'h-[372px]' : 'h-[352px]'}`}
        >
          <img
            src="/modal-not-interest.png"
            alt="modal-not-interest"
            className="w-[156px] h-[156px] mb-2"
          />
          <div className="w-full text-center text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
            This deal didn’t work out, but no worries — you can always use Aha
            to find better brand deals!
          </div>
          <div className="">
            {/* <Button
              variant="contained"
              className={`mui-btn-primary !h-10`}
              onClick={() => {
                setShowNotInterestSubmitModal(false);
                // setShowFlowGuideModal(true);
              }}
            >
              Show me how
            </Button> */}
            <Button
              variant="contained"
              className={`mui-btn-primary !h-10`}
              onClick={() => {
                setShowNotInterestSubmitModal(false);
                window.location.href = getHomeUrl();
              }}
            >
              Skip for now
            </Button>
          </div>
        </div>
      </WebModal>
    </>
  );
};

export default ModalNotInterestSubmit;
