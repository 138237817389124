import React, { useEffect, useState } from 'react';
import { isMobile } from '@/biz/tool';
import { SegmentedControl } from './components/SegmentedControl';
import { ReactComponent as Eye } from '@/assets/icons/eye.svg';
import { ReactComponent as Question } from '@/assets/icons/question-circle.svg';
import { BlackTooltip } from './InfluencerAndTimeline';
import { copyToClipboard } from '@/utils';
import styles from './ContentRequirements.module.less';
import { OrderStatus, AdsPlatform } from '@/interface/order';

type YouTubeObject = {
  url: string;
  title: string;
  view: number;
  multiplier: number;
};
const convertYouTubeObjectToYouTubeEmbed = (object: YouTubeObject) => {
  return object?.url.replace('/watch?v=', '/embed/');
};

const ViralVideoRecommendation: React.FC<{ data: any }> = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const { order } = data;
  const [hotProductRecommendation, setHotProductRecommendation] =
    useState<any>(null);
  const [ideaIndex, setIdeaIndex] = useState<number | string>(0);

  useEffect(() => {
    if (order) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    setHotVideo(order);
  }, [order]);

  const setHotVideo = async (order: any) => {
    if (order?.hotProductRecommendation) {
      if (
        order?.hotProductRecommendation?.contents.length > 0 &&
        order?.platform === AdsPlatform.Twitter
      ) {
        order?.hotProductRecommendation?.contents.forEach((content: any) => {
          const tweetId = content.url.split('/').pop();
          content.embedUrl = `https://platform.twitter.com/embed/Tweet.html?id=${tweetId}`;
        });
      }

      setHotProductRecommendation(order?.hotProductRecommendation);
    }
  };

  if (
    order?.status === OrderStatus.Invited ||
    order?.status === OrderStatus.CreatorApplied
  ) {
    return (
      <div className="flex flex-col items-center justify-center my-32">
        <img
          src="/empty-hot-video.png"
          alt=""
          className="w-[150px] h-[129px]"
        />
        <div className="w-full mt-10 text-center text-[#1c2024] text-sm font-normal font-['Inter'] leading-snug">
          You will gain access to Aha Studio upon the establishment of this
          collaboration.
        </div>
      </div>
    );
  }

  if (
    !hotProductRecommendation ||
    !hotProductRecommendation?.AIGeneratedContents
  ) {
    return <></>;
  }

  return (
    <div
      className={`viralVideoRec  pt-1 
      ${isMobile() ? '' : 'px-8 pt-6'}
      `}
    >
      {/*  */}
      <div
        className={`flex gap-4 text-[18px] font-semibold font-['Geist'] leading-10
                    ${isMobile() ? 'flex-col items-start' : 'flex-row items-center flex-wrap'}`}
      >
        Scripts Ideas
      </div>
      <div className="flex flex-row bg-[#E2F5FF] rounded-lg p-3">
        <div className="flex flex-col content-start items-center mr-2 mt-[-4px] text-base">
          💡
        </div>
        <div
          className={`flex flex-col content-start items-center font-['Inter'] font-medium text-xs`}
        >
          These three ideas are generated based on your past content, combined
          with the products promoted by advertisers and trending videos. Please
          choose one as your creative material.
        </div>
      </div>

      <div className="hitVideoContainer border rounded-lg p-4 mt-4">
        <SegmentedControl
          defaultValue={ideaIndex}
          options={[
            { id: 0, label: 'Idea 1' },
            { id: 1, label: 'Idea 2' },
            { id: 2, label: 'Idea 3' }
          ]}
          onSelect={(id) => {
            setIdeaIndex(id);
          }}
        />

        <div
          className={`font-['Inter] text-xs font-normal mt-3 mb-1 text-secondary-text`}
        >
          Recommended title
        </div>
        <div className={`border-b pb-4 font-['Geist'] font-semibold`}>
          {hotProductRecommendation?.AIGeneratedContents?.[ideaIndex]
            ?.creative_title
            ? hotProductRecommendation?.AIGeneratedContents?.[ideaIndex]
                ?.creative_title
            : hotProductRecommendation?.AIGeneratedContents?.[ideaIndex]
                ?.contentTitle}
        </div>
        <div
          className={styles.markdownContainer}
          style={{ marginLeft: 5, marginRight: 5 }}
        >
          <ul>
            <ScriptContent
              hotProductRecommendation={hotProductRecommendation}
              ideaIndex={Number(ideaIndex)}
            />
          </ul>
        </div>
      </div>

      <div
        className={`flex  gap-4 text-[18px] font-semibold font-['Geist'] leading-10 mt-6
                    ${isMobile() ? 'flex-col items-start' : 'flex-row items-center flex-wrap'}`}
      >
        Hit content
      </div>
      <div
        className={`font-['Inter'] text-[14px] font text-secondary-text mb-4`}
      >
        These outlier videos are carefully selected for your channel and this
        collaboration. Feel free to take their thumbnails, titles, storytelling
        technique etc. as a reference to help your video to gain more exposure.
      </div>
      <div className="flex flex-nowrap justify-between space-x-8 relative w-full">
        <HotVideoContent
          order={order}
          hotProductRecommendation={hotProductRecommendation}
        />
      </div>
      {/*  */}
      <div
        className={`flex  gap-4 text-[18px] font-semibold font-['Geist'] leading-10 mt-6
                    ${isMobile() ? 'flex-row items-center' : 'flex-row items-center flex-wrap'}`}
      >
        Keywords to select
        <BlackTooltip
          enterTouchDelay={0}
          arrow
          title={
            'The following keywords are recommended based on our algo that can bring the highest traffic to your video for this brand.'
          }
        >
          <Question />
        </BlackTooltip>
      </div>
      <div className={`font-['Inter'] text-[14px] font text-secondary-text`}>
        Keywords tailored for your channel and this collaboration. Using them in
        your video title and description can help drive traffic from search and
        recommendation.
      </div>
      <div className="flex flex-row items-start flex-wrap gap-2 my-4 text-xs">
        <HotKeywords hotProductRecommendation={hotProductRecommendation} />
      </div>
    </div>
  );
};

export default ViralVideoRecommendation;

const ScriptContent = ({
  hotProductRecommendation,
  ideaIndex
}: {
  hotProductRecommendation: any;
  ideaIndex: number;
}) => {
  const AIGeneratedContentsList =
    hotProductRecommendation.AIGeneratedContents[ideaIndex]?.content;

  if (Array.isArray(AIGeneratedContentsList)) {
    return AIGeneratedContentsList.map(
      (scriptElement: { act_name: string; act_desc: string }) => {
        return (
          <li>
            <p>
              <strong>{scriptElement.act_name}</strong>
            </p>
            <ul>
              <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                {scriptElement.act_desc}
              </p>
            </ul>
          </li>
        );
      }
    );
  }
  if (typeof AIGeneratedContentsList === 'string') {
    return <li>{AIGeneratedContentsList}</li>;
  }
};

const HotVideoContent = ({
  order,
  hotProductRecommendation
}: {
  order: any;
  hotProductRecommendation: any;
}) => {
  if (Array.isArray(hotProductRecommendation.contents)) {
    return hotProductRecommendation.contents.slice(0, 2).map((content: any) => {
      return (
        <div className="flex-1">
          <div className="rounded-lg overflow-hidden">
            {order?.platform === AdsPlatform.Youtube ? (
              <iframe
                title={content.title}
                className="aspect-video w-full"
                src={convertYouTubeObjectToYouTubeEmbed(content)}
                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                allowFullScreen
              ></iframe>
            ) : order?.platform === AdsPlatform.Twitter ? (
              <iframe
                title={content.title}
                className="aspect-video w-full"
                src={content?.embedUrl}
                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                allowFullScreen
              ></iframe>
            ) : (
              <>
                <div
                  className="w-full flex gap-4 cursor-pointer"
                  onClick={() => {
                    window.open(content.url, '_blank');
                  }}
                >
                  <img
                    src={content.cover_url}
                    alt=""
                    className="w-[80px] h-[80px] object-cover"
                  />
                  <div className="flex flex-col gap-2">
                    <div className="text-xs text-secondary-text">
                      {content?.content?.slice(0, 100)}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={`overflow-hidden font-['Geist'] text-base font-semibold`}
          >
            {content.title}
          </div>
          <div className="icon-container flex items-center">
            <Eye />
            <div
              className={`text-xs font-['Geist'] text-secondary-text ml-1 mr-3`}
            >
              {Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 1
              }).format(content.view)}
            </div>
            <div className={`text-secondary-text`}>·</div>
            <div
              className={`text-xs rounded-lg font-['Geist'] bg-black pl-2 pr-2 text-white ml-3 mr-1`}
            >
              {Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 1
              }).format(content.multiplier)}
              x
            </div>
            <BlackTooltip
              enterTouchDelay={0}
              arrow
              title={
                'View multiplier of the video compared to other videos in search results with the same keywords'
              }
            >
              <Question />
            </BlackTooltip>
          </div>
        </div>
      );
    });
  }
};

const HotKeywords = ({
  hotProductRecommendation
}: {
  hotProductRecommendation: any;
}) => {
  const [copyPrompt, setCopyPrompt] = useState('Click to copy');

  if (Array.isArray(hotProductRecommendation?.keywords)) {
    return hotProductRecommendation?.keywords.map((keyword: string) => {
      return (
        <BlackTooltip title={copyPrompt} arrow enterTouchDelay={0}>
          <div
            className={`rounded-full border font-semibold px-2.5 hover:cursor-pointer first-letter:uppercase inline-block`}
            onClick={() => {
              copyToClipboard(keyword);
              setCopyPrompt('Copied!');
              setTimeout(() => {
                setCopyPrompt('Click to copy');
              }, 1000);
            }}
          >
            {keyword}
          </div>
        </BlackTooltip>
      );
    });
  }
};
