import React, { useState, useEffect } from 'react';
import { isMobile } from '@/biz/tool';
import Button from '@material-ui/core/Button';
import {
  ContactEmailIcon,
  WhatAppsIcon,
  LockIcon
} from '@/components/common/icon';
import { emailValidator, whatAppsValidator } from '@/biz/validator';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '@/apis/axiosInstance';
import toast from 'react-hot-toast';
import OneSignal from 'react-onesignal';

import styles from './index.module.less';

interface Props {}

const ContactInfo: React.FC<Props> = ({}) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e: any) => {
    const value = e?.target?.value;
    setEmail(value);
    if (emailValidator(value)) {
      setEmailError('');
    }
  };

  const [whatApps, setWhatApps] = useState('');
  const [whatAppsError, setWhatAppsError] = useState('');
  const handleWhatAppsChange = (e: any) => {
    const value = e?.target?.value;
    setWhatApps(value);
    if (whatAppsValidator(value)) {
      setWhatAppsError('');
    }
  };

  const handleSubmit = async () => {
    if (email && !emailValidator(email)) {
      setEmailError('Please fill in the correct email address.');
      return;
    }
    if (whatApps && !whatAppsValidator(whatApps)) {
      setWhatAppsError('Please fill in the correct number.');
      return;
    }
    if (!email && !whatApps) {
      OneSignal?.Slidedown?.promptPush()
        .then(() => {
          console.log('授权提示已显示');
        })
        .catch((error: any) => {
          console.error('无法显示授权提示:', error);
        });
      navigate('/match');
      return;
    }
    const params = [];
    if (email) {
      params.push({
        type: 'email',
        value: email
      });
    }
    if (whatApps) {
      params.push({
        type: 'whatsapp',
        value: whatApps
      });
    }
    try {
      await axiosInstance.post('/user/profile', { contact: params });
      navigate('/match');
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div
      className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Set up more ways that deals can find you
        </div>
        <div className={styles.desc}>
          Add extra contact channels so you never miss a deal update
        </div>
        <div className={`${styles.content} ${isMobile() ? '!w-full' : ''}`}>
          <div className={styles.contentItem}>
            <div className={styles.itemTitle}>
              <ContactEmailIcon />
              <span>Email</span>
            </div>
            <div className={styles.itemInput}>
              <input
                type="text"
                value={email}
                onChange={handleEmailChange}
                placeholder="Please enter your email"
              />
            </div>
            {emailError && <div className={styles.itemError}>{emailError}</div>}
          </div>
          <div className={styles.contentItem}>
            <div className={styles.itemTitle}>
              <WhatAppsIcon />
              <span>WhatsApp</span>
            </div>
            <div className={styles.itemInput}>
              <input
                type="text"
                value={whatApps}
                onChange={handleWhatAppsChange}
                placeholder="Please enter your number..."
              />
            </div>
            {whatAppsError && (
              <div className={styles.itemError}>{whatAppsError}</div>
            )}
          </div>
          <Button
            className={`mui-btn-primary !mb-[10px] ${isMobile() ? '!h-10' : ''}`}
            onClick={handleSubmit}
            disabled={!email && !whatApps}
          >
            Continue
          </Button>
          <Button
            className={`mui-btn-gray !h-[48px] ${isMobile() ? '!h-10' : ''}`}
            onClick={() => navigate('/match')}
          >
            Maybe later
          </Button>
        </div>
        <div className={styles.lock}>
          <LockIcon />
          <span>Your data is secure</span>
        </div>
      </div>
      {!isMobile() && (
        <div className={styles.bg}>
          <div className={styles.img}>
            <img src="/c-connect-bg.png" alt="bg" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactInfo;
