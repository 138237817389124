/**
 * @file campaign config
 * @author caiguoqing
 * @date 2024/11/04
 */
import { OrderStatus } from '../../interface/order';

export const AHA_DOCS_LINK =
  'https://chartreuse-wrinkle-697.notion.site/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68804a9ba3c6bed4546503';

export const getFrontPaymentRate = (rate: number = 0.5): string => {
  return `${rate * 100}%`;
};

// 预付款账号更新、新建提示的状态集合
export const frontPaymentAccountStatus = [
  OrderStatus.CreatorApplied,
  OrderStatus.ApplyRejected,
  OrderStatus.UnderProduction,
  OrderStatus.UnderReview,
  OrderStatus.ReviewRejected,
  OrderStatus.PendingPublish,
  OrderStatus.PublishRejected,
  OrderStatus.PendingPayment,
  OrderStatus.PaymentSubmitted,
  OrderStatus.Paid,
  OrderStatus.PaymentFailed
];
