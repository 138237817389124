import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { useOrderIdStore } from '@/hooks/orderIdStore';
import { getOrderId, isMobile } from '@/biz/tool';
import { getShowScript } from '@/biz/order';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#1C2024',
    color: '#ffffff',
    maxWidth: 296,
    border: '1px solid #dadde9',
    borderRadius: '8px',
    padding: '8px 4px',
    pointerEvents: 'auto' // 添加这行使tooltip内部元素可点击
  },
  arrow: {
    color: '#1C2024'
  }
}))(Tooltip);

const TooltipFirstOrder: React.FC<{}> = () => {
  const { duringFirstOrder, detailData, isLoadingData } = useDetailDataStore();
  const { orderIds } = useOrderIdStore();
  const orderId = getOrderId();
  const [firstOrderTooltipOneSkip, setFirstOrderTooltipOneSkip] =
    useState(false);
  const [firstOrderTooltipTwoSkip, setFirstOrderTooltipTwoSkip] =
    useState(false);
  const [firstOrderTooltipThreeSkip, setFirstOrderTooltipThreeSkip] =
    useState(false);

  useEffect(() => {
    const orderIdLocalStorage = orderIds?.[orderId];
    if (orderIdLocalStorage) {
      setFirstOrderTooltipOneSkip(
        orderIdLocalStorage?.firstOrderTooltipOneSkip
      );
      setFirstOrderTooltipTwoSkip(
        orderIdLocalStorage?.firstOrderTooltipTwoSkip
      );
      setFirstOrderTooltipThreeSkip(
        orderIdLocalStorage?.firstOrderTooltipThreeSkip
      );
    }
  }, [orderIds]);

  if (isLoadingData) {
    return null;
  }

  // 首单 展示三个提示 tooltip
  if (!duringFirstOrder) {
    return null;
  }

  const displayStatus = detailData?.order?.displayStatus;
  if (
    displayStatus === 'Under_script_production' &&
    !firstOrderTooltipOneSkip
  ) {
    return <TooltipFirstOrderOne />;
  }
  if (displayStatus === 'Under_video_production' && !firstOrderTooltipTwoSkip) {
    return <TooltipFirstOrderTwo />;
  }
  if (
    // 这个是 final link 提交
    (displayStatus === 'Pending_publish' ||
      displayStatus === 'Publish_rejected') &&
    !firstOrderTooltipThreeSkip
  ) {
    return <TooltipFirstOrderThree />;
  }
  return null;
  // return (
  //   <>
  //     {/* 1/3 */}
  //     <TooltipFirstOrderOne />
  //     {/* 2/3 */}
  //     <TooltipFirstOrderTwo />
  //     {/* 3/3 */}
  //     <TooltipFirstOrderThree />
  //   </>
  // );
};

export default TooltipFirstOrder;

const TooltipFirstOrderOne = () => {
  return (
    <TooltipFirstOrderItem
      data={{
        title: 'Submit your script',
        step: 1,
        content: (
          <div className="self-stretch opacity-90">
            <span className="text-white text-xs font-normal font-['Inter'] leading-[18px]">
              Now that your deal’s confirmed, start by submitting your script
              draft here.
            </span>
          </div>
        )
      }}
    />
  );
};

const TooltipFirstOrderTwo = () => {
  const { detailData } = useDetailDataStore();
  const order = detailData?.order;
  return (
    <TooltipFirstOrderItem
      data={{
        title: 'Submit content',
        step: getShowScript(order) ? 2 : 1,
        content: (
          <div className="opacity-90">
            <span className="text-white text-xs font-normal font-['Inter'] leading-[18px]">
              After receiving feedback, you have a designated period to make
              changes and submit your video here.
            </span>
          </div>
        )
      }}
    />
  );
};

const TooltipFirstOrderThree = () => {
  const { detailData } = useDetailDataStore();
  const order = detailData?.order;
  return (
    <TooltipFirstOrderItem
      data={{
        title: 'Submit final link',
        step: getShowScript(order) ? 3 : 2,
        content: (
          <div className="opacity-90">
            <span className="text-white text-xs font-normal font-['Inter'] leading-[18px]">
              Well done! Publish the completed video to your channel and submit
              final video link here.
            </span>
          </div>
        )
      }}
    />
  );
};
const TooltipFirstOrderItem = ({ data }: { data: any }) => {
  const { detailData } = useDetailDataStore();
  const order = detailData?.order;

  const orderId = getOrderId();
  const { orderIds, setOrderIds } = useOrderIdStore();

  const { title, step, content } = data;
  return (
    <>
      {/* 站位 */}
      <div className={`${isMobile() ? 'h-20' : 'h-10'}`}></div>
      <div
        className={`absolute left-0 w-full 
          ${isMobile() ? 'top-[-70px]' : 'top-[-60px]'}`}
      >
        <HtmlTooltip
          arrow
          open={true}
          PopperProps={{ style: { zIndex: 0 }, disablePortal: true }}
          title={
            <React.Fragment>
              <div className="px-2 py-1 bg-[#1c2024] rounded-lg flex-col justify-start items-start gap-2 inline-flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="text-white text-sm font-semibold font-['Inter'] leading-tight">
                    {title}
                  </div>
                </div>
                {content}
                {/* 这里加个白色 10% 透明度的横线 */}
                <div className="w-full h-[1px] bg-white/10"></div>
                <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
                  <div className="grow shrink basis-0">
                    <span className="text-white text-xs font-normal font-['Geist Mono'] leading-none">
                      {step}
                    </span>
                    <span className="text-[#8b8d98] text-xs font-normal font-['Geist Mono'] leading-none">
                      /{getShowScript(order) ? 3 : 2}
                    </span>
                  </div>
                  <span
                    className="cursor-pointer hover:underline"
                    onClick={() => {
                      const ShowScript = getShowScript(order) ? true : false;
                      let realStep = step;
                      if (!ShowScript) {
                        realStep = step + 1;
                      }

                      if (realStep === 1) {
                        setOrderIds({
                          ...orderIds,
                          [orderId]: {
                            ...orderIds?.[orderId],
                            firstOrderTooltipOneSkip: true
                          }
                        });
                      }
                      if (realStep === 2) {
                        setOrderIds({
                          ...orderIds,
                          [orderId]: {
                            ...orderIds?.[orderId],
                            firstOrderTooltipTwoSkip: true
                          }
                        });
                      }
                      if (realStep === 3) {
                        setOrderIds({
                          ...orderIds,
                          [orderId]: {
                            ...orderIds?.[orderId],
                            firstOrderTooltipThreeSkip: true
                          }
                        });
                      }
                    }}
                  >
                    skip
                  </span>
                </div>
              </div>
            </React.Fragment>
          }
        >
          <div className=""></div>
        </HtmlTooltip>
      </div>
    </>
  );
};
