import { useState, useEffect } from 'react';
import { isMobile, getOrderId } from '@/biz/tool';
import Radio from '@/components/Radio/index';
import Button from '@material-ui/core/Button';
import toast from 'react-hot-toast';
import { Tracker } from '@/utils';
import Modal from '@material-ui/core/Modal';
import WalletPaymentMethod from './walletPaymentMethod';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import axiosInstance from '@/apis/axiosInstance';

export default function Wallet() {
  const { detailData } = useDetailDataStore();
  const [openUpdatePaymentMethod, setOpenUpdatePaymentMethod] = useState(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState('bank');
  const [method, setMethod] = useState('bank');
  const orderId = getOrderId();

  useEffect(() => {
    setCurrentPaymentMethod(detailData?.payment?.accountType);
  }, [detailData]);

  useEffect(() => {
    getPaymentAccount();
  }, []);

  const getPaymentAccount = async () => {
    try {
      const {
        data: { data }
      } = await axiosInstance.get(`/payment/account`);
      setCurrentPaymentMethod(data?.payment?.accountType);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* navbar */}
      {/* content */}
      <div
        className={`mx-auto flex flex-col items-center
        ${isMobile() ? 'w-screen px-4' : 'max-w-[1440px] px-20 '}`}
      >
        <div id="drawerCtn" className="px-6 pt-8 pb-6">
          <div className="flex flex-col justify-center items-center">
            <img
              alt="payment-card"
              src="/payment-card.png"
              className={`
                ${isMobile() ? 'w-[262px] h-[184px] mb-8' : 'w-[100px] h-[70px] mb-2'}`}
            />
            <div className="mt-8 text-center text-[#1c2024] text-xl font-semibold font-['Geist'] leading-7">
              Set up your payment method
            </div>
            <div className="mt-2 h-11 text-center text-[#60646c] text-sm font-normal font-['Inter'] leading-snug">
              Choose your preferred payment method and start earning with ease.
            </div>
          </div>

          <div
            id="payment"
            className={`flex items-start p-4 mb-4 border rounded-lg cursor-pointer ${
              method === 'bank' ? 'border-[#5B3DE9]' : 'border-[#E8E8EC]'
            }`}
            onClick={() => {
              setMethod(method === 'bank' ? 'none' : 'bank');
            }}
          >
            <div className="mr-3 mt-1">
              <Radio checked={method === 'bank'} />
            </div>
            <div>
              <div className="flex items-center mb-2">
                <div className="text-[16px] font-medium">Bank transfer</div>
                {currentPaymentMethod === 'bank' && (
                  <div className="ml-2 px-2 py-1 text-[12px] bg-[#eff1ff] text-[#5A31F0] rounded">
                    Current
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center text-[14px] text-[#60646C]">
                  <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                  Up to 7 business day
                </div>
                <div className="flex items-center text-[14px] text-[#60646C]">
                  <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                  Fees may apply.{' '}
                  <a
                    target="_blank"
                    className="ml-1 underline text-[#0c67e6]"
                    href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            id="payment"
            className={`flex items-start p-4 mb-4 border rounded-lg cursor-pointer ${
              method === 'paypal' ? 'border-[#5B3DE9]' : 'border-[#E8E8EC]'
            }`}
            onClick={() => {
              setMethod(method === 'paypal' ? 'none' : 'paypal');
            }}
          >
            <div className="mr-3 mt-1">
              <Radio checked={method === 'paypal'} />
            </div>
            <div>
              <div className="flex items-center mb-2">
                <div className="text-[16px] font-medium">PayPal</div>
                {currentPaymentMethod === 'paypal' && (
                  <div className="ml-2 px-2 py-1 text-[12px] bg-[#eff1ff] text-[#5A31F0] rounded">
                    Current
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center text-[14px] text-[#60646C]">
                  <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                  Up to 1 business day
                </div>
                <div className="flex items-center text-[14px] text-[#60646C]">
                  <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                  Fees may apply.{' '}
                  <a
                    target="_blank"
                    className="ml-1 underline text-[#0c67e6]"
                    href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Button
            variant="contained"
            className="mui-btn-primary"
            onClick={() => {
              Tracker.click('continue to set up payment');
              if (method === 'none') {
                toast.error('Please select your preferred payment method.');
              } else {
                // web 用弹窗 mobile用新页面
                if (isMobile()) {
                  window.location.href = `/campaign/wallet/payment/method/update/${encodeURIComponent(method)}?orderId=${orderId}`;
                } else {
                  setOpenUpdatePaymentMethod(true);
                }
              }
            }}
          >
            Continue
          </Button>
          {isMobile() ? (
            <Button
              variant="contained"
              className="mui-btn-default"
              onClick={() => {
                window.history.back();
                Tracker.click('skip setting up payment');
              }}
            >
              Back
            </Button>
          ) : null}
        </div>
      </div>
      <Modal
        open={openUpdatePaymentMethod}
        onClose={() => setOpenUpdatePaymentMethod(false)}
        keepMounted={true}
      >
        <div
          className={`w-[505px] min-h-[560px] rounded-lg bg-white flex flex-col pl-4 pt-4
              relative  overflow-hidden left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] `}
        >
          <div className="text-[#1c2024] text-2xl font-semibold font-['Geist'] leading-loose">
            Update payment card
          </div>
          <WalletPaymentMethod
            modalMethod={method}
            handleClose={() => setOpenUpdatePaymentMethod(false)}
          />
        </div>
      </Modal>
    </>
  );
}
