import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { GrowthBook } from '@growthbook/growthbook-react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { Tracker } from './utils';
import { BrowserRouter } from 'react-router-dom';
import { datafluxRum } from '@cloudcare/browser-rum';
import appConfig from './utils/config';
import App from './App';
import { Toaster } from 'react-hot-toast';
import VConsole from 'vconsole';
import { useGrowthBookStore } from '@/hooks/useGrowthBookStore';
import { ClerkProvider } from '@clerk/clerk-react';
import {
  getClerkSignInRedirectUrl,
  getClerkSignupRedirectUrl
} from '@/biz/tool';
import {
  isMobile,
  getRealRouterFromUrl,
  getOrderId,
  getQrCodeLink
} from '@/biz/tool';

//如果不是生产环境并且不是pc设备那么就显示调试
if (
  process.env.REACT_APP_ENV !== 'production' &&
  window.localStorage.getItem('vconsole') === 'true'
) {
  console.log(process.env.NODE_ENV);
  const vConsole = new VConsole();
}

// Import your Publishable Key
const PUBLISHABLE_KEY = appConfig?.['clerkPublishableKey'];
console.log('PUBLISHABLE_KEY', PUBLISHABLE_KEY);

if (!PUBLISHABLE_KEY) {
  throw new Error('Add your Clerk publishable key to the .env.local file');
}

const localization = {
  organizationProfile: {
    start: {
      profileSection: {
        uploadAction__title: 'Logo(optional)'
      }
    }
  }
};

const loadPhylloScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://cdn.getphyllo.com/connect/v2/phyllo-connect.js';
  document.head.appendChild(script);
};

const computed = () => {
  // 获取设备宽度
  let html = document.documentElement;
  let deviceWidth = html.clientWidth;
  let deviceHeight = html.clientHeight;
  let designWidth = 390;
  let StandardFontSize = 16;

  /**
   * 移动端 通用
   * 以390px时font-size是16px为标准(iphone12pro) ，也适配 tailwindcss 的 font-size
   */
  let ratio = (deviceWidth * StandardFontSize) / designWidth;

  if (deviceWidth > deviceHeight) {
    /**
     * 移动端 横屏
     */
    ratio = StandardFontSize;
  } else if (deviceWidth >= 1024) {
    /**
     * 移动端 过宽 ipad等
     */
    ratio = 41.9692;
  }

  if (!isMobile()) {
    /**
     * 桌面端 root.font-size 固定 16
     */
    ratio = 16;
  }

  // 规定
  // 移动端 root.font-size 自适应，0-41.9692;
  // 桌面端 root.font-size 固定16
  html.style.fontSize = ratio + 'px';
};
computed();
window.addEventListener('resize', computed);
loadPhylloScript();

if (process.env.REACT_APP_ENV !== 'development') {
  // 使用 guance 云面板
  // 工作空间 test/prod -> 用户访问监测 -> 应用 alalab-influencer -> 分析看板
  // 地址：
  // https://us1-console.guance.com/rum/list
  datafluxRum.init({
    applicationId: appConfig['applicationId'],
    clientToken: appConfig['clientToken'],
    env: appConfig['env'],
    allowedTracingUrls: [appConfig['BASE_URL']],
    version: '1.0.3',
    service: 'browser',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 70,
    trackUserInteractions: true,
    site: 'https://rum-openway.guance.one'
  });
  datafluxRum.startSessionReplayRecording();
}

// 2.0 /web，自动跳转回home或者detail页面
const pathname = window.location.pathname;
if (pathname.startsWith('/web')) {
  const qrCodeLink = getQrCodeLink();
  if (qrCodeLink) {
    window.location.replace(qrCodeLink);
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

ReactGA.initialize([
  {
    trackingId: 'G-8BJ0BN20MX'
  }
]);

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-XVaUlEIbQIIO0qxt',
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log('aha-experiment', experiment);
    console.log('aha-result', result);
    console.log('aha-Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key
    });
    const hashValue = result.hashValue;
    const featureId = result.featureId || '';
    const value = result.value;
    growthBookStore.addCampaignAndOrderIds(hashValue, featureId, value);
    const experimentId = experiment.key;
    const variationId = result.key;
    // const identifier = platformUserId + campaignId
    Tracker.growthbook({
      event_params: {
        experimentId,
        variationId,
        orderId: getOrderId()
        // identifier
      }
    });
  }
});

// Load features asynchronously when the app renders
growthbook.init({ streaming: true });

// 在文件顶部创建store实例
const growthBookStore = useGrowthBookStore.getState();
const clerkSignInRedirectUrl = getClerkSignInRedirectUrl();
const clerkSignupRedirectUrl = getClerkSignupRedirectUrl();

root.render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}
      signInForceRedirectUrl={clerkSignInRedirectUrl}
      signInFallbackRedirectUrl={clerkSignInRedirectUrl}
      // 注册
      signUpForceRedirectUrl={clerkSignupRedirectUrl}
      signUpFallbackRedirectUrl={clerkSignupRedirectUrl}
      localization={localization}
      appearance={{
        elements: {
          formButtonPrimary: 'bg-[#5A3DF1] hover:bg-[#4d34cc]'
        }
      }}
      allowedRedirectOrigins={
        appConfig['env'] === 'production'
          ? [
              'https://platform.ahalab.io',
              'https://platform.creator.ahaglobal.io'
            ]
          : []
      }
    >
      <GrowthBookProvider growthbook={growthbook}>
        <BrowserRouter>
          <App />
          <Toaster
            toastOptions={{
              className: '',
              style: {
                fontSize: 12,
                borderRadius: 4,
                background: '#1C2024',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
                color: '#fff'
              }
            }}
          />
        </BrowserRouter>
      </GrowthBookProvider>
    </ClerkProvider>
  </React.StrictMode>
);
