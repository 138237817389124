export default function getEnv() {
  //
  const domain = window?.location?.hostname;
  if (
    domain === 'test-platform.creator.ahaglobal.io' ||
    domain === 'localhost'
  ) {
    return 'development';
  }
  if (domain === 'platform.creator.ahaglobal.io') {
    return 'production';
  }
  return 'production';
}
