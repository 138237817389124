import { create } from 'zustand';

type NavStore = {
  activity: string;
  setActivity: (activity: string) => void;
  showLoginModal: boolean;
  setShowLoginModal: (showLoginModal: boolean) => void;
  avatarInformationOpen: boolean;
  setAvatarInformationOpen: (avatarInformationOpen: boolean) => void;
};

export const useNavStore = create<NavStore>((set) => ({
  activity: 'today',
  setActivity: (activity: string) => set({ activity }),
  showLoginModal: false,
  setShowLoginModal: (showLoginModal: boolean) => set({ showLoginModal }),
  avatarInformationOpen: false,
  setAvatarInformationOpen: (avatarInformationOpen: boolean) =>
    set({ avatarInformationOpen })
}));
