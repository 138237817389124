import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ClerkLoaded, useUser } from '@clerk/clerk-react';
import Home from './components/Home';
import ClerkCallbackPage from './pages/login/clerkCallbackPage';
import PlatformCallbackSassPage from './pages/login/PlatformCallbackSassPage';
import ConnectError from './pages/campaign/connectError';
import ConnectSuccess from './pages/campaign/connectSuccess';
import ErrorPage from './pages/campaign/error';
import MatchPage from './pages/campaign/match';
import ConnectPage from './pages/campaign/connect';
import Wallet from './pages/campaign/wallet';
import WalletPaymentMethod from './pages/campaign/walletPaymentMethod';
import InvitesHome from './pages/campaign/invites';
import DetailNew from './pages/campaign/detailNew';
import DealsHome from './pages/campaign/deals';
import ShareHome from './pages/share';
import ContactInfo from './pages/contactInfo';
import { initCustomEvent } from './utils';
import { isMobile } from '@/biz/tool';
import { useLocation } from 'react-router-dom';
import Navbar from './components/web/Navbar';
import OneSignalConfig from '@/components/oneSIgnalConfig';

import './index.css';
import Intercom from '@intercom/messenger-js-sdk';

const App: React.FC = () => {
  const router = useLocation();
  const { isSignedIn, user, isLoaded } = useUser();

  if (isSignedIn && isLoaded) {
    const createdAtInMs = user?.createdAt?.valueOf() || new Date().valueOf(); // just in case
    const createdAt = Math.round(createdAtInMs / 1000);
    Intercom({
      app_id: 'hd5tkx1s',
      user_id: user.id,
      name: user?.fullName || '', // just in case
      email: user?.primaryEmailAddress?.emailAddress,
      created_at: createdAt
    });
  }

  useEffect(() => {
    initCustomEvent();
  }, [router]);

  return (
    <div id="app" className={isMobile() ? '' : 'webAppContainer'}>
      <ClerkLoaded>
        <Navbar />
        <OneSignalConfig />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/web" element={<Web />} /> */}
          <Route path="/campaign/error" element={<ErrorPage />} />
          <Route path="/match" element={<MatchPage />} />
          <Route path="/connect" element={<ConnectPage />} />
          <Route path="/contactInfo" element={<ContactInfo />} />
          <Route
            path="/auth/:platform/"
            element={<PlatformCallbackSassPage />}
          />
          <Route path="/clerk" element={<ClerkCallbackPage />} />
          <Route
            path="/auth/:platform/connectError"
            element={<ConnectError />}
          />
          <Route path="/auth/:platform/success" element={<ConnectSuccess />} />
          {/*  */}
          {/* 以下是的新页面 */}
          {/*  */}
          <Route path="/campaign/detail/:orderId" element={<DetailNew />} />
          <Route
            path="/campaign/invites/detail/:orderId"
            element={<DetailNew />}
          />
          <Route
            path="/campaign/deals/detail/:orderId"
            element={<DetailNew />}
          />
          <Route path="/campaign/wallet/:orderId?" element={<Wallet />} />
          <Route
            path="/campaign/wallet/payment/method/update/:type"
            element={<WalletPaymentMethod />}
          />
          <Route path="/campaign/invites" element={<InvitesHome />} />
          <Route path="/campaign/deals" element={<DealsHome />} />
          <Route path="/campaign/share" element={<ShareHome />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ClerkLoaded>
    </div>
  );
};

export default App;
