import React, { useEffect } from 'react';
import styles from './error.module.less';
import { getDetailPageUrl, getHomeUrl } from '@/biz/tool';
import Button from '@material-ui/core/Button';
import { Tracker } from '@/utils';
import { useLocation } from 'react-router-dom';
import { isMobile, getOrderId } from '@/biz/tool';
import toast from 'react-hot-toast';
import { getOrderPlatform } from '@/biz/order';
import { maskEmail } from '@/biz/tool';

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type');
  const message = query.get('message');
  const platform = query.get('platform') || '';
  const orderId = getOrderId();

  let payload = query.get('payload') as any;
  if (payload) {
    payload = JSON.parse(payload);
  }

  useEffect(() => {
    // const pathname = window.location.pathname;
    // // 废弃路由 重定向回 detail 或者 /
    // if (orderId && !pathname.includes('/campaign/error')) {
    //   if (
    //     pathname.includes('/campaign/home/') ||
    //     pathname.includes('/campaign/delivery/') ||
    //     pathname.includes('/campaign/draft/upload/link') ||
    //     pathname.includes('/campaign/draft/detail/')
    //   ) {
    //     setTimeout(() => {
    //       toast.success('Redirecting to detail page...');
    //     }, 0);
    //     setTimeout(() => {
    //       window.location.href = `/campaign/detail/${orderId}`;
    //     }, 3000);
    //   } else {
    //     setTimeout(() => {
    //       window.location.href = '/';
    //     }, 3000);
    //   }
    // } else {
    //   setTimeout(() => {
    //     window.location.href = '/';
    //   }, 3000);
    // }
  }, []);

  switch (type) {
    case 'loginError':
      return (
        <div
          className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
        >
          <div className={'flex flex-col items-center mt-10 max-w-md mx-auto'}>
            <img src={'/c-login-error.svg'} className={styles.linkedImg} />
            <div className={styles.title}>Login failed, please try again</div>
            <div className="mt-10 w-[300px]">
              <Button
                variant="contained"
                className="mui-btn-primary"
                onClick={() => {
                  Tracker.click('Return to login');
                  window.location.href = getHomeUrl(true);
                }}
              >
                Return to login
              </Button>
            </div>
          </div>
        </div>
      );
    case 'connectError':
      return (
        <div
          className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
        >
          <div className={'flex flex-col items-center mt-10 max-w-md mx-auto'}>
            <img src={'/c-login-error.svg'} className={styles.linkedImg} />
            <div className={styles.title}>
              {`Couldn't connect to ${platform}`}
            </div>
            <div className={styles.detail}>
              <p>
                {payload?.type === 'emptyChannel'
                  ? `We were unable to find any ${getOrderPlatform({ platform })} accounts under your email. Please try another.`
                  : ''}
              </p>
              <p>
                {payload?.type === 'channelConnectedByOthers'
                  ? `Your ${getOrderPlatform({ platform })} account(s) are already associated with ${payload?.emails.map((email: string) => maskEmail(email)).join(', ')}. Try logging in with that email instead.`
                  : ''}
              </p>
            </div>
            <div className="mt-10 w-[300px]">
              <Button
                variant="contained"
                className="mui-btn-primary"
                onClick={() => {
                  Tracker.click('Return to connect');
                  window.location.href = '/connect';
                }}
              >
                Return to connect
              </Button>
            </div>
          </div>
        </div>
      );
    case 'cancelGoogle':
      return (
        <div
          className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
        >
          <div className={'flex flex-col items-center mt-10 max-w-md mx-auto'}>
            <img src={'/c-login-error.svg'} className={styles.linkedImg} />
            <div className={styles.title}>
              {`Couldn't connect to ${platform}`}
            </div>
            <div className={styles.detail}>
              <p>
                {
                  'You have canceled the YouTube channel information authorization.'
                }
              </p>
            </div>
            <div className="mt-10 w-[300px]">
              <Button
                variant="contained"
                className="mui-btn-primary"
                onClick={() => {
                  Tracker.click('Return to connect');
                  window.location.href = '/connect';
                }}
              >
                Return to connect
              </Button>
            </div>
          </div>
        </div>
      );
    case 'matchError':
      return (
        <div
          className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
        >
          <div className={'flex flex-col items-center mt-10 max-w-md mx-auto'}>
            <img src={'/c-login-error.svg'} className={styles.linkedImg} />
            <div className={styles.title}>
              Matching failed, please try again
            </div>
            <p className="text-red-500">{message}</p>
            <div className="mt-10 w-[300px]">
              <Button
                variant="contained"
                className="mui-btn-primary"
                onClick={() => {
                  Tracker.click('Return to match');
                  window.location.href = '/match';
                }}
              >
                Try again
              </Button>
            </div>
          </div>
        </div>
      );
    case 'zeroMatched':
      return (
        <div
          className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
        >
          <div className={'flex flex-col items-center mt-10 max-w-md mx-auto'}>
            <img
              src={'/modal-not-interest.png'}
              className="w-[140px] h-[140px] mb-8"
            />
            <div
              className={`${styles.title} mb-4
              ${isMobile() ? 'text-center' : 'whitespace-nowrap'}
              `}
            >
              No ideal deals at the moment
            </div>
            <p
              className={`text-[#60646C] 
              ${isMobile() ? 'px-4 text-center' : 'w-[658px] text-center'}`}
            >
              We apologize that there are currently no ideal deals available for
              your channel. Rest assured, we will continue to match you with the
              best opportunities. In the meantime, you can start earning through
              our Invite and Earn program!
            </p>
            <div
              className={`mt-10 flex gap-4
              ${isMobile() ? 'flex-col-reverse' : 'flex-row-reverse'}`}
            >
              <Button
                variant="contained"
                className="mui-btn-primary !w-[294px]"
                onClick={() => {
                  // Tracker.click('Return to match');
                  // window.location.href = '/connect';
                  window.location.href = '/';
                }}
              >
                Visit homepage
              </Button>
              <Button
                variant="contained"
                className="mui-btn-gray !h-[48px] !w-[294px]"
                onClick={() => {
                  Tracker.click('Invite and Earn');
                  window.location.href = '/campaign/share';
                }}
              >
                Invite and Earn
              </Button>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div
          className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
        >
          <div className={'flex flex-col items-center mt-10 max-w-md mx-auto'}>
            <img src={'/c-errorBg.png'} className={styles.linkedImg} />
            <div className={styles.desc}>
              Sorry, the page you are trying to access is lost. Please try again
              later.
            </div>
            <div className="mt-10 w-[300px]">
              <Button
                variant="contained"
                className="mui-btn-primary"
                onClick={() => {
                  Tracker.click('Back to deals');
                  if (orderId) {
                    window.location.href = getDetailPageUrl();
                  } else {
                    window.location.href = '/';
                  }
                }}
              >
                {orderId ? 'Back to deals' : 'Back to home'}
              </Button>
            </div>
          </div>
        </div>
      );
  }
};

export default ErrorPage;
