interface PhylloConfig {
  [key: string]: any;
}

interface PhylloConnect {
  on(event: string, callback: (...args: any[]) => void): void;
  open(): void;
}

declare global {
  interface Window {
    PhylloConnect: {
      initialize(config: PhylloConfig): PhylloConnect;
    };
  }
}

class PhylloSDK {
  async openPhylloSDK(config: PhylloConfig) {
    const phylloConnect = window.PhylloConnect.initialize(config);

    phylloConnect.on(
      'accountConnected',
      (accountId: string, workplatformId: string, userId: string) => {
        console.log(
          `onAccountConnected: ${accountId}, ${workplatformId}, ${userId}`
        );
      }
    );

    phylloConnect.on(
      'accountDisconnected',
      (accountId: string, workplatformId: string, userId: string) => {
        console.log(
          `onAccountDisconnected: ${accountId}, ${workplatformId}, ${userId}`
        );
      }
    );

    phylloConnect.on('tokenExpired', (userId: string) => {
      console.log(`onTokenExpired: ${userId}`);
      if (
        window.confirm('Your session has expired, but we can help you fix it')
      ) {
        // Reinitiating Phyllo SDK
        // localStorage.removeItem("PHYLLO_SDK_TOKEN");
        // this.openPhylloSDK(config);
        console.log(`onTokenExpired: ${userId}`); // the SDK closes automatically in case the token has expired, and you need to handle this by showing an appropriate UI and messaging to the users
      } else {
        window.location.href = '/';
      }
    });

    phylloConnect.on('exit', (reason: string, userId: string) => {
      console.log(`onExit: ${reason}, ${userId}`);
      alert('Phyllo SDK exit reason: ' + reason);
      window.location.href = '/connect';
    });

    phylloConnect.on(
      'connectionFailure',
      (reason: string, workplatformId: string, userId: string) => {
        console.log(
          `onConnectionFailure: ${reason}, ${workplatformId}, ${userId}`
        );
        alert('WorkPlatform connection failure reason: ' + reason);
      }
    );

    phylloConnect.open();
  }
}

export default PhylloSDK;
