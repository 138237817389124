import React, { useState, useEffect } from 'react';
import Notices from '@/components/common/Notices';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import {
  PhysicalProductIcon,
  NoticeRocketIcon,
  PhysicalProductDoneIcon
} from '@/components/common/icon';
import { normalizePrice } from '@/utils/normalizePrice';
import { OrderStatus } from '@/interface/order';
import { NationsLab } from '@/utils/locations';
import { ProductPhysicalSelectEnum } from '../config';

interface Props {}

const PhysicalProductNoticesBar: React.FC<Props> = ({}) => {
  const { detailData } = useDetailDataStore();
  const { campaign, product, order } = detailData || {};
  const { locations } = campaign || [];
  const { physicalProductShipType, physicalProductCashValue } = product || {};
  const country = locations
    ?.map((item: string[]) => NationsLab?.[item?.[1]])
    .join(', ');

  const [visible, setVisible] = useState(true);
  const orderStatus = order?.status;
  if (
    [OrderStatus.Invited].includes(orderStatus) &&
    physicalProductShipType === ProductPhysicalSelectEnum.ship &&
    visible
  ) {
    return (
      <Notices
        backgroundColor={'#e4e7ff'}
        title={''}
        desc={
          <div>
            <span>
              This is a physical product deal. In addition to the order price,
              the brands will ship a product valued at{' '}
              {normalizePrice(physicalProductCashValue)} directly to you. To
              begin this collaboration, please ensure that you are located in
              one of the following countries: {country}.
            </span>
          </div>
        }
        Icon={PhysicalProductIcon}
        isShowClose={true}
        handleClose={() => setVisible(false)}
      />
    );
  }
  if (
    [OrderStatus.Invited].includes(orderStatus) &&
    physicalProductShipType === 'ShareVideo' &&
    visible
  ) {
    return (
      <Notices
        backgroundColor={'#D9F1D9'}
        title={''}
        desc={
          <div>
            <span>
              This is a physical product deal. The brands will share video
              assets with you to incorporate into your content.
            </span>
          </div>
        }
        Icon={NoticeRocketIcon}
        isShowClose={true}
        handleClose={() => setVisible(false)}
      />
    );
  }
  if (
    [OrderStatus.PendingProductShipment].includes(orderStatus) &&
    physicalProductShipType === ProductPhysicalSelectEnum.ship &&
    visible
  ) {
    return (
      <Notices
        backgroundColor={'#D9F1D9'}
        title={''}
        desc={
          <div>
            <span>
              Collaboration established: you have established the collaboration
              with the brand. Please wait for the brand to send product to your
              address.
            </span>
          </div>
        }
        Icon={PhysicalProductIcon}
        isShowClose={true}
        handleClose={() => setVisible(false)}
      />
    );
  }
  if (
    [OrderStatus.PendingProductArrival].includes(orderStatus) &&
    physicalProductShipType === ProductPhysicalSelectEnum.ship &&
    visible
  ) {
    return (
      <Notices
        backgroundColor={'#DBEBFF'}
        title={''}
        desc={
          <div>
            <span>
              Product shipped: Please keep track of the product delivery status.
              Once you've received the product, you can start your creative
              work!
            </span>
          </div>
        }
        Icon={PhysicalProductDoneIcon}
        isShowClose={true}
        handleClose={() => setVisible(false)}
      />
    );
  }
  if (
    [OrderStatus.UnderProduction].includes(orderStatus) &&
    physicalProductShipType === ProductPhysicalSelectEnum.ship &&
    visible
  ) {
    return (
      <Notices
        backgroundColor={'#D9F1D9'}
        title={''}
        desc={
          <div>
            <span>
              Product arrived: The brand has successfully delivered the product
              to your provided address. You can now start your creative work!
            </span>
          </div>
        }
        Icon={PhysicalProductIcon}
        isShowClose={true}
        handleClose={() => setVisible(false)}
      />
    );
  }
  return null;
};

export default PhysicalProductNoticesBar;
