import React, { useState, useEffect } from 'react';
import OneSignal from 'react-onesignal';
import appConfig from '@/utils/config';
import { useUser } from '@clerk/clerk-react';
interface Props {}

const OneSignalConfig: React.FC<Props> = ({}) => {
  const { user } = useUser();
  const init = async () => {
    if (typeof window !== 'undefined') {
      const appId = appConfig?.oneSignalAppId;
      console.log('appId', appId);
      await OneSignal?.init({
        appId,
        // You can add other initialization options here
        notifyButton: {
          enable: true
        },
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        // allowLocalhostAsSecureOrigin: true,
        promptOptions: {
          slidedown: {
            enabled: true // 启用权限请求提示
            // autoPrompt: true
          }
        }
      });
      if (user?.id) {
        window?.OneSignalDeferred &&
          window?.OneSignalDeferred?.push(async function (OneSignal: any) {
            await OneSignal?.login(user?.id);
          });
      }
    }
  };
  useEffect(() => {
    // Ensure this code runs only on the client side
    init();
  }, []);
  return null;
};

export default OneSignalConfig;
