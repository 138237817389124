export enum ReginEnum {
  NORTH_AMERICA = 'North America',
  LATIN_AMERICA = 'Latin America',
  WESTERN_EUROPE = 'Western Europe',
  EASTERN_EUROPE_AND_RUSSIA = 'Eastern Europe & Russia',
  MIDDLE_EAST = 'Middle East',
  SOUTH_ASIA = 'South Asia',
  SOUTH_EAST_ASIA = 'Southeast Asia',
  EAST_ASIA = 'East Asia',
  OCEANIA = 'Oceania',
  AFRICA = 'Africa',
  Other = 'Other'
}

export const locationOptions = [
  {
    label: ReginEnum.NORTH_AMERICA,
    value: ReginEnum.NORTH_AMERICA,
    children: [
      {
        label: 'Canada',
        value: 'CA'
      },
      {
        label: 'United States',
        value: 'US'
      },
      {
        label: 'Greenland',
        value: 'GL'
      },
      {
        label: 'Anguilla',
        value: 'AI'
      },
      {
        label: 'Montserrat',
        value: 'MS'
      },
      {
        label: 'Sint Maarten',
        value: 'SX'
      },
      {
        label: 'Cayman Islands',
        value: 'KY'
      },
      {
        label: 'Bermuda',
        value: 'BM'
      }
    ]
  },
  {
    label: ReginEnum.LATIN_AMERICA,
    value: ReginEnum.LATIN_AMERICA,
    children: [
      {
        label: 'Mexico',
        value: 'MX'
      },
      {
        label: 'Belize',
        value: 'BZ'
      },
      {
        label: 'Costa Rica',
        value: 'CR'
      },
      {
        label: 'El Salvador',
        value: 'SV'
      },
      {
        label: 'Guatemala',
        value: 'GT'
      },
      {
        label: 'Honduras',
        value: 'HN'
      },
      {
        label: 'Nicaragua',
        value: 'NI'
      },
      {
        label: 'Panama',
        value: 'PA'
      },
      {
        label: 'Argentina',
        value: 'AR'
      },
      {
        label: 'Bolivia',
        value: 'BO'
      },
      {
        label: 'Brazil',
        value: 'BR'
      },
      {
        label: 'Chile',
        value: 'CL'
      },
      {
        label: 'Colombia',
        value: 'CO'
      },
      {
        label: 'Ecuador',
        value: 'EC'
      },
      {
        label: 'Guyana',
        value: 'GY'
      },
      {
        label: 'Paraguay',
        value: 'PY'
      },
      {
        label: 'Peru',
        value: 'PE'
      },
      {
        label: 'Suriname',
        value: 'SR'
      },
      {
        label: 'Uruguay',
        value: 'UY'
      },
      {
        label: 'Venezuela',
        value: 'VE'
      },
      {
        label: 'Antigua & Barbuda',
        value: 'AG'
      },
      {
        label: 'Bahamas',
        value: 'BS'
      },
      {
        label: 'Barbados',
        value: 'BB'
      },
      {
        label: 'Cuba',
        value: 'CU'
      },
      {
        label: 'Dominica',
        value: 'DM'
      },
      {
        label: 'Dominican Republic',
        value: 'DO'
      },
      {
        label: 'Grenada',
        value: 'GD'
      },
      {
        label: 'Haiti',
        value: 'HT'
      },
      {
        label: 'Jamaica',
        value: 'JM'
      },
      {
        label: 'St. Kitts & Nevis',
        value: 'KN'
      },
      {
        label: 'St. Lucia',
        value: 'LC'
      },
      {
        label: 'St. Vincent & Grenadines',
        value: 'VC'
      },
      {
        label: 'Trinidad & Tobago',
        value: 'TT'
      },
      {
        label: 'Aruba',
        value: 'AW'
      },
      {
        label: 'CuraÇAo',
        value: 'CW'
      },
      {
        label: 'Bonaire, Sint Eustatius and Saba',
        value: 'BQ'
      },
      {
        label: 'Guadeloupe',
        value: 'GP'
      },
      {
        label: 'Martinique',
        value: 'MQ'
      }
    ]
  },
  {
    label: ReginEnum.WESTERN_EUROPE,
    value: ReginEnum.WESTERN_EUROPE,
    children: [
      {
        label: 'Ireland',
        value: 'IE'
      },
      {
        label: 'United Kingdom',
        value: 'GB'
      },
      {
        label: 'France',
        value: 'FR'
      },
      {
        label: 'Germany',
        value: 'DE'
      },
      {
        label: 'Spain',
        value: 'ES'
      },
      {
        label: 'Portugal',
        value: 'PT'
      },
      {
        label: 'Belgium',
        value: 'BE'
      },
      {
        label: 'Netherlands',
        value: 'NL'
      },
      {
        label: 'Luxembourg',
        value: 'LU'
      },
      {
        label: 'Switzerland',
        value: 'CH'
      },
      {
        label: 'Austria',
        value: 'AT'
      },
      {
        label: 'Italy',
        value: 'IT'
      },
      {
        label: 'Monaco',
        value: 'MC'
      },
      {
        label: 'Liechtenstein',
        value: 'LI'
      },
      {
        label: 'San Marino',
        value: 'SM'
      },
      {
        label: 'Vatican City',
        value: 'VA'
      },
      {
        label: 'Norway',
        value: 'NO'
      },
      {
        label: 'Iceland',
        value: 'IS'
      },
      {
        label: 'Denmark',
        value: 'DK'
      },
      {
        label: 'Finland',
        value: 'FI'
      },
      {
        label: 'Greece',
        value: 'GR'
      },
      {
        label: 'Croatia',
        value: 'HR'
      },
      {
        label: 'Slovenia',
        value: 'SI'
      },
      {
        label: 'Sweden',
        value: 'SE'
      },
      {
        label: 'Madeira',
        value: '#N/A'
      },
      {
        label: 'Malta',
        value: 'MT'
      },
      {
        label: 'Andorra',
        value: 'AD'
      },
      {
        label: 'Gibraltar',
        value: 'GI'
      }
    ]
  },
  {
    label: ReginEnum.EASTERN_EUROPE_AND_RUSSIA,
    value: ReginEnum.EASTERN_EUROPE_AND_RUSSIA,
    children: [
      {
        label: 'Russia',
        value: 'RU'
      },
      {
        label: 'Belarus',
        value: 'BY'
      },
      {
        label: 'Ukraine',
        value: 'UA'
      },
      {
        label: 'Poland',
        value: 'PL'
      },
      {
        label: 'Czechia',
        value: 'CZ'
      },
      {
        label: 'Slovakia',
        value: 'SK'
      },
      {
        label: 'Hungary',
        value: 'HU'
      },
      {
        label: 'Romania',
        value: 'RO'
      },
      {
        label: 'Bulgaria',
        value: 'BG'
      },
      {
        label: 'Lithuania',
        value: 'LT'
      },
      {
        label: 'Latvia',
        value: 'LV'
      },
      {
        label: 'Estonia',
        value: 'EE'
      },
      {
        label: 'Moldova',
        value: 'MD'
      },
      {
        label: 'Serbia',
        value: 'RS'
      },
      {
        label: 'Bosnia and Herzegovina',
        value: 'BA'
      },
      {
        label: 'Montenegro',
        value: 'ME'
      },
      {
        label: 'Albania',
        value: 'AL'
      },
      {
        label: 'Macedonia',
        value: 'MK'
      },
      {
        label: 'Armenia',
        value: 'AM'
      },
      {
        label: 'Azerbaijan',
        value: 'AZ'
      },
      {
        label: 'Tajikistan',
        value: 'TJ'
      },
      {
        label: 'Kazakhstan',
        value: 'KZ'
      },
      {
        label: 'Kyrgyzstan',
        value: 'KG'
      },
      {
        label: 'Tajikistan',
        value: 'UZ'
      },
      {
        label: 'Turkmenistan',
        value: 'TM'
      },
      {
        label: 'Georgia',
        value: 'GE'
      }
    ]
  },
  {
    label: ReginEnum.MIDDLE_EAST,
    value: ReginEnum.MIDDLE_EAST,
    children: [
      {
        label: 'Saudi Arabia',
        value: 'SA'
      },
      {
        label: 'United Arab Emirates',
        value: 'AE'
      },
      {
        label: 'Israel',
        value: 'IL'
      },
      {
        label: 'Jordan',
        value: 'JO'
      },
      {
        label: 'Lebanon',
        value: 'LB'
      },
      {
        label: 'Syria',
        value: 'SY'
      },
      {
        label: 'Iraq',
        value: 'IQ'
      },
      {
        label: 'Kuwait',
        value: 'KW'
      },
      {
        label: 'Oman',
        value: 'OM'
      },
      {
        label: 'Qatar',
        value: 'QA'
      },
      {
        label: 'Bahrain',
        value: 'BH'
      },
      {
        label: 'Yemen',
        value: 'YE'
      },
      {
        label: 'Iran',
        value: 'IR'
      },
      {
        label: 'Türkiye',
        value: 'TR'
      },
      {
        label: 'Palestine',
        value: 'PS'
      },
      {
        label: 'Egypt',
        value: 'EG'
      },
      {
        label: 'Cyprus',
        value: 'CY'
      }
    ]
  },
  {
    label: ReginEnum.SOUTH_ASIA,
    value: ReginEnum.SOUTH_ASIA,
    children: [
      {
        label: 'India',
        value: 'IN'
      },
      {
        label: 'Pakistan',
        value: 'PK'
      },
      {
        label: 'Bangladesh',
        value: 'BD'
      },
      {
        label: 'Nepal',
        value: 'NP'
      },
      {
        label: 'Sri Lanka',
        value: 'LK'
      },
      {
        label: 'Bhutan',
        value: 'BT'
      },
      {
        label: 'Maldives',
        value: 'MV'
      },
      {
        label: 'Afghanistan',
        value: 'AF'
      }
    ]
  },
  {
    label: ReginEnum.SOUTH_EAST_ASIA,
    value: ReginEnum.SOUTH_EAST_ASIA,
    children: [
      {
        label: 'Myanmar',
        value: 'MM'
      },
      {
        label: 'Thailand',
        value: 'TH'
      },
      {
        label: 'Vietnam',
        value: 'VN'
      },
      {
        label: 'Malaysia',
        value: 'MY'
      },
      {
        label: 'Singapore',
        value: 'SG'
      },
      {
        label: 'Indonesia',
        value: 'ID'
      },
      {
        label: 'Philippines',
        value: 'PH'
      },
      {
        label: 'Cambodia',
        value: 'KH'
      },
      {
        label: 'Laos',
        value: 'LA'
      },
      {
        label: 'Timor-Leste',
        value: 'TL'
      },
      {
        label: 'Brunei',
        value: 'BN'
      }
    ]
  },
  {
    label: ReginEnum.EAST_ASIA,
    value: ReginEnum.EAST_ASIA,
    children: [
      {
        label: 'China',
        value: 'CN'
      },
      {
        label: 'Japan',
        value: 'JP'
      },
      {
        label: 'South Korea',
        value: 'KR'
      },
      {
        label: 'Mongolia',
        value: 'MN'
      },
      {
        label: 'Taiwan',
        value: 'TW'
      },
      {
        label: 'Hong Kong SAR China',
        value: 'HK'
      },
      {
        label: 'Macau SAR China',
        value: 'MO'
      }
    ]
  },
  {
    label: ReginEnum.OCEANIA,
    value: ReginEnum.OCEANIA,
    children: [
      {
        label: 'Australia',
        value: 'AU'
      },
      {
        label: 'New Zealand',
        value: 'NZ'
      },
      {
        label: 'Papua New Guinea',
        value: 'PG'
      },
      {
        label: 'Fiji',
        value: 'FJ'
      },
      {
        label: 'Solomon Islands',
        value: 'SB'
      },
      {
        label: 'Vanuatu',
        value: 'VU'
      },
      {
        label: 'Samoa',
        value: 'WS'
      },
      {
        label: 'Kiribati',
        value: 'KI'
      },
      {
        label: 'Tonga',
        value: 'TO'
      },
      {
        label: 'Micronesia',
        value: 'FM'
      },
      {
        label: 'Marshall Islands',
        value: 'MH'
      },
      {
        label: 'Palau',
        value: 'PW'
      },
      {
        label: 'Nauru',
        value: 'NR'
      },
      {
        label: 'Tuvalu',
        value: 'TV'
      },
      {
        label: 'New Caledonia',
        value: 'NC'
      },
      {
        label: 'French Polynesia',
        value: 'PF'
      },
      {
        label: 'Wallis & Futuna',
        value: 'WF'
      },
      {
        label: 'Cook Islands',
        value: 'CK'
      },
      {
        label: 'Niue',
        value: 'NU'
      },
      {
        label: 'American Samoa',
        value: 'AS'
      },
      {
        label: 'Northern Mariana Islands',
        value: 'MP'
      },
      {
        label: 'Guam',
        value: 'GU'
      }
    ]
  },
  {
    label: ReginEnum.AFRICA,
    value: ReginEnum.AFRICA,
    children: [
      {
        label: 'Algeria',
        value: 'DZ'
      },
      {
        label: 'Angola',
        value: 'AO'
      },
      {
        label: 'Benin',
        value: 'BJ'
      },
      {
        label: 'Botswana',
        value: 'BW'
      },
      {
        label: 'Burkina Faso',
        value: 'BF'
      },
      {
        label: 'Burundi',
        value: 'BI'
      },
      {
        label: 'Cameroon',
        value: 'CM'
      },
      {
        label: 'Central African Republic',
        value: 'CF'
      },
      {
        label: 'Chad',
        value: 'TD'
      },
      {
        label: 'Comoros',
        value: 'KM'
      },
      {
        label: 'Democratic Republic of the Congo',
        value: 'CD'
      },
      {
        label: 'Republic of the Congo',
        value: 'CG'
      },
      {
        label: "Côte d'Ivoire",
        value: 'CI'
      },
      {
        label: 'Djibouti',
        value: 'DJ'
      },
      {
        label: 'Equatorial Guinea',
        value: 'GQ'
      },
      {
        label: 'Eritrea',
        value: 'ER'
      },
      {
        label: 'Swaziland',
        value: 'SZ'
      },
      {
        label: 'Ethiopia',
        value: 'ET'
      },
      {
        label: 'Gabon',
        value: 'GA'
      },
      {
        label: 'Gambia',
        value: 'GM'
      },
      {
        label: 'Ghana',
        value: 'GH'
      },
      {
        label: 'Guinea',
        value: 'GN'
      },
      {
        label: 'Guinea-Bissau',
        value: 'GW'
      },
      {
        label: 'Kenya',
        value: 'KE'
      },
      {
        label: 'Lesotho',
        value: 'LS'
      },
      {
        label: 'Liberia',
        value: 'LR'
      },
      {
        label: 'Madagascar',
        value: 'MG'
      },
      {
        label: 'Malawi',
        value: 'MW'
      },
      {
        label: 'Mali',
        value: 'ML'
      },
      {
        label: 'Mauritania',
        value: 'MR'
      },
      {
        label: 'Mauritius',
        value: 'MU'
      },
      {
        label: 'Mayotte',
        value: 'YT'
      },
      {
        label: 'Morocco',
        value: 'MA'
      },
      {
        label: 'Mozambique',
        value: 'MZ'
      },
      {
        label: 'Namibia',
        value: 'NA'
      },
      {
        label: 'Niger',
        value: 'NE'
      },
      {
        label: 'Nigeria',
        value: 'NG'
      },
      {
        label: 'Rwanda',
        value: 'RW'
      },
      {
        label: 'São Tomé and Príncipe',
        value: 'ST'
      },
      {
        label: 'Senegal',
        value: 'SN'
      },
      {
        label: 'Seychelles',
        value: 'SC'
      },
      {
        label: 'Sierra Leone',
        value: 'SL'
      },
      {
        label: 'Somalia',
        value: 'SO'
      },
      {
        label: 'South Africa',
        value: 'ZA'
      },
      {
        label: 'South Sudan',
        value: 'SS'
      },
      {
        label: 'Sudan',
        value: 'SD'
      },
      {
        label: 'Tanzania',
        value: 'TZ'
      },
      {
        label: 'Togo',
        value: 'TG'
      },
      {
        label: 'Uganda',
        value: 'UG'
      },
      {
        label: 'Western Sahara',
        value: 'EH'
      },
      {
        label: 'Zambia',
        value: 'ZM'
      },
      {
        label: 'Zimbabwe',
        value: 'ZW'
      },
      {
        label: 'Libya',
        value: 'LY'
      },
      {
        label: 'Tunisia',
        value: 'TN'
      },
      {
        label: 'Cape Verde',
        value: 'CV'
      }
    ]
  },
  {
    label: ReginEnum.Other,
    value: ReginEnum.Other,
    children: [
      {
        label: 'Åland Islands',
        value: 'AX'
      },
      {
        label: 'Bouvet Island',
        value: 'BV'
      },
      {
        label: 'British Indian Ocean Territory',
        value: 'IO'
      },
      {
        label: 'Falkland Islands',
        value: 'FK'
      },
      {
        label: 'Faroe Islands',
        value: 'FO'
      },
      {
        label: 'Guernsey',
        value: 'GG'
      },
      {
        label: 'Heard Island and McDonald Islands',
        value: 'HM'
      },
      {
        label: 'Isle of Man',
        value: 'IM'
      },
      {
        label: 'Jersey',
        value: 'JE'
      },
      {
        label: 'Saint Martin',
        value: 'MF'
      },
      {
        label: 'St. Pierre & Miquelon',
        value: 'PM'
      },
      {
        label: 'Pitcairn Islands',
        value: 'PN'
      },
      {
        label: 'Puerto Rico',
        value: 'PR'
      },
      {
        label: 'Réunion',
        value: 'RE'
      },
      {
        label: 'St. Helena',
        value: 'SH'
      },
      {
        label: 'Svalbard and Jan Mayen',
        value: 'SJ'
      },
      {
        label: 'Tokelau',
        value: 'TK'
      },
      {
        label: 'Turks & Caicos Islands',
        value: 'TC'
      },
      {
        label: 'United States Minor Outlying Islands',
        value: 'UM'
      },
      {
        label: 'British Virgin Islands',
        value: 'VG'
      },
      {
        label: 'U.S. Virgin Islands',
        value: 'VI'
      }
    ]
  }
];

// 枚举的动态生成函数
function createEnum(
  data: {
    children: { label: string; value: string }[];
  }[]
): Record<string, string> {
  const enumObject: Record<string, string> = {};

  data.forEach((group) => {
    group.children.forEach((item) => {
      enumObject[item.value] = item.label;
    });
  });
  return enumObject;
}

export const NationsLab = createEnum(locationOptions);
