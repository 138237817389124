import React, { useEffect, useState } from 'react';

const PaymentInfo: React.FC<{ data: any }> = ({ data }) => {
  const { campaign } = data;
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState<string[]>([]);

  useEffect(() => {
    if (campaign) {
      setLoading(false);
    }

    if (campaign?.mustDoList?.length > 0 && !!campaign?.mustDoList.join('')) {
      setBrand(campaign?.mustDoList);
    }
  }, [campaign]);

  if (loading) return <PaymentInfoSkeleton />;

  if (brand.length === 0) return null;
  return (
    <>
      <div className="w-full ml-1 flex-col justify-start items-start gap-2 inline-flex">
        <Brand brand={brand} />
      </div>
    </>
  );
};

export default PaymentInfo;

const PaymentInfoSkeleton = () => {
  return (
    <>
      <div className="text-xl font-semibold font-['Geist'] leading-loose">
        Brand
      </div>
      <div className="flex flex-col gap-2">
        <div className="h-[20px] w-[203px] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[203px] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
      </div>
    </>
  );
};

export const Brand: React.FC<{ brand: Array<string> }> = ({ brand }) => {
  return (
    <div className="flex flex-col gap-2">
      {brand.map((item, index) => (
        <div key={index} className="flex">
          <div className="flex-shrink-0 w-[16px]">
            <div className="w-1.5 h-1.5 rounded-full bg-black mt-[9px]"></div>
          </div>
          <p className="whitespace-pre-wrap">{item}</p>
        </div>
      ))}
    </div>
  );
};
