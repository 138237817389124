import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { linkValidator } from '@/biz/validator';
import { WarnRedIcon } from '@/components/common/icon';
import { submitModalLinkTitle, SubmitStatusEnum } from '../config';
import styles from './submitButton.module.less';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { styled } from '@material-ui/core/styles';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { AdsPlatform } from '@/interface/order';
import ProductLogo from '@/components/web/components/ProductLogo';
import { normalizePrice } from '@/utils/normalizePrice';
import {
  getPlatformName,
  getSubmitModalDesc,
  platformDraftLinkValidator,
  platformFinalLinkValidator
} from '@/biz/platform';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '5B3DE9'
    }
  }
})(TextField);

interface Props {
  setLink: Function;
  setDesc: Function;
  setFormIdea: Function;
  setIsCanSubmit: Function;
  submitStatus: string;
  errorTitle: string;
}

const SubmitModalContent: React.FC<Props> = ({
  setLink,
  setDesc,
  setFormIdea,
  setIsCanSubmit,
  submitStatus,
  errorTitle
}) => {
  const { detailData } = useDetailDataStore();
  // platform 相关参数
  const AHA_PLATFORM = detailData?.order?.platform;
  const UpAhaPlatform = getPlatformName(AHA_PLATFORM);
  const submitModalDesc = getSubmitModalDesc(AHA_PLATFORM)[submitStatus];
  //
  const [mounted, setMounted] = useState(false);
  const [idea, setIdea] = useState('');
  const [shouldSelectIdea, setShouldSelectIdea] = useState(false);
  const [creatives, setCreatives] = useState<any[]>([]);
  const [url, setUrl] = useState('');
  const [remarks, setRemarks] = useState('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [validErrorText, setValidErrorText] = useState('');

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const platform = detailData?.order?.platform;
    const creatorNextOperateType = detailData?.order?.creatorNextOperateType;
    const creatives = detailData?.order?.creatives;
    if (
      platform === AdsPlatform.Youtube &&
      creatorNextOperateType === 'ScriptSubmit' &&
      creatives?.length > 0
    ) {
      setShouldSelectIdea(true);
      setCreatives([{ creativeId: '', creativeTitle: '' }, ...creatives]);
    } else if (
      platform !== AdsPlatform.Youtube &&
      creatorNextOperateType === 'Draft_1_Submit' &&
      creatives?.length > 0
    ) {
      setShouldSelectIdea(true);
      setCreatives([{ creativeId: '', creativeTitle: '' }, ...creatives]);
    }
  }, [detailData]);

  useEffect(() => {
    if (shouldSelectIdea && !idea) {
      setIsCanSubmit(false);
      return;
    }
    if (url) {
      let isValidLink = false;
      if (submitStatus === SubmitStatusEnum.script) {
        // script 是 url 链接
        isValidLink = linkValidator(url);
      } else if (submitStatus === SubmitStatusEnum.video) {
        // draft 是 url 链接
        isValidLink = platformDraftLinkValidator(url, AHA_PLATFORM);
      } else {
        // final 是 XXXX平台 链接
        isValidLink = platformFinalLinkValidator(url, AHA_PLATFORM);
      }

      if (!isValidLink) {
        if (submitStatus === SubmitStatusEnum.script) {
          setValidErrorText('Please enter the correct URL link.');
        } else {
          setValidErrorText(`Please enter the correct ${UpAhaPlatform} link.`);
        }
      } else {
        setValidErrorText('');
      }

      setIsValid(isValidLink);
      setIsCanSubmit(isValidLink);
    } else if (url === '' && mounted) {
      setIsValid(false);
      setIsCanSubmit(false);
    }
  }, [url, idea]);

  useEffect(() => {
    setFormIdea(idea);
  }, [idea]);

  useEffect(() => {
    setLink(url);
  }, [url]);

  useEffect(() => {
    setDesc(remarks);
  }, [remarks]);

  return (
    <div className={styles.scriptContainer}>
      <div className={styles.scriptDes}>{submitModalDesc}</div>

      <div
        className=" items-center gap-2 mt-4 border-b border-[#eeeeee] pb-4"
        style={{
          display: submitStatus === SubmitStatusEnum.finalLink ? 'flex' : 'none'
        }}
      >
        <ProductLogo size={48} src={detailData?.product?.logo} />
        <div className="flex-1">
          <div className="text-[#1c2024] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
            {detailData?.product?.name}
          </div>
          <a
            className="text-sm font-normal text-[#0c67e6] hover:underline break-all"
            href={detailData?.product?.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            {detailData?.product?.website}
          </a>
        </div>
        <div className="text-[#1c2024] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
          {!isNaN(detailData?.order?.price)
            ? normalizePrice(detailData?.order?.price)
            : ''}
        </div>
      </div>
      {/*  */}
      {shouldSelectIdea ? (
        <>
          <div className={styles.draftLink}>
            <div className={styles.draftLinkText}>
              <div className={styles.draftLinkTitle}>
                <span>Select script idea</span>
                <span style={{ color: '#d43710' }}>*</span>
              </div>
            </div>
          </div>
          <NativeSelect
            id="demo-customized-select-native"
            className="w-full rounded-md border border-[#E0E0E0]"
            value={idea}
            onChange={(event: { target: { value: string } }) => {
              setIdea(event?.target?.value);
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            input={<BootstrapInput />}
          >
            {creatives?.map((creative: any) => {
              return (
                <option value={creative.creativeId}>
                  {creative.creativeTitle}
                </option>
              );
            })}
          </NativeSelect>
        </>
      ) : null}
      {/*  */}
      <div className={styles.draftLink}>
        <div className={styles.draftLinkText}>
          <div className={styles.draftLinkTitle}>
            <span>{submitModalLinkTitle[submitStatus]}</span>
            <span style={{ color: '#d43710' }}>*</span>
          </div>
        </div>
        <CssTextField
          // id 用于浏览器联想
          // id=Script draft link
          id={`${submitModalLinkTitle[submitStatus]}`}
          className={`${styles.input} ${isValid ? '' : styles.inputError}`}
          placeholder="Paste link"
          InputProps={{ disableUnderline: true }}
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
        />
        {validErrorText ? (
          <div className={styles.errorDesc}>
            {validErrorText ||
              `Please enter the correct ${UpAhaPlatform} link.`}
          </div>
        ) : null}
        {errorTitle ? (
          <div className="px-4 py-0.5 bg-[#ffebe5] rounded justify-center items-center gap-1 inline-flex mt-2">
            <WarnRedIcon className="w-3.5 h-3.5 mt-[2px]" />
            <div className="grow shrink basis-0 text-[#d43710] text-xs font-normal font-['Inter'] leading-tight">
              {errorTitle || `Please enter the correct ${UpAhaPlatform} link.`}
            </div>
          </div>
        ) : null}
      </div>
      {submitStatus !== SubmitStatusEnum.finalLink && (
        <div className={styles.draftLink}>
          <div className={styles.draftLinkText}>
            <div className={styles.draftLinkTitle}>
              <span>Additional notes</span>
            </div>
          </div>
          <CssTextField
            id="remark"
            className={styles.textArea}
            placeholder="Type your message here."
            InputProps={{ disableUnderline: true }}
            multiline
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SubmitModalContent;
