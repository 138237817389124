import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import toast from 'react-hot-toast';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper/modules';
import {
  getLoginSuccessFromUrlSearch,
  getDetailPageUrl,
  isMobile
} from '@/biz/tool';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ModalLoginSuccess: React.FC = () => {
  const [showLoginSuccessModal, setShowLoginSuccessModal] = useState(false);

  useEffect(() => {
    if (getLoginSuccessFromUrlSearch()) {
      const url = getDetailPageUrl();
      setTimeout(() => {
        setShowLoginSuccessModal(true);
        toast.success('Login successful!');
        // 清除url中的loginSuccess参数
        window.history.pushState({}, '', url);
      }, 0);
    }
  }, []);

  return (
    <>
      {/* 登录成功弹窗 */}
      <Modal
        open={showLoginSuccessModal}
        // onClose={() => setShowLoginSuccessModal(false)}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        disablePortal
      >
        <div
          className={`bg-white relative  left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]  
        ${isMobile() ? 'w-[342px] py-8 rounded-2xl' : 'w-[515px] py-6 rounded-lg'}`}
        >
          <Swiper
            id="aha-swiper"
            modules={[Navigation, Pagination, A11y, Autoplay]}
            slidesPerView={1}
            loop
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            pagination={{
              clickable: true,
              bulletClass: 'swiper-pagination-bullet',
              bulletActiveClass:
                'swiper-pagination-bullet-active !bg-blue-600 !opacity-100'
            }}
            className="w-full [&_.swiper-pagination]:!bottom-auto [&_.swiper-pagination]:!top-[calc(159px+0px)]"
          >
            {swiperData.map((item, index) => (
              <SwiperSlide
                key={index}
                className="flex flex-col items-center px-6"
              >
                <div
                  className={`h-[159px]
                  ${isMobile() ? 'mb-[26px]' : 'mb-[42px]'} rounded-xl overflow-hidden`}
                >
                  <img
                    src={item.avatar}
                    alt={item.title}
                    className="h-full w-full object-cover"
                  />
                </div>
                <h2
                  className={`text-xl text-[#1c2024] font-semibold text-center
                ${isMobile() ? 'mb-2' : 'mb-4'}`}
                >
                  {item.title}
                </h2>
                <p
                  className={`text-sm text-gray-500 text-center 
                ${isMobile() ? 'mb-[24px]' : 'mb-[26px]'}`}
                >
                  {item.desc}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="px-6">
            <Button
              variant="contained"
              fullWidth
              className="mui-btn-primary !h-10"
              onClick={() => setShowLoginSuccessModal(false)}
            >
              Get started
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalLoginSuccess;

interface SwiperItemProps {
  avatar: string;
  title: string;
  desc: string;
}

const swiperData: SwiperItemProps[] = [
  {
    avatar: '/login-success/banner01.png',
    title: '🎉Getting started with your Aha journey',
    desc: 'Excel in what you love, earn what you deserve'
  },
  {
    avatar: isMobile()
      ? '/login-success/banner02-mobile.png'
      : '/login-success/banner02.png',
    title: 'More relevant brand partnerships',
    desc: 'Get intelligently matched with brands seeking your unique skills.'
  },
  {
    avatar: '/login-success/banner03.png',
    title: 'Secure and Timely Payments',
    desc: ' Enjoy reliable, secure, on-time payments for peace of mind.'
  },
  {
    avatar: '/login-success/banner04.png',
    title: 'Boost Your Earnings',
    desc: 'Let our AI maximize your earnings with competitive pricing.'
  },
  {
    avatar: '/login-success/banner05.png',
    title: 'Streamlined Collaboration',
    desc: 'Focus on creating while we simplify project management.'
  }
];
