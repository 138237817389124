import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type GrowthBookObject = {
  [key: string]: {
    [key: string]: {
      value: string;
      timestamp: number;
    };
  };
};

type GrowthBookStore = {
  campaignAndOrderIds: GrowthBookObject;
  addCampaignAndOrderIds: (
    id: string,
    growthbookFeatureId: string,
    value: string
  ) => void;
  getCampaignAndOrderId: (
    campaignAndOrderId: string,
    growthbookFeatureId: string
  ) => string;
  clearExpiredData: () => void;
  clearCampaignAndOrderIds: () => void;
};

export const useGrowthBookStore = create(
  persist<GrowthBookStore>(
    (set, get) => ({
      campaignAndOrderIds: {
        // '35766d7e89470ddbaa1bfd787a2': {
        //   pricing_schema: {
        //     value: 'display_price_cannot_change',
        //     timestamp: Date.now()
        //   },
        //   age_schema: {
        //     value: 'display_price_cannot_change',
        //     timestamp: Date.now()
        //   }
        // }
      },
      addCampaignAndOrderIds: (
        campaignAndOrderId: string,
        growthbookFeatureId: string,
        value: string
      ) => {
        get().clearExpiredData();

        set((state) => ({
          campaignAndOrderIds: {
            ...state.campaignAndOrderIds,
            [campaignAndOrderId]: {
              ...state.campaignAndOrderIds[campaignAndOrderId],
              [growthbookFeatureId]: {
                value,
                timestamp: Date.now()
              }
            }
          }
        }));
      },
      getCampaignAndOrderId: (
        campaignAndOrderId: string,
        growthbookFeatureId: string
      ) =>
        get().campaignAndOrderIds?.[campaignAndOrderId]?.[growthbookFeatureId]
          ?.value,
      clearExpiredData: () => {
        const MAX_AGE = 7 * 24 * 60 * 60 * 1000; // 7天过期
        const now = Date.now();

        set((state) => {
          const newCampaignAndOrderIds = { ...state.campaignAndOrderIds };

          // 使用 Object.entries 来遍历对象，这样可以更安全地处理可能的 undefined 值
          Object.keys(newCampaignAndOrderIds).forEach((campaignId) => {
            if (!newCampaignAndOrderIds[campaignId]) return;

            const campaign = newCampaignAndOrderIds[campaignId];
            let hasValidFeatures = false;

            Object.keys(campaign).forEach((featureId) => {
              const data = campaign[featureId];
              if (!data || now - data.timestamp > MAX_AGE) {
                delete campaign[featureId];
              } else {
                hasValidFeatures = true;
              }
            });

            // 如果该 campaign 下没有有效的 feature，删除整个 campaign
            if (!hasValidFeatures) {
              delete newCampaignAndOrderIds[campaignId];
            }
          });

          return { campaignAndOrderIds: newCampaignAndOrderIds };
        });
      },
      clearCampaignAndOrderIds: () => set({ campaignAndOrderIds: {} })
    }),
    {
      name: 'growthBook-list'
    }
  )
);
