import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { isMobile } from '@/biz/tool';
import { BLingIcon } from '@/components/common/icon';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8ec'
  },
  scroller: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': { display: 'none' }, // Chrome/Safari
    '-ms-overflow-style': 'none', // IE/Edge
    'scrollbar-width': 'none' // Firefox
  },
  indicator: {
    backgroundColor: '#5a31f0'
  }
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: isMobile() ? 20 : 107,
      maxWidth: 180,
      fontSize: '16px',
      whiteSpace: 'nowrap',
      paddingLeft: '0px',
      paddingRight: '12px',
      '&:focus': {
        color: '#1c2024',
        fontWeight: '600'
      },
      '&$selected': {
        color: '#1c2024',
        fontWeight: '600'
      }
    },
    selected: {}
  })
)((props: StyledTabProps) => (
  <div style={{ position: 'relative' }}>
    <Tab disableRipple {...props} />
    {props?.isShowHistoryMark && (
      <div
        style={{
          position: 'absolute',
          width: '6px',
          height: '6px',
          backgroundColor: '#D43710',
          top: '10px',
          right: 0,
          borderRadius: '50%'
        }}
      ></div>
    )}
  </div>
));

const AntTabColorful = withStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 1,
      background: 'linear-gradient(180deg, #3385FF 0%, #5A31F0 100%)',
      '-webkit-background-clip': 'text',
      'background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      textTransform: 'none',
      minWidth: 107,
      maxWidth: 180,
      fontSize: '16px',
      whiteSpace: 'nowrap',

      '&:focus': {
        // color: '#1c2024',
        fontWeight: '600'
      },
      '&$selected': {
        // color: '#1c2024',
        fontWeight: '600'
      }
    },
    selected: {}
  })
)((props: StyledTabProps) => (
  <div style={{ position: 'relative', paddingLeft: '14px' }}>
    <div
      className={`absolute
      ${isMobile() ? 'top-[9px] left-[2px] w-5 h-5' : 'top-[12px] left-[6px] w-5 h-5'}
      `}
    >
      <BLingIcon className="w-5 h-5" />
    </div>
    <Tab disableRipple {...props} />
    {/* {props?.isShowHistoryMark && (
      <div
        style={{
          position: 'absolute',
          width: '6px',
          height: '6px',
          backgroundColor: '#D43710',
          top: '10px',
          right: 0,
          borderRadius: '50%'
        }}
      ></div>
    )} */}
  </div>
));

interface StyledTabProps {
  label: string;
  isShowHistoryMark: boolean;
  value: string | number;
}

const CommonTabs: React.FC<{
  tabId: number | string;
  handleChange: Function;
  tabItems: string[];
  isShowHistoryMark: boolean;
  isShowMarkIndex: number;
}> = ({
  tabId,
  tabItems,
  handleChange,
  isShowHistoryMark,
  isShowMarkIndex
}) => {
  return (
    <AntTabs
      value={tabId}
      onChange={(event: any, newValue: any) => handleChange(event, newValue)}
      aria-label="ant example"
      style={{ width: 'auto' }}
      classes={{
        scroller: '!overflow-x-auto'
      }}
    >
      {tabItems.map((item, index) => {
        if (item !== 'Aha studio') {
          return (
            <AntTab
              label={item}
              key={item}
              value={item}
              isShowHistoryMark={isShowHistoryMark && index === isShowMarkIndex}
            />
          );
        }
        return (
          <AntTabColorful
            label={item}
            key={item}
            value={item}
            isShowHistoryMark={isShowHistoryMark && index === isShowMarkIndex}
          />
          // <div className="px-3 py-[6px] flex items-center cursor-pointer">
          //   <div className="text-[#3284ff] text-base font-semibold font-['Geist'] leading-normal">
          //     {item}
          //   </div>
          // </div>
        );
      })}
    </AntTabs>
  );
};

export default CommonTabs;
