import { create } from 'zustand';

interface PriceField {
  contentType: string;
  contentGenre: string;
  price: number;
}

export enum GbPricingSchemaFlag {
  DISPLAY_PRICE_CANNOT_CHANGE = 'display_price_cannot_change',
  NOT_DISPLAY_PRICE_CAN_CHANGE = 'not_display_price_can_change',
  DISPLAY_PRICE_CAN_CHANGE = 'display_price_can_change'
}

type MultiplePricesStore = {
  gbPricingSchemaFlag: GbPricingSchemaFlag;
  setGbPricingSchemaFlag: (gbPricingSchemaFlag: GbPricingSchemaFlag) => void;
  priceFields: PriceField[];
  setPriceFields: (priceFields: PriceField[]) => void;
  selectedPriceFields: PriceField[];
  setSelectedPriceFields: (selectedPriceFields: PriceField[]) => void;
  clearAllMultiplePricesStore: () => void;
  addressInfo: any;
  setAddressInfo: (addressInfo: any) => void;
};

export const useMultiplePricesStore = create<MultiplePricesStore>()((set) => ({
  gbPricingSchemaFlag: GbPricingSchemaFlag.DISPLAY_PRICE_CANNOT_CHANGE,
  setGbPricingSchemaFlag: (gbPricingSchemaFlag: GbPricingSchemaFlag) =>
    set({ gbPricingSchemaFlag }),
  priceFields: [],
  setPriceFields: (priceFields: PriceField[]) => set({ priceFields }),
  selectedPriceFields: [],
  setSelectedPriceFields: (selectedPriceFields: PriceField[]) =>
    set({ selectedPriceFields }),
  addressInfo: {},
  setAddressInfo: (addressInfo: any) => set({ addressInfo }),
  clearAllMultiplePricesStore: () =>
    set({ priceFields: [], selectedPriceFields: [] })
}));
