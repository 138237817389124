import { create } from 'zustand';
import axiosInstance from '@/apis/axiosInstance';

interface PlatformStore {
  influencers: any[] | null;
  user: any;
  platformList: any[];
  phylloSDKConfig: any;
  loading: boolean;
  lastFetchTime: number | null;
  isRequesting: boolean;
  setInfluencers: (influencers: any[]) => void;
  setUser: (user: any) => void;
  setPlatformList: (platforms: any[]) => void;
  setPhylloSDKConfig: (config: any) => void;
  fetchPlatformData: () => Promise<void>;
}

export const usePlatformStore = create<PlatformStore>((set, get) => ({
  influencers: null, // 已绑定的
  setInfluencers: (influencers) => set({ influencers }),
  user: {},
  setUser: (user) => set({ user }),
  platformList: [],
  phylloSDKConfig: {},
  loading: false,
  lastFetchTime: null,
  isRequesting: false,
  setPlatformList: (platforms) => set({ platformList: platforms }),
  setPhylloSDKConfig: (config) => set({ phylloSDKConfig: config }),
  fetchPlatformData: async () => {
    const CACHE_DURATION = 5 * 60 * 1000;
    const currentTime = Date.now();
    const lastFetch = get().lastFetchTime;

    if (get().isRequesting) {
      // console.log('已有请求在进行中，跳过');
      return;
    }

    if (lastFetch && currentTime - lastFetch < CACHE_DURATION) {
      // console.log('使用缓存数据，跳过请求');
      return;
    }

    set({ isRequesting: true });
    set({ loading: true });

    try {
      const [platformsResult, sdkConfigResult] = await Promise.allSettled([
        axiosInstance.get('/auth/phyllo/activeWorkPlatforms'),
        axiosInstance.get('/auth/phyllo/sdkConfig')
      ]);

      if (platformsResult.status === 'fulfilled') {
        set({ platformList: platformsResult.value.data.data });
      } else {
        console.error('获取平台列表失败:', platformsResult.reason);
      }

      if (sdkConfigResult.status === 'fulfilled') {
        set({ phylloSDKConfig: sdkConfigResult.value.data.data });
      } else {
        console.error('获取SDK配置失败:', sdkConfigResult.reason);
      }

      set({ lastFetchTime: currentTime });
    } finally {
      set({ loading: false });
      set({ isRequesting: false });
    }
  }
}));
