import React from 'react';
import SubmitModalContentDefault from './SubmitModalContentDefault';
import { AdsPlatform } from '@/interface/order';

interface Props {
  platform: AdsPlatform;
  setLink: Function;
  setDesc: Function;
  setFormIdea: Function;
  setIsCanSubmit: Function;
  submitStatus: string;
  errorTitle: string;
}

const SubmitModalContent: React.FC<Props> = (props) => {
  return (
    <>
      <SubmitModalContentDefault {...props} />
    </>
  );
};

export default SubmitModalContent;
