export const TEST_API_BASE_URL = 'https://api.test.ahalab.io/';
export const PRE_API_BASE_URL = 'https://api.pre.ahalab.io/';
export const BASE_URL = 'https://api.ahalab.io/';

// Define a type alias for environment names
type Environment = 'production' | 'pre' | 'staging' | 'development';

interface Config {
  BASE_URL: string;
  applicationId: string;
  clientToken: string;
  env: Environment;
  clerkPublishableKey: string;
  oneSignalAppId: string;
  stripe_public_key: string;
}

// staging 为测试环境。
const stripe_public_key =
  'pk_test_51QFSPwCmpvoJKd3Q3N1NrBOCAYjc6tSPmaCyUYSaEEHynd01MtyKAdmVIsUiHC6dMm7bbSOQxNKDW9Rst9jjWgIH00U0vugJVy';
const stripe_product_public_key =
  'pk_live_51QFSPwCmpvoJKd3QLWua58AL4cbffFGHhYlEdibmqACzTrgbac6PXgrfGgiGp389kNBs8QUXNoDkX7gmifFX67VZ00XofQo9TS';

const environmentConfig: Record<Environment, Config> = {
  production: {
    BASE_URL: BASE_URL,
    // 观测云 prod 工作空间
    // alalab-influencer
    applicationId: '673b6070_645f_11ef_bde9_8523879b12de',
    clientToken: '486e29412fca488c96b22f0bfb334163',
    env: 'production',
    clerkPublishableKey: 'pk_live_Y2xlcmsuY3JlYXRvci5haGFnbG9iYWwuaW8k',
    oneSignalAppId: 'ebe4fe94-c66a-4a5f-a944-0a162992927c',
    stripe_public_key: stripe_product_public_key
  },
  pre: {
    BASE_URL: PRE_API_BASE_URL,
    // 观测云 pre 工作空间
    applicationId: '',
    clientToken: '',
    env: 'pre',
    clerkPublishableKey: '',
    oneSignalAppId: 'ebe4fe94-c66a-4a5f-a944-0a162992927c',
    stripe_public_key: stripe_product_public_key
  },
  staging: {
    BASE_URL: TEST_API_BASE_URL,
    // 观测云 test 工作空间，后会改名为 staging
    // alalab-influencer
    applicationId: '3f3874f0_645f_11ef_8616_a1de74c8cd9a',
    clientToken: '5cc46982375849f2876af25c104bcd26',
    env: 'staging',
    clerkPublishableKey:
      'pk_test_c3VwZXJiLXNwb25nZS05My5jbGVyay5hY2NvdW50cy5kZXYk',
    oneSignalAppId: '207e00ea-bf13-4143-a957-483397f582b0',
    stripe_public_key
  },
  development: {
    BASE_URL: TEST_API_BASE_URL,
    // advertiser-platform
    applicationId: '979ebd10_b5dc_11ef_8a49_6924ceb4c413',
    clientToken: 'dba74988e50b4aad8bcca68dc6123897',
    env: 'staging',
    // clerkPublishableKey: 'pk_test_c291Z2h0LWdlbGRpbmctNDMuY2xlcmsuYWNjb3VudHMuZGV2JA',
    clerkPublishableKey:
      'pk_test_c3VwZXJiLXNwb25nZS05My5jbGVyay5hY2NvdW50cy5kZXYk',
    oneSignalAppId: '207e00ea-bf13-4143-a957-483397f582b0',
    stripe_public_key
  }
};

const env = (process.env.REACT_APP_ENV as Environment) || 'staging';
const appConfig = environmentConfig[env];

export default appConfig;
