import React from 'react';
import { RightArrowIcon } from '@/components/common/icon';
import styles from './resourceItemCard.module.less';
import { isMobile } from '@/biz/tool';

interface Props {
  item: {
    Icon: any;
    title: string;
    desc: string;
    link: string;
  };
}

const ResourceItem: React.FC<Props> = ({ item }) => {
  if (isMobile()) {
    return (
      <div className={styles.mobileContainer}>
        <a href={item.link} target="_blank" rel="noopener noreferrer">
          <div className={styles.header}>
            <div className={styles.left}>
              <item.Icon />
              <div className={styles.title}>{item?.title}</div>
            </div>
            <RightArrowIcon />
          </div>
          <div className={styles.desc}>{item?.desc}</div>
        </a>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <a href={item.link} target="_blank" rel="noopener noreferrer">
        <item.Icon />
        <div className={styles.content}>
          <div className={styles.title}>
            {item?.title}
            <RightArrowIcon />
          </div>
          <div className={styles.desc}>{item?.desc}</div>
        </div>
      </a>
    </div>
  );
};

export default ResourceItem;
