import GA from 'react-ga4';
import { getOrderId } from '@/biz/tool';

export const fixedDetailBottomHeight = () => {
  setTimeout(() => {
    const detail = document.getElementById('detail');
    const bottomEle = document.getElementById('bottom');
    // console.log(bottomEle?.getBoundingClientRect().height);

    if (detail) {
      if (bottomEle) {
        detail.style.paddingBottom = `${Math.max(Number(bottomEle?.getBoundingClientRect().height || 0), 200) + 30}px`;
      } else {
        detail.style.paddingBottom = '30px';
      }
    }
  }, 100);
};

export const copyToClipboard = (text: string) => {
  // 创建一个文本域
  const textArea = document.createElement('textarea');
  // 隐藏掉这个文本域，使其在页面上不显示
  textArea.style.position = 'fixed';
  textArea.style.visibility = '-10000px';
  // 将需要复制的内容赋值给文本域
  textArea.value = text;
  // 将这个文本域添加到页面上
  document.body.appendChild(textArea);
  // 添加聚焦事件，写了可以鼠标选取要复制的内容
  textArea.focus();
  // 选取文本域内容
  textArea.select();

  if (!document.execCommand('copy')) {
    // 检测浏览器是否支持这个方法
    console.warn('浏览器不支持 document.execCommand("copy")');
    // 复制失败将构造的标签 移除
    document.body.removeChild(textArea);
    return false;
  } else {
    console.log('复制成功');
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(textArea);
    return true;
  }
};

export const Tracker = {
  click: (label: string, value?: number, category?: string) => {
    const event = {
      action: 'click',
      value: value || Number(getOrderId()),
      category: category || String(window.localStorage.getItem('platform')), // twitter, youtube, or null
      label
    };
    window.localStorage.setItem(
      'preLogInfo',
      JSON.stringify({ time: Date.now(), event })
    );
    GA.event(event);

    //解决埋点延迟发送，导致点击切换页面时，上个页面的埋点不发送问题
    setTimeout(() => {
      window.localStorage.setItem('preLogInfo', 'false');
    }, 8000);
  },
  gtag: (operation: any, event: string, params: any) => {
    GA.gtag(operation, event, params);
  },
  growthbookConfirmPrice: ({
    event_params
  }: {
    event_params?: {
      platform_price_d: number | string | undefined;
      platform_price_i: number | string | undefined;
      creator_price_d: number | string | undefined;
      creator_price_i: number | string | undefined;
      order: any;
    };
  }) => {
    //  GA.gtag 可上报自定义维度
    GA.gtag('event', 'experiment_viewed_confirm_price', {
      platform_price_d: Number(event_params?.platform_price_d),
      platform_price_i: Number(event_params?.platform_price_i),
      creator_price_d: Number(event_params?.creator_price_d),
      creator_price_i: Number(event_params?.creator_price_i),
      category: String(window.localStorage.getItem('platform')), // twitter, youtube, or null
      order_id: event_params?.order.id,
      campaign_id: event_params?.order.campaignId,
      growthbook_id: event_params?.order.campaignId + event_params?.order.id
    });
  },
  growthbook: ({
    category,
    event_params
  }: {
    category?: string;
    event_params?: {
      experimentId: string;
      variationId: string;
      orderId: string;
    };
  }) => {
    //  GA.gtag 可上报自定义维度
    GA.gtag('event', 'experiment_viewed', {
      experiment_id: event_params?.experimentId,
      variation_id: event_params?.variationId,
      category: String(window.localStorage.getItem('platform')), // twitter, youtube, or null
      order_id: event_params?.orderId
    });
  },
  send: GA.send
};

//补发送 上一个页面没来的及发送的log
const cleanPrePageLogInfo = () => {
  window.onload = () => {
    let preLogInfo = window.localStorage.getItem('preLogInfo');

    if (preLogInfo && preLogInfo !== 'false') {
      try {
        const { time, event } = JSON.parse(preLogInfo);
        if (Date.now() - time < 8000) {
          GA.event(event);
          window.localStorage.setItem('preLogInfo', 'false');
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
};

export const initCustomEvent = () => {
  (function (window) {
    if (!window || !navigator) return;
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

    let KeyboardUpEvent = document.createEvent('KeyboardEvent');
    KeyboardUpEvent.initEvent('KeyboardUp', true, true);

    let KeyboardDownEvent = document.createEvent('KeyboardEvent');
    KeyboardDownEvent.initEvent('KeyboardDown', true, true);

    if (isAndroid) {
      let originalHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let lastRiseHeight = 0;

      window.onresize = function () {
        let resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        if (originalHeight / resizeHeight > 1.3 && lastRiseHeight === 0) {
          document.dispatchEvent(KeyboardUpEvent);
          lastRiseHeight = resizeHeight;
        } else if (
          lastRiseHeight !== 0 &&
          resizeHeight / lastRiseHeight > 1.3
        ) {
          lastRiseHeight = 0;
          document.dispatchEvent(KeyboardDownEvent);
        }
      };
    } else if (isiOS) {
      document.body.addEventListener('focusin', () => {
        document.dispatchEvent(KeyboardUpEvent);
      });
      document.body.addEventListener('focusout', () => {
        document.dispatchEvent(KeyboardDownEvent);
      });
    }
  })(window);
};

cleanPrePageLogInfo();
