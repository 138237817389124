import React, { useState } from 'react';
import { CloseBgIcon, PlayIcon } from '@/components/common/icon';
import Modal from '@material-ui/core/Modal';
import { isMobile } from '@/biz/tool';

import './videoPreview.less';

interface Props {
  isImage?: boolean;
  src: string;
  handleDeleteVideo?: Function;
  index?: number;
  videoIndex?: number;
  isShowDelete?: boolean;
}

const VideoPreview: React.FC<Props> = ({
  isImage = false,
  src,
  handleDeleteVideo,
  index,
  videoIndex,
  isShowDelete = true
}) => {
  const [previewVideo, setPreviewVideo] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handlePreviewVideo = (videoUrl: string) => {
    setPreviewVideo(videoUrl);
    setIsModalOpen(true);
  };

  return (
    <div className="video-preview">
      {isImage ? <img src={src} /> : <video src={src}></video>}
      <span className="preview-icon" onClick={() => handlePreviewVideo(src)}>
        {isImage ? (
          <div className="preview-icon w-[52px] h-[52px]"></div>
        ) : (
          <PlayIcon className="preview-icon" />
        )}
      </span>
      {isShowDelete && (
        <span
          className="close"
          onClick={() =>
            handleDeleteVideo ? handleDeleteVideo(index, videoIndex) : () => {}
          }
        >
          <CloseBgIcon className="close-icon" />
        </span>
      )}
      <Modal
        open={isModalOpen}
        // footer={null}
        onClose={() => setIsModalOpen(false)}
        // width={800}
        // centered
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <div
          className={`relative left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] max-h-[80vh]
        ${isMobile() ? 'w-fit p-0 rounded-2xl' : 'w-fit p-0 rounded-lg'}`}
        >
          {isImage ? (
            <img
              src={previewVideo}
              style={{
                width: 'auto',
                height: 'auto',
                maxWidth: isMobile() ? '90vw' : '80vh',
                maxHeight: isMobile() ? '70vh' : '80vh',
                background: 'white',
                borderRadius: '10px',
                objectFit: 'contain'
              }}
            />
          ) : (
            <video
              src={previewVideo}
              controls
              style={{
                width: 'auto',
                height: 'auto',
                maxWidth: isMobile() ? '90vw' : '80vh',
                maxHeight: isMobile() ? '70vh' : '80vh',
                background: 'white',
                borderRadius: '10px',
                objectFit: 'contain'
              }}
            />
          )}
          <div
            className={`absolute right-0 cursor-pointer
            ${isMobile() ? 'top-[-30px]' : 'top-[-50px]'}`}
            onClick={() => setIsModalOpen(false)}
          >
            <CloseBgIcon
              className={`close-icon 
               ${isMobile() ? 'w-6 h-6' : 'w-10 h-10'}`}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VideoPreview;
