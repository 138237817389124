import React, { useState, useEffect } from 'react';
import { OrderStatus } from '@/interface/order';
import { isMobile } from '@/biz/tool';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import Radio from '@/components/Radio/index';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { NumericFormat } from 'react-number-format';
import { WarningIcon } from '@/components/common/icon';
import toast from 'react-hot-toast';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import { OrderContentType } from '@/interface/order';
import { getPlatformVideoContentType } from '@/biz/platform';
import { ProductType, ProductPhysicalSelectEnum } from '../config';

export const BlackTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#1c2024',
    color: '#ffffff',
    fontSize: '12px',
    padding: '8px 12px',
    borderRadius: '6px'
  },
  arrow: {
    color: '#1c2024'
  }
}))(Tooltip);

const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      '&:before': {
        borderBottom: '1px solid #E8E8EC' // 默认状态的底线颜色
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid #5B3DE9' // hover 状态的底线颜色
      },
      '&.Mui-focused:after': {
        borderBottom: '2px solid #5B3DE9' // 输入态（focused）的底线颜色
      },
      '&.Mui-disabled': {
        '&:before': {
          borderBottomStyle: 'solid'
        }
      },
      '& input': {
        caretColor: '#295eff', // 设置输入光标为紫色'
        '&::placeholder': {
          color: '#8B8D98',
          opacity: 1,
          fontSize: '16px',
          fontWeight: 300,
          fontFamily: 'Inter'
        }
      }
    }
  }
})(TextField);

interface NumberFormatUSDProps {
  inputRef: (instance: any) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatUSD(props: NumberFormatUSDProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      // suffix=" USD"
    />
  );
}

const ModalSelectContentPrice: React.FC<{
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  setShowAnInterestOpen: (v: boolean) => void;
  setShowAddressModal: (v: boolean) => void;
  product: any;
}> = ({
  isOpen,
  setIsOpen,
  setShowAnInterestOpen,
  setShowAddressModal,
  product
}) => {
  const { detailData } = useDetailDataStore();
  const { order } = detailData;
  const [method, setMethod] = useState('');
  const { priceFields, setSelectedPriceFields } = useMultiplePricesStore();

  if (![OrderStatus.Invited].includes(order?.status)) {
    return null;
  }

  const handleSubmit = () => {
    // 校验勾选和金额

    // 示例订单
    // display_price_cannot_change http://localhost:5001/campaign/detail/674591048d24ceb96d075107
    // display_price_can_change http://localhost:5001/campaign/invites/detail/674591048d24ceb96d07510e
    // not_display_price_can_change http://localhost:5001/campaign/invites/detail/67443d3afe6d43fca984f3a1

    if (!method || method === 'none') {
      toast.error(
        "Please select the video type that's best fit for your channel"
      );
      return;
    }
    //
    const price = priceFields.find(
      (el: any) => el.contentType === method
    )?.price;
    if (price === 0 || !price) {
      toast.error('Please enter the price.');
      return;
    }

    //
    setIsOpen(false);
    const selected = priceFields.filter((el: any) => el.contentType === method);
    setSelectedPriceFields(selected);

    if (
      product?.productType === ProductType.PHYSICAL_PRODUCT &&
      product?.physicalProductShipType === ProductPhysicalSelectEnum.ship
    ) {
      setShowAddressModal(true);
      return;
    }
    setShowAnInterestOpen(true);
  };

  return (
    <>
      <>
        {isMobile() ? (
          <BottomDrawer
            title="Select content type "
            visible={isOpen}
            handleClose={() => setIsOpen(false)}
            handleSubmit={handleSubmit}
            submitDisabled={!method || method === 'none'}
            okText={'Continue'}
          >
            <ModalContent method={method} setMethod={setMethod} />
          </BottomDrawer>
        ) : (
          <WebModal
            title="Select content type"
            visible={isOpen}
            handleClose={() => setIsOpen(false)}
            handleSubmit={handleSubmit}
            submitDisabled={!method || method === 'none'}
            okText={'Continue'}
          >
            <ModalContent method={method} setMethod={setMethod} />
          </WebModal>
        )}
      </>
    </>
  );
};

export default ModalSelectContentPrice;

const ModalContent: React.FC<{
  method: string;
  setMethod: (v: string) => void;
}> = ({ method, setMethod }) => {
  const { detailData } = useDetailDataStore();
  const {
    priceFields,
    setPriceFields,
    setSelectedPriceFields,
    gbPricingSchemaFlag
  } = useMultiplePricesStore();

  useEffect(() => {
    if (detailData?.order?.priceFields) {
      // 更新 且 清空选择 这个时机是ok的
      if (gbPricingSchemaFlag === 'not_display_price_can_change') {
        const newPriceFields = detailData?.order?.priceFields.map(
          (el: any) => ({
            contentType: el.contentType,
            price: 0,
            contentGenre: el.contentGenre
          })
        );
        setPriceFields(newPriceFields);
      } else {
        setPriceFields(detailData.order.priceFields);
      }
      setSelectedPriceFields([]);
    }
  }, [detailData]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    contentType: OrderContentType
  ) => {
    // 更新对应的一项
    setPriceFields(
      priceFields.map((item: any) => {
        if (item.contentType === contentType) {
          return {
            ...item,
            price: event.target.value
          };
        }
        return item;
      })
    );
  };

  return (
    <div className="w-full">
      <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
        {priceFields.length > 0
          ? 'Select the video type that best fits your channel.'
          : ''}
      </div>
      <div
        className={`flex  mt-6
          ${isMobile() ? 'flex-col gap-3' : ''}
          ${gbPricingSchemaFlag === 'not_display_price_can_change' ? 'flex-col gap-0' : 'gap-8'}
      `}
      >
        {priceFields.map((el: any) => {
          return (
            <PriceFieldSelectItemComponent
              priceFieldsOne={el}
              method={method}
              setMethod={setMethod}
              handleChange={handleChange}
            />
          );
        })}
      </div>
    </div>
  );
};

const PriceFieldSelectItemComponent: React.FC<{
  priceFieldsOne: any;
  method: string;
  setMethod: (v: string) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: OrderContentType
  ) => void;
}> = ({ priceFieldsOne, method, setMethod, handleChange }) => {
  const { detailData } = useDetailDataStore();
  const { order } = detailData;
  const { gbPricingSchemaFlag } = useMultiplePricesStore();
  const thisRadioId = priceFieldsOne?.contentType;
  const handleRadioClick = () => {
    setMethod(method === thisRadioId ? 'none' : thisRadioId);
  };
  const canNotChange =
    gbPricingSchemaFlag === 'display_price_cannot_change' && false;
  const isNotDisplayPriceCanChange =
    gbPricingSchemaFlag === 'not_display_price_can_change';
  //
  // console.log('priceFieldsOne', priceFieldsOne);
  if (!priceFieldsOne?.contentType) {
    return null;
  }

  return (
    <div className="flex-1">
      <div
        id={thisRadioId}
        className={`flex items-start p-4 border rounded-lg  gap-2
        ${method === thisRadioId ? 'border-[#5B3DE9]' : 'border-[#E8E8EC]'}
        ${canNotChange ? 'cursor-pointer' : ''}
        // 
        ${isMobile() ? '' : 'max-w-[330px]'}
        ${!isNotDisplayPriceCanChange && isMobile() ? 'flex-row-reverse items-center gap-4' : 'gap-2'}
        ${isNotDisplayPriceCanChange ? 'border-none' : ''}
        ${isNotDisplayPriceCanChange && isMobile() ? 'flex-row items-start !gap-0 ml-[-18px] py-0' : 'gap-2 py-1'}
    `}
        onClick={() => {
          if (canNotChange) {
            // 没有修改按钮 全域可点击
            handleRadioClick();
          }
        }}
      >
        <Radio checked={method === thisRadioId} onClick={handleRadioClick} />
        <div className="flex flex-col ml-2 w-full">
          <div
            className="mr-3 cursor-pointer flex gap-2"
            onClick={handleRadioClick}
          >
            <div className="flex items-center mb-2">
              <div className="text-[16px] font-medium text-[#60646c]">
                {getPlatformVideoContentType({
                  platform: order?.platform,
                  contentType: thisRadioId
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              {canNotChange ? (
                <div className="text-[#1c2024] text-2xl font-semibold font-['Geist'] leading-7">
                  {Number(priceFieldsOne.price || 0).toLocaleString()} USD
                </div>
              ) : (
                <StyledTextField
                  style={{
                    display:
                      isNotDisplayPriceCanChange && method !== thisRadioId
                        ? 'none'
                        : 'block'
                  }}
                  disabled={method !== thisRadioId}
                  value={priceFieldsOne.price || ''}
                  onChange={(event) =>
                    handleChange(
                      event as React.ChangeEvent<HTMLInputElement>,
                      thisRadioId as OrderContentType
                    )
                  }
                  placeholder="Enter your expected price."
                  name="price"
                  InputProps={{
                    endAdornment: <div>USD</div>,
                    inputComponent: NumberFormatUSD as any,
                    inputProps: {
                      decimalScale: 0,
                      allowNegative: false,
                      isAllowed: (values: any) => {
                        const { value } = values;
                        return (
                          (value === '' || Number(value) > 0) &&
                          value.length <= 8
                        );
                      }
                    },
                    style: {
                      width: '100%',
                      fontSize: '1.5rem',
                      fontWeight: 600,
                      fontFamily: 'Geist'
                    }
                  }}
                />
              )}
              {gbPricingSchemaFlag === 'display_price_can_change' ? (
                <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight flex items-center gap-1">
                  <span className="cursor-pointer" onClick={handleRadioClick}>
                    Suggested price:{' '}
                    {Number(priceFieldsOne.originPrice || 0).toLocaleString()}{' '}
                    USD.
                  </span>
                  {/*  */}
                  <PriceTooltip />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PriceTooltip: React.FC<{}> = ({}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      {isMobile() ? (
        <BlackTooltip
          title="This price is optimized based on your average views, follower count, and overall fit with the brand."
          placement={'bottom-end'}
          PopperProps={{
            disablePortal: true
          }}
          onClose={isMobile() ? () => setTooltipOpen(false) : undefined}
          open={tooltipOpen}
          arrow
        >
          <div className="cursor-pointer" onClick={() => setTooltipOpen(true)}>
            <WarningIcon className="text-[#8B8D98] w-4 h-4" />
          </div>
        </BlackTooltip>
      ) : (
        <BlackTooltip
          title="This price is optimized based on your average views, follower count, and overall fit with the brand."
          placement={'top-end'}
          arrow
        >
          <div className="cursor-pointer" onClick={() => setTooltipOpen(true)}>
            <WarningIcon className="text-[#8B8D98] w-4 h-4" />
          </div>
        </BlackTooltip>
      )}
    </>
  );
};
