/**
 * 首字母转为大写
 * @param str 字符串
 * @param isLower 是否小写
 * @returns 首字母大写的字符串
 */
export default function capitalizeFirstLetter(str: string, isLower?: boolean) {
  if (!str) return str;
  if (isLower) {
    return str.replace(/^(.)/, (match) => match.toLowerCase());
  }
  return str.replace(/^(.)/, (match) => match.toUpperCase());
}
