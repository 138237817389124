import React, { useState } from 'react';
import Notices from '@/components/common/Notices';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { NavLink } from 'react-router-dom';
import {
  FrontPaymentIcon,
  FrontPaymentUpdateIcon,
  FrontPaymentSetupIcon
} from '@/components/common/icon';
import { OrderStatus } from '@/interface/order';
import {
  AHA_DOCS_LINK,
  frontPaymentAccountStatus,
  getFrontPaymentRate
} from '@/pages/campaign/config';
import {
  NoticeRocketIcon,
  NoticeCoffeeIcon,
  NoticePaddingIcon,
  NoticeInfoIcon,
  NoticeMonyIcon
} from '@/components/common/icon';
import { getDuration, getDurationFormatBusinessDays } from '@/biz/time';
import { getOrderId } from '@/biz/tool';
import { useOrderIdStore } from '@/hooks/orderIdStore';
import ModalConfirmPaymentMethod from './components/ModalConfirmPaymentMethod';
import { AdsPlatform } from '@/interface/order';

const draftDeadline = (
  hours: number | null,
  createdAt?: string | Date | null
) => {
  if (createdAt) {
    const timeString = new Date(createdAt).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    const dateString = new Date(createdAt).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    return `${timeString} on ${dateString}`;
  }

  if (hours) return `${getDuration(hours)} after application approval`;
  return '-';
};

const review = (hours: number | null) => {
  if (hours) return getDurationFormatBusinessDays(hours);
  return '-';
};

function creatorRejectedAt(createdAt: string | Date | null) {
  if (!createdAt) return '';
  return ` on ${new Date(createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })}`;
}

// 通知汇总
// Deal cancelled 主动拒绝 You declined this invite on June 16, 2024
//
// Application under review 申请审核中 We'll email you the results within 1 business day. Stay tuned!
// Application approved *可关闭* 申请通过 Submit your script before {script_ddl}. Your script will be reviewed once submitted.
// Application not approved 申请未通过 Due to high competition, it wasn't a match this time, but stay tuned for more opportunities coming soon!
//
// Script draft under review 脚本审核中 Your script's under review. We'll email you results within {time} hours.
// Feedback provided *可关闭* 脚本有反馈 Submit your video draft before {video_ddl}, your draft will be reviewed once submitted.
// @ Script approved 脚本通过 Your script has been approved. You can now submit your video.
//
// Video draft under review 草稿审核中 Your draft's under review. We'll email you results within {time} hours.
// Video approved 草稿审核通过 *可关闭* Post your approved content at a peak time and submit the final link before {final_link_ddl}.
// Feedback provided 草稿有反馈 *可关闭* Please check your video feedback and submit a new draft.
// Deal cancelled 草稿第三次反馈 We regret to inform you we have decided not to proceed with your final draft for this deal. Stay connected with us for future opportunities!
//
// Payment delivered 终稿（final link)提交后 We have delivered the payment to you. Please note that payment might take up to 7 days to process.
//
// Payment success 付款发起后 Your payment have been successfully paid to your payment method!

// 颜色区分
// success #D9F1D9
// padding #E4E7FF
// info #DBEBFF
// warning #FFEDDC

const NoticesBar: React.FC = () => {
  const { detailData } = useDetailDataStore();
  return <TopNoticesBar data={detailData} />;
};

export default NoticesBar;

const TopNoticesBar = ({ data }: any) => {
  const { order, timeline, payment, user } = data;
  const hasPaymentMethod = Boolean(payment?.accountType);
  const orderStatus = order?.status;
  const orderCancelType = order?.cancelType;
  const rate = getFrontPaymentRate(order?.prepaymentRate);
  const orderId = getOrderId();

  let AhaNotice = null;
  let PreFrontNotice = null;
  // 控制可关闭notices展示的变量，默认true
  const { orderIds, setOrderIds } = useOrderIdStore();
  const [visible, setVisible] = useState(true);
  const handleClose = (title: string) => {
    setVisible(false);
    // 可关闭的notice只展示一次，添加缓存
    setOrderIds({
      ...orderIds,
      [orderId]: {
        ...orderIds?.[orderId],
        [title]: true
      }
    });
  };
  /**
   * 当前环境中优先级最高的全局提醒
   * 预付款当达人接单后直到订单取消的中间所有状态下，达人为设置收款账号或账号有误时展示
   * isValid 字段为后端判断当前收款账号是否可用的字段，分为两种情况 1.未创建 2.账号有误
   * airwallexId 为后端储存收款信息的id。如果没有id说明账号未创建
   */
  let shouldUpdatePayment = !payment?.isValid && hasPaymentMethod && user?.id; // 付款不可用 且 付款方式存在 且 已登录
  if (
    !payment?.isValid &&
    frontPaymentAccountStatus.includes(orderStatus) &&
    user?.id
  ) {
    PreFrontNotice = (
      <>
        <Notices
          backgroundColor={shouldUpdatePayment ? '#ffeddc' : '#e2f5ff'}
          title={
            shouldUpdatePayment
              ? 'Update Your Payment Details'
              : 'Set up your payment'
          }
          desc={
            <div>
              {shouldUpdatePayment ? (
                <span>
                  We're unable to transfer payment to your current payment
                  method. Please update your details.
                </span>
              ) : (
                <span>
                  Provide your payment method to receive your {rate} upfront
                  payment!
                </span>
              )}
              {
                <NavLink to={`/campaign/wallet/${orderId}`}>
                  <span
                    className="text-[#0c67ef]"
                    style={{ marginLeft: '8px' }}
                  >
                    {shouldUpdatePayment ? 'Update now' : 'Set up'}
                  </span>
                </NavLink>
              }
            </div>
          }
          Icon={
            shouldUpdatePayment ? FrontPaymentUpdateIcon : FrontPaymentSetupIcon
          }
        />
      </>
    );
  } else {
    // 需要确认付款方式
    // 反选之前 和 取消后 不展示
    if (
      user?.id &&
      !order?.paymentAccountConfirmed &&
      !(
        [OrderStatus.Invited].includes(orderStatus) ||
        [OrderStatus.CreatorApplied, OrderStatus.Pending].includes(
          orderStatus
        ) ||
        [OrderStatus.Cancelled].includes(orderStatus) ||
        [OrderStatus.ReviewRejected].includes(orderStatus)
      )
    ) {
      if (hasPaymentMethod) {
        // 有付款信息
        PreFrontNotice = (
          <>
            <Notices
              backgroundColor={'#e4e7ff'}
              title={'Collaboration confirmed'}
              desc={
                <div>
                  <span>
                    Congratulations on establishing your deal collaboration!
                    Click "Confirm" to receive your upfront payment and
                    kickstart your creative work!
                  </span>
                  {<ModalConfirmPaymentMethod />}
                </div>
              }
              Icon={NoticeRocketIcon}
            />
          </>
        );
      } else {
        // 无付款信息
        PreFrontNotice = (
          <>
            <Notices
              backgroundColor="#e4e7ff"
              title="Collaboration confirmed"
              desc={
                <div>
                  <span>
                    setup payment method to receive the front payment.
                  </span>
                  <NavLink to={`/campaign/wallet/${orderId}`}>
                    <span
                      className="text-[#0c67ef]"
                      style={{ marginLeft: '8px' }}
                    >
                      {shouldUpdatePayment ? 'Update now' : 'Set up'}
                    </span>
                  </NavLink>
                </div>
              }
              Icon={NoticeRocketIcon}
            />
          </>
        );
      }
    }
  }

  const uploadedRecords = data?.order?.contents || [];

  if (
    [OrderStatus.Cancelled].includes(orderStatus) ||
    [OrderStatus.ReviewRejected].includes(orderStatus)
  ) {
    // 四种 Cancel 类型
    // 1不感兴趣 2广告主拒绝 3提交超次数拒绝 4提交超时
    if (orderCancelType === 'Creator_rejected') {
      // 达人不感兴趣
      AhaNotice = (
        <>
          {/* 不感兴趣  */}
          {/* https://ops.test.ahalab.io/campaigns/609/orders/67330d5bb64574c5391064ae */}
          {/* Deal cancelled - warning使用咖啡图标 */}
          <Notices
            backgroundColor="#FFEDDC"
            title="Deal cancelled"
            desc={<div>You declined this invite.</div>}
            Icon={NoticeCoffeeIcon}
          />
        </>
      );
    } else if (orderCancelType === 'Apply_rejected') {
      // 申请后 广告主拒绝
      AhaNotice = (
        <>
          {/* 申请后 拒绝 */}
          {/* https://ops.test.ahalab.io/campaigns/609/orders/67330d5cb64574c5391064c2 */}
          {/* Application not approved - warning使用咖啡图标 */}
          <Notices
            backgroundColor="#FFEDDC"
            title="Application not approved"
            desc="Due to high competition, it wasn't a match this time, but stay tuned for more opportunities coming soon!"
            Icon={NoticeCoffeeIcon}
          />
        </>
      );
    } else if (orderCancelType === 'Draft_rejected') {
      // 提交超次数拒绝
      AhaNotice = (
        <>
          {/* http://localhost:5001/campaign/detail/67356d06f9a54bc116ed26df */}
          {/* Deal cancelled 草稿第三次反馈  */}
          <Notices
            backgroundColor="#FFEDDC"
            title="Deal cancelled"
            desc="We regret to inform you we have decided not to proceed with your final draft for this deal. Stay connected with us for future opportunities!"
            Icon={NoticeCoffeeIcon}
          />
        </>
      );
    } else if (orderCancelType === 'Overdue') {
      // 提交超时
      AhaNotice = (
        <>
          {/* 超时取消 */}
          {/* https://ops.test.ahalab.io/campaigns/609/orders/67330d5cb64574c5391064c2 */}
          {/* Deal canceled- warning使用咖啡图标 */}
          <Notices
            backgroundColor="#FFEDDC"
            title="Deal canceled"
            desc="This collaboration has been canceled since you have not submitted your content based on the timeline. Please noted failure to comply with the timeline will affect your credibility on the platform and might affect your future brand opportunities."
            Icon={NoticeCoffeeIcon}
          />
        </>
      );
    } else {
      // 其他情况
      AhaNotice = (
        <>
          {/* 超时取消 */}
          {/* https://ops.test.ahalab.io/campaigns/609/orders/67330d5cb64574c5391064c2 */}
          {/* Deal canceled- warning使用咖啡图标 */}
          <Notices
            backgroundColor="#FFEDDC"
            title="Deal canceled"
            desc="This collaboration has been canceled, but stay tuned for more opportunities coming soon!"
            Icon={NoticeCoffeeIcon}
          />
        </>
      );
    }

    //
  } else if (
    [OrderStatus.CreatorApplied, OrderStatus.Pending].includes(orderStatus)
  ) {
    // 2025-01-17 11:04:09 添加 资源池 判断
    // Pending = 'Pending', // 万用状态
    let reviewTime = '1-2';
    if ([OrderStatus.Pending].includes(orderStatus)) {
      reviewTime = '3-4';
    }
    AhaNotice = (
      <>
        {/* Application under review - padding使用NoticePaddingIcon */}
        <Notices
          backgroundColor="#E4E7FF"
          title="Application under review"
          desc={`We'll email you the outcome within ${reviewTime} business days. Stay tuned!`}
          Icon={NoticePaddingIcon}
        />
      </>
    );
  } else if ([OrderStatus.UnderProduction].includes(orderStatus)) {
    if (order?.contentStage === 'Script') {
      const isCache = orderIds?.[orderId]?.applicationApproved;
      if (visible && !isCache) {
        AhaNotice = (
          <>
            {/* Application approved */}
            <Notices
              backgroundColor="#D9F1D9"
              isShowClose={true}
              title="Application approved"
              handleClose={() => handleClose('applicationApproved')}
              // desc="Submit your script before {script_ddl}. Your script will be reviewed once submitted."
              desc={
                <div>
                  Submit your script before{' '}
                  {draftDeadline(
                    timeline?.initialScriptDraft?.maxWaitInHours,
                    timeline?.initialScriptDraft?.deadlineTs
                  )}
                  . Your script will be reviewed once submitted.
                </div>
              }
              Icon={NoticeRocketIcon}
            />
            {/* -------- 之前没有 */}
          </>
        );
      }
    } else {
      // 这里是 草稿 阶段
      // 还需要区分 审核反馈的
      const script = uploadedRecords.find((item: any) =>
        item?.stage?.includes('Script')
      );
      const draft = uploadedRecords.filter((item: any) =>
        item?.stage?.includes('Draft')
      );

      if (draft.length === 0 && script?.feedback) {
        const isCache = orderIds?.[orderId]?.scriptFeedbackProvided;
        if (visible && !isCache) {
          AhaNotice = (
            <>
              {/* Feedback provided *可关闭* 脚本有反馈 */}
              <Notices
                backgroundColor="#DBEBFF"
                isShowClose={true}
                title="Feedback provided"
                handleClose={() => handleClose('scriptFeedbackProvided')}
                // desc="Submit your video draft before {video_ddl}, your draft will be reviewed once submitted."
                desc={
                  <div>
                    Submit your video draft before{' '}
                    {draftDeadline(
                      timeline?.draft?.maxWaitInHours,
                      timeline?.draft?.deadlineTs
                    )}
                    , your draft will be reviewed once submitted.
                  </div>
                }
                Icon={NoticeInfoIcon}
              />
              {/* -------- 之前没有 */}
            </>
          );
        }
      } else if (draft.length > 0) {
        const isCache = orderIds?.[orderId]?.videoFeedbackProvided;
        if (visible && !isCache) {
          AhaNotice = (
            <>
              {/* Feedback provided 草稿有反馈 *可关闭*  */}
              <Notices
                backgroundColor="#DBEBFF"
                isShowClose={true}
                title="Feedback provided"
                desc="Please check your video feedback and submit a new draft."
                Icon={NoticeRocketIcon}
                handleClose={() => handleClose('videoFeedbackProvided')}
              />
              {/* -------- 之前没有 */}
            </>
          );
        }
      } else {
        const isCache = orderIds?.[orderId]?.scriptApproved;
        if (visible && !isCache) {
          AhaNotice = (
            <>
              {/* Script approved */}
              <Notices
                backgroundColor="#D9F1D9"
                isShowClose={true}
                title={
                  order?.platform === AdsPlatform.Youtube
                    ? 'Script approved'
                    : 'Application approved'
                }
                desc={
                  order?.platform === AdsPlatform.Youtube ? (
                    'Your script has been approved. You can now submit your video.'
                  ) : (
                    <div>
                      Submit your video draft before{' '}
                      {draftDeadline(
                        timeline?.draft?.maxWaitInHours,
                        timeline?.draft?.deadlineTs
                      )}
                      , your draft will be reviewed once submitted.
                    </div>
                  )
                }
                Icon={NoticeRocketIcon}
                handleClose={() => handleClose('scriptApproved')}
              />
              {/* -------- 之前没有 */}
            </>
          );
        }
      }
    }
  } else if ([OrderStatus.UnderReview].includes(orderStatus)) {
    if (order?.contentStage === 'Script') {
      AhaNotice = (
        <>
          {/* Script draft under review - padding使用NoticePaddingIcon */}
          <Notices
            backgroundColor="#E4E7FF"
            title="Script draft under review"
            desc={
              <div>
                Your script's under review. We'll email you results within 2
                business days.
              </div>
              // 后端计算和前端显示有歧义。先用固定文案 2 business days.
              // <div>
              //   Your script's under review. We'll email you results within{' '}
              //   {review(timeline?.review?.maxWaitInHours)}.
              // </div>
            }
            Icon={NoticePaddingIcon}
          />
        </>
      );
    }

    if (order?.contentStage !== 'Script') {
      AhaNotice = (
        <>
          {/* Video draft under review - padding使用NoticePaddingIcon */}
          <Notices
            backgroundColor="#E4E7FF"
            title="Video draft under review"
            desc={
              <div>
                Your draft's under review. We'll email your results within 2
                business days.
              </div>
              // 后端计算和前端显示有歧义。先用固定文案 2 business days.。
              // <div>
              //   Your draft's under review. We'll email your results within{' '}
              //   {review(timeline?.review?.maxWaitInHours)}.
              // </div>
            }
            Icon={NoticePaddingIcon}
          />
        </>
      );
    }
  } else if (
    [OrderStatus.PendingPublish].includes(orderStatus) ||
    [OrderStatus.PublishRejected].includes(orderStatus)
  ) {
    const isCache = orderIds?.[orderId]?.videoApproved;
    if (visible && !isCache) {
      AhaNotice = (
        <>
          {/* Video approved */}
          <Notices
            backgroundColor="#D9F1D9"
            isShowClose={true}
            title="Video approved"
            // desc="Post your approved content at a peak time and submit the final link before {final_link_ddl}."
            desc={
              <div>
                Post your approved content at a peak time and submit the final
                link before{' '}
                {draftDeadline(
                  timeline?.finalPostDeadline?.maxWaitInHours,
                  timeline?.finalPostDeadline?.deadlineTs
                )}
                .
              </div>
            }
            Icon={NoticeRocketIcon}
            handleClose={() => handleClose('videoApproved')}
          />
          {/* -------- 之前没有 */}
        </>
      );
    }
  } else if ([OrderStatus.PendingPayment].includes(orderStatus)) {
    AhaNotice = (
      <>
        {/* Payment delivered */}
        <Notices
          backgroundColor="#D9F1D9"
          title="Payment delivered"
          desc="We have delivered the payment to you. Please note that payment might take up to 7 days to process."
          Icon={NoticeMonyIcon}
        />
        {/* -------- 之前没有 */}
      </>
    );
  } else if (
    [OrderStatus.PaymentSubmitted].includes(orderStatus) ||
    [OrderStatus.Paid].includes(orderStatus)
  ) {
    // TODO: 需要区分 付款成功 和 付款失败
    AhaNotice = (
      <>
        {/* Payment success */}
        <Notices
          backgroundColor="#D9F1D9"
          title="Payment success"
          desc="Your payment have been successfully paid to your payment method!"
          Icon={NoticeMonyIcon}
        />
        {/* -------- 之前没有 */}
      </>
    );
  } else if ([OrderStatus.Invited].includes(orderStatus)) {
    // 在Invited状态下出预付款说明全局提示栏
    AhaNotice = (
      <>
        <Notices
          backgroundColor="#E4E7FF"
          title={`${rate} front payment available!`}
          desc={
            <div>
              {
                <span>
                  Get a {rate} upfront payment as soon as your collaboration has
                  been confirmed. Hit “I’m interested” and start earning!
                </span>
              }
              {
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={AHA_DOCS_LINK}
                  className="text-[#0c67ef]"
                  style={{ marginLeft: '8px' }}
                >
                  Learn more
                </a>
              }
            </div>
          }
          Icon={FrontPaymentIcon}
        />
      </>
    );
  }

  return (
    <div className="flex w-full flex-col gap-4">
      {PreFrontNotice || AhaNotice}
    </div>
  );
};
