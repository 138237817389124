import React, { useState, useEffect } from 'react';
import {
  ClockBgIcon,
  CarrierIngIcon,
  CarrierDoneIcon
} from '@/components/common/icon';
import { CopyToClipboard } from '@/components/common/copyToClipboard';
import { copyToClipboard } from '@/utils';
import { NationsLab } from '@/utils/locations';
import toast from 'react-hot-toast';
import { OrderStatus } from '@/interface/order';
import { formatDateWithSuffix } from '@/biz/time';
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';

import styles from './index.module.less';
interface Props {
  data: any;
}

const ShippingDetail: React.FC<Props> = ({ data }) => {
  const { order } = data || {};

  const { status } = order || {};

  const { productShipment } = order || {};
  const { trackingNumber, courierName, trackingInfo } = productShipment || {};

  const getLabel = (key: string) => {
    if (key?.includes('line')) {
      return `Address ${key}`;
    }
    if (key === 'postal_code') {
      return 'Zip code';
    }
    return capitalizeFirstLetter(key);
  };

  const addressMap = Object.keys(order?.addressInfo || []).map(
    (key: string) => ({
      label: getLabel(key),
      value: order?.addressInfo?.[key]
    })
  );

  if (!addressMap?.length) return null;

  const getStatusMap = () => {
    if (status === OrderStatus.PendingProductShipment) {
      return {
        text: 'Pending',
        Icon: ClockBgIcon,
        color: '#B06700',
        bgColor: '#FFEDDC'
      };
    }
    if (status === OrderStatus.PendingProductArrival) {
      return {
        text: 'Transit',
        Icon: CarrierIngIcon,
        color: '#0C67E6',
        bgColor: '#EAF3FF'
      };
    }
    if (
      [
        OrderStatus.UnderProduction,
        OrderStatus.UnderReview,
        OrderStatus.ReviewRejected,
        OrderStatus.PendingPublish,
        OrderStatus.PublishRejected,
        OrderStatus.PendingPayment,
        OrderStatus.PaymentSubmitted,
        OrderStatus.Paid,
        OrderStatus.PaymentFailed,
        OrderStatus.UnderRevision
      ].includes(status)
    ) {
      return {
        text: 'Delivered',
        Icon: CarrierDoneIcon,
        color: '#287F2E',
        bgColor: '#E8F7E8'
      };
    }
    return null;
  };

  const statusMap: any = getStatusMap();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Shipping details</div>
      <div className={`${styles.address} ${styles.line}`}>
        {addressMap?.map((item: { label: string; value: string }) => (
          <div className={styles.addressItem} key={item?.label}>
            <div className={styles.addressItemLeft}>{item?.label}</div>
            <div className={styles.addressItemRight}>
              {item?.label === 'Country'
                ? NationsLab?.[item?.value]
                : item?.value}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.address}>
        {statusMap && (
          <div className={styles.addressItem}>
            <div className={styles.addressItemLeft}>Status</div>
            <div
              className={styles.pending}
              style={{
                backgroundColor: statusMap?.bgColor,
                color: statusMap?.color
              }}
            >
              <statusMap.Icon />
              <span>{statusMap?.text}</span>
            </div>
          </div>
        )}
        <div className={styles.addressItem}>
          <div className={styles.addressItemLeft}>Carrier</div>
          <div className={styles.addressItemRight}>{courierName || '-'}</div>
        </div>
        <div className={styles.addressItem}>
          <div className={styles.addressItemLeft}>Tracking number</div>
          <div className={styles.addressItemRight}>
            {trackingNumber || '-'}
            {trackingNumber && (
              <CopyToClipboard
                onClick={() => {
                  copyToClipboard(trackingNumber);
                  toast.success('Tracking number copied.');
                }}
              />
            )}
          </div>
        </div>
      </div>
      {trackingInfo && (
        <div className={styles.trackingInfo}>
          <div className={styles.trackingInfoContent}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>{courierName || '-'}</div>
              <div className={styles.headerRight}>
                {trackingNumber || '-'}
                {trackingNumber && (
                  <CopyToClipboard
                    onClick={() => {
                      copyToClipboard(trackingNumber);
                      toast.success('Tracking number copied.');
                    }}
                  />
                )}
              </div>
            </div>
            <div className={styles.transit}>
              <div className={styles.time}>
                <div className={styles.dot}></div>
                <div className={styles.transitTitle}>
                  {trackingInfo?.subStatus}
                </div>
                <div className={styles.transitTime}>
                  {formatDateWithSuffix(trackingInfo?.updateAt)}
                </div>
              </div>
              <div className={styles.desc}>{trackingInfo?.latestEvent}</div>
            </div>
            <div className={styles.transit}>
              <div className={styles.time}>
                <div className={styles.dot}></div>
                <div className={styles.transitTitle}>Shipping date</div>
                <div className={styles.transitTime}>
                  {formatDateWithSuffix(trackingInfo?.shippingDate)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShippingDetail;
