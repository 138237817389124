import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import InvitesCard from '@/components/web/components/invites/InvitesCard';
import axiosInstance from '../../../apis/axiosInstance';

import styles from './invitesHome.module.less';
import { isMobile } from '@/biz/tool';

const InvitesHome: React.FC = () => {
  const [invitesList, setInvites] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // 获取所有的新邀请
  const getNewInvitesList = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get('/influencer/order');
      const { invites = [] } = data?.data || {};
      setInvites(invites);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setInvites([]);
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    getNewInvitesList();
  }, []);

  // 空状态
  if (!isLoading && !invitesList?.length) {
    return (
      <div className={styles.emptyContainer}>
        <div className={styles.img}></div>
        <div className={styles.emptyText}>
          Our algo is matching the best brand deals fit for you. Check your
          email and stay tuned for the new invites!
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${isMobile() ? styles.mobileContainer : styles.container}`}
    >
      {!!invitesList?.length && (
        <div className={styles.cardList}>
          {invitesList?.map((item: any, index) => (
            <InvitesCard
              key={item?._id}
              item={item}
              className={styles.cardItem}
              index={index}
            />
          ))}
        </div>
      )}
      {/* 骨架屏加载动画 */}
      {isLoading && !isMobile() ? (
        <div className="flex flex-col gap-2 w-full mb-10 text-xl text-[#1C2024] font-semibold font-['Geist'] leading-loose">
          <div className="flex gap-8 mb-[40px]">
            <div className="h-[262px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
          <div className="flex gap-8 mb-[40px]">
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
          <div className="flex gap-8 mb-[40px]">
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
        </div>
      ) : null}
      {isLoading && isMobile() ? (
        <div className="flex flex-col gap-2 w-full mb-10 text-xl text-[#1C2024] font-semibold font-['Geist'] leading-loose">
          <div className="flex flex-col gap-8 mb-[40px]">
            <div className="h-[262px] w-full bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-full bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-full bg-gray-200 rounded-2xl animate-pulse" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvitesHome;
