import { OrderStatus, AdsPlatform } from '../interface/order';
import { PlatformItems } from '../interface/common';

export const getOrderStep = (order: any) => {
  const status = order?.status;
  if (
    [
      OrderStatus.Created,
      OrderStatus.Inviting,
      OrderStatus.Invited,
      OrderStatus.CreatorApplied,
      OrderStatus.Cancelled
    ].includes(status)
  )
    return 0;
  if ([OrderStatus.UnderProduction].includes(status))
    return order.version >= 1 ? 2 : 0;
  if ([OrderStatus.UnderReview].includes(status)) return 1;
  if ([OrderStatus.PendingPublish].includes(status)) return 3;
  if ([OrderStatus.PendingPayment].includes(status)) return 4;
  if (
    [
      OrderStatus.PaymentSubmitted,
      OrderStatus.Paid,
      OrderStatus.PaymentFailed,
      OrderStatus.Completed
    ].includes(status)
  )
    return 5;
  return 0;
};

export const getOrderContentGenre = (order: any) => {
  const contentGenre = order?.contentGenre || order?.contentForm;
  // 处理大小写
  return typeof contentGenre !== 'string'
    ? '-'
    : contentGenre
        .split(/\s+/)
        .map((word) =>
          word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : ''
        )
        .join(' ');
};

export const getOrderPlatform = (order: any) => {
  let platform = order?.platform;

  if (typeof platform !== 'string') return '-';
  if (platform === '__platform__') return 'platform';
  platform = platform.toLowerCase();
  // 处理大小写
  if (PlatformItems.YOUTUBE.toLowerCase() === platform) return 'YouTube';
  if (PlatformItems.TIKTOK.toLowerCase() === platform) return 'TikTok';
  if (PlatformItems.INSTAGRAM.toLowerCase() === platform) return 'Instagram';
  if (PlatformItems.TWITTER.toLowerCase() === platform) return 'Twitter';
  if (PlatformItems.LINKEDIN.toLowerCase() === platform) return 'LinkedIn';
  return `${platform.charAt(0).toUpperCase()}${platform.slice(1)}`;
};

export const formatPrice = (price: number) => {
  if (!isNaN(parseFloat(String(price))) && isFinite(price)) {
    if (price < 1000) {
      return `$${Math.floor(price)}`;
    } else if (price >= 1000 && price < 10000) {
      return `$${Math.floor(price / 100) / 10}K`;
    } else if (price >= 10000 && price < 1000000) {
      return `$${Math.floor(price / 1000)}K`;
    } else if (price >= 1000000 && price < 10000000) {
      return `$${Math.floor(price / 100000) / 10}M`;
    } else if (price >= 10000000) {
      return `$${Math.floor(price / 1000000)}M`;
    }
  } else {
    return '';
  }
};

export const isOrderAvailable = (order: any) => {
  return ![
    OrderStatus.Created,
    OrderStatus.Inviting,
    OrderStatus.Invited,
    OrderStatus.CreatorApplied,
    OrderStatus.Cancelled
  ].includes(order?.status);
};

export const getShowScript = (order: any) => {
  // 不包含脚本 或者跳过脚本
  return order?.platform === AdsPlatform.Youtube && !order?.skipScript;
};
