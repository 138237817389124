import React from 'react';
import Button from '@material-ui/core/Button';
import styles from './creatorStepsCard.module.less';
import { isMobile } from '@/biz/tool';
import { CertificationIcon } from '@/components/common/icon';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { PlatformIconComponent } from '@/biz/platform';

interface Props {
  item: {
    Icon: any;
    title: string;
    desc: string;
  };
  influencers: {
    id: string;
    name: string;
    profilePictureLink: string;
    platform: string;
  }[];
  index: number;
}

const CreatorStepsCard: React.FC<Props> = ({ item, influencers, index }) => {
  const { setShowSelectPlatformModal } = useWebModalStore();
  if (isMobile()) {
    return (
      <div className={styles.mobileContainer}>
        <item.Icon className={styles.icon} />
        <div className={styles.content}>
          <div className={styles.title}>{item.title}</div>
          <div className={`${styles.desc} !text-[#60646c]`}>{item.desc}</div>
          {!!influencers?.length && index === 0 && (
            <div className={styles.influencersList}>
              {influencers.map((influencer) => (
                <div
                  className={styles.influencersListItem}
                  key={influencer?.name}
                >
                  <PlatformIconComponent
                    platform={influencer?.platform}
                    className="w-4"
                  />
                  <span className={'wrap break-words'}>
                    @{influencer?.name}
                  </span>
                  <CertificationIcon className={'flex flex-shrink-0'} />
                </div>
              ))}
            </div>
          )}
          {index === 0 && (
            <Button
              className="mui-btn-gray !mt-6"
              onClick={() => {
                setShowSelectPlatformModal(true);
              }}
            >
              {influencers?.length ? 'Connect more' : 'Connect'}
            </Button>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <item.Icon />
      <div className={styles.title}>{item.title}</div>
      <div className={`${styles.desc} !text-[#60646c]`}>{item.desc}</div>
      {!!influencers?.length && index === 0 && (
        <div className={styles.influencersList}>
          {influencers.map((influencer) => (
            <div className={styles.influencersListItem} key={influencer?.id}>
              <PlatformIconComponent
                platform={influencer?.platform}
                className="w-4 !rounded-none"
              />
              <span className={'wrap break-words'}>@{influencer?.name}</span>
              <CertificationIcon className={'flex flex-shrink-0'} />
            </div>
          ))}
        </div>
      )}
      {index === 0 && (
        <Button
          className="mui-btn-gray !mt-6"
          onClick={() => {
            setShowSelectPlatformModal(true);
          }}
        >
          {influencers?.length ? 'Connect more' : 'Connect'}
        </Button>
      )}
    </div>
  );
};

export default CreatorStepsCard;
