import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { PlusIcon } from '@/components/common/icon';
import {
  getPlatformIcon,
  getPlatformName,
  checkPhylloConfig
} from '@/biz/platform';
import PhylloSDK from '@/biz/phylloSDKInit';
import { usePlatformStore } from '@/hooks/usePlatformStore';
import { CertificationIcon } from '@/components/common/icon';
import { Tracker } from '@/utils';

const ModalSelectPlatformContent: React.FC<{}> = () => {
  const { platformList, phylloSDKConfig, loading, fetchPlatformData } =
    usePlatformStore();
  const { influencers } = usePlatformStore();

  useEffect(() => {
    fetchPlatformData();
  }, []);

  const handleConnect = async (platform: any) => {
    const platformId = platform.id;
    const getRedirectUrl = ({
      platformType,
      workPlatformId,
      onboarding
    }: {
      platformType: string;
      workPlatformId: string;
      onboarding: boolean;
    }) => {
      return `${window.location.origin}/auth/${platformType}?work_platform_id=${workPlatformId}&onboarding=${onboarding}`;
    };

    const config = {
      clientDisplayName: 'Aha Creator',
      environment:
        process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging',
      userId: phylloSDKConfig.user_id,
      token: phylloSDKConfig.sdk_token,
      redirect: true,
      redirectURL: getRedirectUrl({
        platformType: platform.platform,
        workPlatformId: platformId,
        onboarding: Boolean(!influencers?.length)
      }),
      workPlatformId: platformId,
      singleAccount: true
    };

    if (!checkPhylloConfig(config)) {
      return;
    }
    let phylloSDK = new PhylloSDK();
    await phylloSDK.openPhylloSDK(config);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {platformList?.length > 0 ? (
        platformList.map((platform: any) => {
          //
          const platformFound = influencers?.find((influencer: any) => {
            return influencer.platform === platform.platform;
          });
          //
          return (
            <div
              key={platform.name}
              className="h-[60px] w-full px-3 py-4 rounded-lg border border-[#d9d9e0] flex justify-between items-center"
            >
              <div className="flex items-center gap-3">
                <div className="w-6 h-6">
                  {getPlatformIcon(platform.platform)}
                </div>
                {platformFound ? (
                  <div className="flex items-center text-[#60646c] text-xs gap-1">
                    <img
                      className="w-4 h-4 rounded-full"
                      src={platformFound?.profilePictureLink}
                      alt="avatar"
                    />
                    @{platformFound?.name}
                  </div>
                ) : (
                  getPlatformName(platform.platform)
                )}
              </div>
              {/* <Button
                className="mui-btn-default !p-0 !w-6 !h-6 !min-w-6"
                onClick={() => {
                  handleConnect(platform);
                }}
              >
                <PlusIcon className="w-6 h-6 " />
              </Button> */}
              <div
                className="w-6 h-6 flex justify-center items-center"
                onClick={() => {
                  if (!platformFound) {
                    // google 上报
                    Tracker.click('connect platform');
                    Tracker.click(`connect platform ${platform.platform}`);
                    handleConnect(platform);
                  }
                }}
              >
                {platformFound ? (
                  <CertificationIcon className="w-6 h-6" />
                ) : (
                  <Button className="mui-btn-default !p-0 !w-6 !h-6 !min-w-6">
                    <PlusIcon className="w-6 h-6 " />
                  </Button>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="h-[60px] w-full px-3 py-4 rounded-lg border border-[#d9d9e0] flex justify-center items-center">
          No platforms found
        </div>
      )}
    </>
  );
};

export default ModalSelectPlatformContent;
