import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './banner.module.less';
import { isMobile } from '@/biz/tool';

interface Props {}

const Banner: React.FC<Props> = ({}) => {
  const navigate = useNavigate();

  const handleShare = () => {
    navigate(`/campaign/share`);
  };

  if (isMobile()) {
    return (
      <div
        className="h-11 px-6 w-full py-3 bg-gradient-to-r from-[#009dff] via-[#b079fb] to-[#daabf6] justify-between items-center inline-flex"
        onClick={handleShare}
      >
        <div className="h-5 px-2.5 bg-white rounded-full flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#5b3de9] text-xs font-semibold font-['Inter']">
              NEW
            </div>
          </div>
        </div>
        <div>
          <span className="text-white text-sm font-normal font-['Geist']">
            Refer creators and earn up to{' '}
          </span>
          <span className="text-white text-sm font-semibold font-['Geist']">
            10,000 USD
          </span>
          <span className="text-white text-sm font-normal font-['Geist']">
            {' '}
            !
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <img src="/home-banner.png" alt="banner" />
      <div className={styles.bannerText}>
        <div className={styles.bannerTitle}>Invite and Earn</div>
        <div className={styles.bannerTarget}>
          <span>Refer creators and earn up to </span>
          <span className={styles.color}>10,000 </span>
          <span>USD</span>
        </div>
        <div className={styles.button} onClick={handleShare}>
          Invite today
        </div>
        <div className={styles.link}>
          <span>For details, see the </span>
          <a
            href="https://chartreuse-wrinkle-697.notion.site/Aha-Docs-11104daded68803fb924f34bbac22a76#b426e324cc1c4ec6b3e58d9f1171a771"
            target="__blank"
          >
            Referral Terms
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
