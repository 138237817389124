import React, { SetStateAction, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import WebModal from '@/components/web/components/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import { getOrderId, isMobile } from '@/biz/tool';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import axiosInstance from '@/apis/axiosInstance';
import toast from 'react-hot-toast';
import { OrderStatus } from '@/interface/order';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import BottomDrawer from '@/components/web/components/BottomDrawer';

const ModalNotInterest: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [showPriceInput, setShowPriceInput] = useState(false);
  const [showTimelineInput, setShowTimelineInput] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const { detailData, duringFirstOrder, reQueryDetailData } =
    useDetailDataStore();
  const { setShowNotInterestSubmitModal, setShowModalJoinSuccess } =
    useWebModalStore();
  const { order } = detailData;

  const updateFeedback = (
    feedback: Array<any>,
    type: string,
    input: string,
    isSelect: boolean,
    index: number
  ) => {
    if (isSelect) {
      feedback[index] = { type, input };
    } else {
      feedback[index] = false;
    }

    setFeedback([...feedback] as SetStateAction<never[]>);
  };

  const submitFeedback = async () => {
    if (feedback.length === 0) {
      toast.error('Please select at least one reason.');
      return;
    }
    const orderId = getOrderId();
    setSubmitBtnLoading(true);

    try {
      await axiosInstance.post(
        `/order/${orderId}/creatorReject`,
        feedback.filter((item) => !!item)
      );
      toast.success('Submission success.');
      setShowModalJoinSuccess(true);
      //成功之后关闭弹窗，并且隐藏按钮
      setIsOpen(false);
      // 提交后刷新页面数据
      reQueryDetailData(orderId);
    } catch (err) {
      toast.error('Submission failed.');
      console.error(err);
    } finally {
      setSubmitBtnLoading(false);
      // 首单逻辑 01
      if (duringFirstOrder) {
        setShowNotInterestSubmitModal(true);
      }
    }
  };
  let WebModalAndMobileDraw = WebModal;
  if (isMobile()) {
    WebModalAndMobileDraw = BottomDrawer;
  }
  if (![OrderStatus.Invited].includes(order?.status)) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        disabled={submitBtnLoading}
        className={`mui-btn-default
          ${isMobile() ? '!h-10 !border-[#5a31f0] !border-solid !w-[128px] flex-shrink-0 !text-[#5b3de9]' : ''}`}
        onClick={() => {
          setIsOpen(true);
          setShowPriceInput(false);
          setShowTimelineInput(false);
          setShowOtherInput(false);
        }}
      >
        Not interested
      </Button>
      <WebModalAndMobileDraw
        title="Not interested"
        visible={isOpen}
        submitDisabled={false}
        isLoading={submitBtnLoading}
        handleClose={() => {
          setIsOpen(false);
        }}
        handleSubmit={() => {
          submitFeedback();
        }}
        width={isMobile() ? 342 : 624}
      >
        <div className="max-h-[70vh]  w-full overflow-y-auto overflow-x-visible">
          <div className=" text-[#60646c] text-xs text-left w-full mb-6 font-normal font-['Inter'] leading-tight">
            We’re sorry that you’re not interested. Help us improve this deal by
            telling us why:
          </div>
          <div
            className={`w-full
          ${isMobile() ? '' : 'ml-[0px]'}`}
          >
            <div className="flex items-center">
              <Checkbox
                color="primary"
                onChange={(_, isSelect) => {
                  updateFeedback(
                    feedback,
                    'Proposed price is not attractive',
                    '',
                    isSelect,
                    1
                  );
                  setShowPriceInput(!showPriceInput);
                }}
              />
              Proposed price is not attractive
            </div>
            {showPriceInput ? (
              <div className="flex flex-col items-start border border-zinc-200 rounded-lg p-1  gap-2 ml-10  mb-2">
                <TextareaAutosize
                  minRows={1}
                  className="w-full pl-2 py-2 rounded-md placeholder:text-[#71717a] placeholder:text-sm "
                  onChange={(event) => {
                    updateFeedback(
                      feedback,
                      'Proposed price is not attractive',
                      event.target.value,
                      true,
                      0
                    );
                  }}
                  placeholder="Enter your expected price"
                />
              </div>
            ) : null}
            <div className="flex  items-center">
              <Checkbox
                color="primary"
                onChange={(_, isSelect) => {
                  updateFeedback(
                    feedback,
                    'Timeline is too tight given the deliverables',
                    '',
                    isSelect,
                    1
                  );
                  setShowTimelineInput(!showTimelineInput);
                }}
              />
              Timeline is too tight given the deliverables
            </div>
            {showTimelineInput ? (
              <div className="flex flex-col items-start border border-zinc-200 rounded-lg p-1  gap-2 ml-10  mb-2">
                <TextareaAutosize
                  minRows={1}
                  className="w-full pl-2 py-2 rounded-md placeholder:text-[#71717a] placeholder:text-sm "
                  onChange={(event) => {
                    updateFeedback(
                      feedback,
                      'Timeline is too tight given the deliverables',
                      event.target.value,
                      true,
                      1
                    );
                  }}
                  placeholder="Enter your expected timeline. (eg: 2 days for filming, 3 days for editing)"
                />
              </div>
            ) : null}
            <div className="flex  items-center">
              <Checkbox
                color="primary"
                onChange={(_, isSelect) => {
                  updateFeedback(
                    feedback,
                    'This product does not fit my content/channel',
                    '',
                    isSelect,
                    2
                  );
                }}
              />
              This product does not fit my content/channel
            </div>
            <div className="flex  items-center">
              <Checkbox
                color="primary"
                onChange={(_, isSelect) => {
                  updateFeedback(feedback, 'Other', '', isSelect, 3);
                  setShowOtherInput(!showOtherInput);
                }}
              />
              Other
            </div>
            {showOtherInput ? (
              <div className="flex flex-col items-start border border-zinc-200 rounded-lg p-1 gap-2 ml-10 mb-2">
                <TextareaAutosize
                  minRows={3}
                  className="w-full pl-2 py-2 rounded-md placeholder:text-[#71717a] placeholder:text-sm "
                  onChange={(event) =>
                    updateFeedback(
                      feedback,
                      'Other',
                      event.target.value,
                      true,
                      3
                    )
                  }
                  placeholder="Enter your reason. (eg: I have scheduling conflicts)"
                />
              </div>
            ) : null}
          </div>
        </div>
      </WebModalAndMobileDraw>
    </>
  );
};

export default ModalNotInterest;
