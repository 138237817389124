import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import InvitesCard from '@/components/web/components/invites/InvitesCard';
import { isMobile } from '@/biz/tool';
import { useNavigate } from 'react-router-dom';
import { DealsEmptyIcon, RightSubmitIcon } from '@/components/common/icon';
import axiosInstance from '../../../apis/axiosInstance';

import styles from './dealsHome.module.less';

const DealsHome: React.FC = () => {
  const [invitesList, setInvites] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [actionsList, setActions] = useState([]);
  const navigate = useNavigate();

  // 获取所有actions
  const getActions = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get('/influencer/order');
      const { deals = [], invites = [] } = data?.data || {};
      setInvites(invites);
      setActions(deals);
      setLoading(false);
    } catch (error) {
      setActions([]);
      setLoading(false);
      toast.error(`${error}`);
    }
  };

  // 跳转到invites页
  const handleInvites = () => {
    navigate(`/campaign/invites`);
  };

  useEffect(() => {
    // getNewInvitesList();
    getActions();
  }, []);

  // 空状态
  if (!isLoading && !actionsList?.length) {
    const isHasInvites = !!invitesList?.length;
    return (
      <div className={styles.emptyContainer}>
        {isHasInvites ? <div className={styles.img}></div> : <DealsEmptyIcon />}
        <div className={styles.emptyText}>
          {isHasInvites
            ? 'No order in progress. Go to see invites to start your high cashback journey!'
            : 'No order in progress. Stay tuned for the new invites!'}
        </div>
        {isHasInvites && (
          <div className={styles.seeInvites} onClick={handleInvites}>
            <span>See invites</span>
            <RightSubmitIcon className={styles.rightSubmit} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`${isMobile() ? styles.mobileContainer : styles.container}`}
    >
      {!!actionsList?.length && (
        <div className={styles.cardList}>
          {actionsList?.map((item: any, index) => (
            <InvitesCard
              key={item?._id}
              item={item}
              className={styles.cardItem}
              isDeals={true}
              index={index}
            />
          ))}
        </div>
      )}
      {/* 骨架屏加载动画 */}
      {isLoading && !isMobile() ? (
        <div className="flex flex-col gap-2 w-full mb-10 text-xl text-[#1C2024] font-semibold font-['Geist'] leading-loose">
          <div className="flex gap-8 mb-[40px]">
            <div className="h-[262px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
          <div className="flex gap-8 mb-[40px]">
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
          <div className="flex gap-8 mb-[40px]">
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[200px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
        </div>
      ) : null}
      {isLoading && isMobile() ? (
        <div className="flex flex-col gap-2 w-full mb-10 text-xl text-[#1C2024] font-semibold font-['Geist'] leading-loose">
          <div className="flex flex-col gap-8 mb-[40px]">
            <div className="h-[262px] w-full bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-full bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-full bg-gray-200 rounded-2xl animate-pulse" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DealsHome;
