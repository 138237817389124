import React, { useEffect, useState } from 'react';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { isMobile } from '@/biz/tool';
import WebModal from '@/components/web/components/Modal';
import toast from 'react-hot-toast';
import {
  getJoinCampaignFromUrlSearch,
  getNoQuotaFromUrlSearch,
  getDetailPageUrl
} from '@/biz/tool';
import ModalLoginAndJoinCampaignContent from './ModalLoginAndJoinCampaignContent';
import { useOrderListStore } from '@/hooks/useOrderListStore';

const ModalLoginAndJoinCampaign: React.FC<{}> = () => {
  const { duringFirstOrder } = useDetailDataStore();
  const { setShowFlowGuideModal } = useWebModalStore();
  const {
    showModalJoinSuccess,
    setShowSelectInvitedOrderModal,
    setShowModalJoinSuccess
  } = useWebModalStore();
  const { reQueryOrderListData } = useOrderListStore();

  useEffect(() => {
    //
    if (getJoinCampaignFromUrlSearch()) {
      const url = getDetailPageUrl();
      setTimeout(() => {
        setShowModalJoinSuccess(true);
        // 清除url中的loginSuccess参数
        window.history.pushState({}, '', url);
      }, 0);
      return;
    } else if (getNoQuotaFromUrlSearch()) {
      toast.error(
        'We sincerely apologize, but this campaign has reached its capacity and no longer has available spots. Thank you for your understanding!'
      );
      return;
    } else {
    }
  }, []);

  useEffect(() => {
    if (showModalJoinSuccess) {
      handleShowInvitedList();
    }
  }, [showModalJoinSuccess]);

  const handleShowInvitedList = async () => {
    // 弹窗 请求invited 订单，并弹窗
    const orderListObject = await reQueryOrderListData();
    const { invites = [] } = orderListObject || {};
    if (invites.length > 0) {
      setShowSelectInvitedOrderModal(true);
    }
  };

  return (
    <>
      <WebModal
        visible={showModalJoinSuccess}
        isShowHeader={false}
        isShowFooter={false}
        width={isMobile() ? 342 : 624}
      >
        <div
          className={`w-full flex-col justify-start items-center gap-6 inline-flex`}
        >
          <ModalLoginAndJoinCampaignContent />
          <div className="w-full flex flex-col items-center gap-2">
            {/* {duringFirstOrder ? (
              <>
                <button
                  className={`mui-btn-primary  !h-10
                    ${isMobile() ? '!w-[300px]' : '!w-[400px]'}`}
                  onClick={() => {
                    setShowModalJoinSuccess(false);
                    // setShowFlowGuideModal(true);
                  }}
                >
                  See what’s next
                </button>
                <button
                  className={`mui-btn-primary  !h-10
                    ${isMobile() ? '!w-[300px]' : '!w-[400px]'}`}
                  onClick={() => setShowModalJoinSuccess(false)}
                >
                  Not now
                </button>
              </>
            ) : ( */}
            <button
              className={`mui-btn-primary  !h-10
                ${isMobile() ? '!w-[300px]' : '!w-[400px]'}`}
              onClick={() => setShowModalJoinSuccess(false)}
            >
              Got it
            </button>
            {/* )} */}
          </div>
        </div>
      </WebModal>
    </>
  );
};

export default ModalLoginAndJoinCampaign;
