import React, { useEffect, useState } from 'react';

import SubmitButton from './components/SubmitButton';
import CountDown from './components/CountDown';
import ProgressBar from '@/components/campaign/ProgressBar';
import { isMobile } from '@/biz/tool';
import { OrderStatus } from '@/interface/order';
import { SubmitStatusEnum, ProgressStatusEnum } from './config';
import NoticesBar from '@/components/web/NoticesBar';
import ModalPostponeTimeline from '@/components/web/components/ModalPostponeTimeline';
import dayjs from 'dayjs';

import { formattedDate } from '@/biz/time';
import { PostTimeType } from './config';

interface Props {
  data: any;
  refreshAllData: Function;
  handleRequirement?: Function;
}

const DealStatus: React.FC<Props> = ({
  data: { order, timeline, user, campaign },
  refreshAllData,
  handleRequirement
}) => {
  const isShowButton = () => {
    return [
      OrderStatus.UnderProduction,
      OrderStatus.ReviewRejected,
      OrderStatus.PendingPublish,
      OrderStatus.PublishRejected
    ].includes(order?.status);
  };

  const [submitStatus, setSubmitStatus] = useState('');
  useEffect(() => {
    if (
      [OrderStatus.PendingPublish, OrderStatus.PublishRejected].includes(
        order?.status
      )
    ) {
      setSubmitStatus(SubmitStatusEnum.finalLink);
    } else if (
      [OrderStatus.UnderProduction, OrderStatus.ReviewRejected].includes(
        order?.status
      )
    ) {
      if (order.contentStage === 'Script') {
        setSubmitStatus(SubmitStatusEnum.script);
      } else {
        setSubmitStatus(SubmitStatusEnum.video);
      }
    }
  }, [order?.status]);

  // 0, 到 Script
  // 1, 到 Video
  // 2, 到 Publish
  // 3, 到 Payment
  const getOrderStep = (status: OrderStatus): ProgressStatusEnum => {
    if (
      [
        OrderStatus.Created,
        OrderStatus.Inviting,
        OrderStatus.Invited,
        OrderStatus.CreatorApplied,
        OrderStatus.Cancelled
      ].includes(status)
    )
      return ProgressStatusEnum.SCRIPT;

    if ([OrderStatus.UnderProduction].includes(status)) {
      if (order?.contentStage === 'Script') {
        return ProgressStatusEnum.SCRIPT;
      }
      // return order.version >= 1 ? 2 : 0;
      return ProgressStatusEnum.DRAFT;
    }

    if ([OrderStatus.UnderReview].includes(status)) {
      if (order?.contentStage === 'Script') {
        return ProgressStatusEnum.SCRIPT;
      } else {
        return ProgressStatusEnum.DRAFT;
      }
    }

    if ([OrderStatus.UnderReview].includes(status))
      return ProgressStatusEnum.DRAFT;

    if ([OrderStatus.PendingPublish].includes(status))
      return ProgressStatusEnum.PUBLISH;

    if ([OrderStatus.PublishRejected].includes(status))
      return ProgressStatusEnum.PUBLISH; // 填报链接校验失败

    if ([OrderStatus.PendingPayment].includes(status))
      return ProgressStatusEnum.PAYMENT;

    if (
      [
        OrderStatus.PaymentSubmitted,
        OrderStatus.Paid,
        OrderStatus.PaymentFailed,
        OrderStatus.Completed
      ].includes(status)
    ) {
      return ProgressStatusEnum.OTHER;
    }

    return ProgressStatusEnum.SCRIPT;
  };

  const [stepNum, setStepNum] = useState(ProgressStatusEnum.SCRIPT);

  useEffect(() => {
    if (order?.status) {
      setStepNum(getOrderStep(order.status));
    } else {
      setStepNum(ProgressStatusEnum.SCRIPT);
    }
  }, [order?.status, order?.version]);

  if (
    order?.status === OrderStatus.Invited ||
    order?.status === OrderStatus.Cancelled ||
    order?.status === OrderStatus.ReviewRejected
  ) {
    return null;
  }

  const isFixedTime =
    PostTimeType.FIXED === campaign?.postTimeType &&
    submitStatus === SubmitStatusEnum.finalLink;
  // 是否禁止提交final link
  const isDisabledSubmit =
    isFixedTime &&
    (dayjs().isBefore(campaign?.postTimeRange?.[0]) ||
      dayjs().isAfter(campaign?.postTimeRange?.[1]));
  return (
    <div
      className={`w-full p-6 bg-white rounded-2xl flex-col justify-start items-start gap-6 inline-flex`}
    >
      {isMobile() && <NoticesBar />}
      <div className="self-stretch text-[26px] font-semibold font-['Geist'] leading-10">
        Deal status
      </div>
      {/* 进度条 */}
      <ProgressBar order={order} stepNum={stepNum} />
      {isShowButton() && (
        <div className="self-stretch flex-col justify-center items-center gap-2 flex">
          {/* 提交按钮 */}
          <SubmitButton
            order={order}
            user={user}
            submitStatus={submitStatus}
            refreshAllData={refreshAllData}
            disabled={isDisabledSubmit}
            postTimeType={campaign?.postTimeType}
            postTimeRange={campaign?.postTimeRange}
            handleRequirement={handleRequirement}
          />
          {!isFixedTime && <ModalPostponeTimeline />}
          {/* 倒计时 */}
          {isDisabledSubmit ? (
            <div>
              {`Publish your approved content between ${formattedDate(campaign?.postTimeRange?.[0])} and ${formattedDate(campaign?.postTimeRange?.[1])}`}
            </div>
          ) : (
            <CountDown timeline={timeline} submitStatus={submitStatus} />
          )}
        </div>
      )}
    </div>
  );
};

export default DealStatus;
