import React, { useState, useEffect } from 'react';
import { AddBgIcon } from '@/components/common/icon';
import { normalizePrice } from '@/utils/normalizePrice';

import styles from './index.module.less';

interface Props {
  price: number;
}

const PhysicalPrice: React.FC<Props> = ({ price }) => {
  return (
    <div className={styles.container}>
      <AddBgIcon className={styles.icon} />
      <div className={styles.title}>Product cash value</div>
      <div className={styles.price}>{normalizePrice(price)}</div>
    </div>
  );
};

export default PhysicalPrice;
