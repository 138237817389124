import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '@/apis/axiosInstance';
import toast, { Toaster } from 'react-hot-toast';
import { getConnectErrorPageUrl } from '@/biz/tool';
import { Tracker } from '@/utils';

const PlatformCallbackPage: React.FC = () => {
  const toastRef = useRef('');
  const { platform } = useParams();
  const location = useLocation();

  const handleCallback = async () => {
    const search = new URLSearchParams(location.search);
    const phylloExitReason = search.get('phyllo_exit_reason');

    let payload: any = {};
    const onboarding = search.get('onboarding');
    const phylloUserId = search.get('phyllo_user_id');
    const phylloWorkPlatformId = search.get('work_platform_id');
    const phylloAccountsConnected =
      search.get('phyllo_accounts_connected') || '[]';
    // [{"account_id":"d5317fed-d257-4b8e-884e-e54a6b72494b","work_platform_id":"14d9ddf5-51c6-415e-bde6-f8ed36ad7054"},{"account_id":"6d80b7e5-5e09-41e1-8dd4-86d9d2c9e0b9","work_platform_id":"14d9ddf5-51c6-415e-bde6-f8ed36ad7054"}]

    if (phylloUserId) {
      payload.phylloUserId = phylloUserId;
    }
    if (phylloWorkPlatformId) {
      payload.phylloWorkPlatformId = phylloWorkPlatformId;
    }

    try {
      const accounts = JSON.parse(phylloAccountsConnected);
      if (accounts.length > 0) {
        payload.phylloAccountIds = accounts
          .map((account: any) => account.account_id)
          .filter(Boolean);
      }
    } catch (error) {
      console.error('phylloAccountsConnected parse error', error);
    }

    if (phylloExitReason) {
      if (phylloExitReason === 'TOKEN_EXPIRED') {
        toast.error('token EXPIRED');
        setTimeout(() => {
          window.location.href = getConnectErrorPageUrl({
            platform,
            onboarding,
            type: 'TOKEN_EXPIRED'
          });
        }, 0);
        return;
      } else if (
        phylloExitReason === 'EXIT_FROM_CONNECTION_FAILED' ||
        phylloExitReason === 'BACK_PRESSED'
      ) {
        toast.error('You cancelled the connection.');
        setTimeout(() => {
          window.location.href = getConnectErrorPageUrl({
            platform,
            onboarding,
            type: 'EXIT_FROM_CONNECTION_FAILED'
          });
        }, 0);
        return;
      }
      if (phylloExitReason === 'DONE_CLICKED') {
        // 这是成功 不操作
      }
    }

    if (
      !payload.phylloUserId ||
      !payload.phylloWorkPlatformId ||
      !payload.phylloAccountIds ||
      payload.phylloAccountIds.length === 0
    ) {
      toast.error(`Failed to link to ${platform}`);
      setTimeout(() => {
        window.location.href = getConnectErrorPageUrl({
          platform,
          type: 'FAILED',
          onboarding
        });
      }, 2500);
      return;
    }
    // 达人 是否 onboarding
    payload.influencerOnboarding = Boolean(onboarding === 'true');

    try {
      toastRef.current = toast.loading('Connecting...');
      const {
        data: { code, message, data }
      } = await axiosInstance.post(`/auth/${platform}/callback`, payload);
      toast.dismiss(toastRef.current);
      console.log('toastRef: ', toastRef);
      if (code === 0) {
        toast.success('Successfully connected');
        if (onboarding === 'true') {
          setTimeout(() => {
            window.location.href = '/contactInfo';
          }, 1000);
        } else {
          // 成功 回到首页
          window.location.href = '/';
        }
      } else {
        toast.error(message || 'Failed to auth.');
        // google 上报
        Tracker.click('connect platform failed');
        Tracker.click(`connect platform failed ${platform}`);
        setTimeout(() => {
          window.location.href = getConnectErrorPageUrl({
            platform,
            type: 'FAILED',
            onboarding,
            message: JSON.stringify(message)
          });
        }, 0);
      }
    } catch (error: any) {
      // google 上报
      Tracker.click('connect platform failed');
      Tracker.click(`connect platform failed ${platform}`);
      console.error('platform callback error', error);
      setTimeout(() => {
        let payload: any = {};
        if (error?.message?.includes('failed to get channel info')) {
          console.log('error.payload', error?.payload);
          payload = {
            platform,
            type: 'emptyChannel'
          };
          //
        } else if (
          error?.message?.includes('account has been bound by other user')
        ) {
          console.log('channelConnectedByOthers error.payload', error?.payload);
          payload = {
            emails: error?.payload?.actual?.emails,
            type: 'channelConnectedByOthers'
          };
          //
        }
        //
        window.location.href = getConnectErrorPageUrl({
          platform,
          type: 'FAILED',
          onboarding,
          message: payload?.type ? '' : JSON.stringify(error?.message),
          payload
        });
      }, 0);
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <Toaster
      toastOptions={{
        style: {
          fontSize: 12,
          borderRadius: 4,
          background: '#1C2024',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
          color: '#fff'
        }
      }}
    />
  );
};

export default PlatformCallbackPage;
