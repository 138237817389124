import { create } from 'zustand';
import axiosInstance from '@/apis/axiosInstance';
import toast from 'react-hot-toast';

const reQueryOrderListData = async () => {
  try {
    // 重新进入 置空
    useOrderListStore.setState({ isLoadingData: true });
    // useOrderListStore.setState({ orderListObject: {} });
    //
    const res = await axiosInstance.get('/influencer/order');
    useOrderListStore.setState({ orderListObject: res?.data?.data || {} });
    return res?.data?.data || {};
  } catch (e: any) {
    toast.error(e.message || 'Failed to get data.');
  } finally {
    useOrderListStore.setState({ isLoadingData: false });
  }
};

type OrderListStore = {
  orderListObject: any;
  setOrderListObject: (orderListObject: any) => void;
  isLoadingData: boolean;
  setIsLoadingData: (isLoadingData: boolean) => void;
  reQueryOrderListData: () => Promise<any>;
};

export const useOrderListStore = create<OrderListStore>((set) => ({
  orderListObject: {},
  setOrderListObject: (orderListObject: any) => set({ orderListObject }),
  isLoadingData: true,
  setIsLoadingData: (isLoadingData: boolean) => set({ isLoadingData }),
  reQueryOrderListData: () => reQueryOrderListData()
}));
