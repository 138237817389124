import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type LoginStore = {
  channelAvatar: string;
  setChannelAvatar: (channelAvatar: string) => void;
  channelName: string;
  setChannelName: (channelName: string) => void;
  userEmail: string;
  setUserEmail: (userEmail: string) => void;
  userAddressInfo: any;
  setUserAddressInfo: (userAddressInfo: any) => void;
  clearLoginInfo: () => void;
};

export const useLoginStore = create<LoginStore>()(
  persist(
    (set) => ({
      // 频道头像 频道名称 用户邮箱
      channelAvatar: '',
      setChannelAvatar: (channelAvatar: string) => set({ channelAvatar }),
      channelName: '',
      setChannelName: (channelName: string) => set({ channelName }),
      userEmail: '',
      setUserEmail: (userEmail: string) => set({ userEmail }),
      userAddressInfo: null,
      setUserAddressInfo: (userAddressInfo: any) => set({ userAddressInfo }),
      clearLoginInfo: () =>
        set({
          channelAvatar: '',
          channelName: '',
          userEmail: ''
        })
    }),
    {
      name: 'login-storage'
    }
  )
);
