import React, { useEffect, useState } from 'react';
import styles from './match.module.less';
import { isMobile } from '@/biz/tool';
import axiosInstance from '@/apis/axiosInstance';
import { Suspense } from 'react';
import MatchLottieLoading from '@/components/web/components/matchLottieLoading';

const MATCH_TIMEOUT_SECONDS = 30;

const getLatestInvitedOrder = async () => {
  try {
    const {
      data: { data }
    } = await axiosInstance.get('/influencer/info');
    const matchResult = data?.influencers?.find((influencer: any) => {
      const matchResult = influencer?.onboarding?.matchResult;
      if (matchResult?.length > 0 || matchResult?.length === 0) {
        return matchResult;
      }
    })?.onboarding?.matchResult;
    if (matchResult?.length > 0) {
      console.log(
        `匹配完成, 匹配到 ${matchResult?.length} 个活动, campaignIds: ${matchResult?.join(', ')}`
      );
      window.location.href = `/campaign/invites/detail/${matchResult[0]}`;
    } else if (matchResult?.length === 0) {
      console.log(`匹配完成, 没有匹配到活动`);
      window.location.href = '/campaign/error?type=zeroMatched';
    } else {
      console.log(`匹配未完成 或 有错误`);
      window.location.href = '/campaign/error?type=zeroMatched';
    }
  } catch (error) {
    window.location.href = '/campaign/error?type=zeroMatched';
  }
};

const MatchPage: React.FC = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        const newTime = prevTime + 0.1;
        if (Math.abs((Math.round(newTime * 10) / 10) % 10) < 0.1) {
          console.log('Current time:', new Date().toLocaleString());
          getLatestInvitedOrder();
        }
        return newTime;
      });
    }, 100);

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
    >
      <div className={styles.wrapper}>
        {!isMobile() && (
          <a className={styles.exit} href="/">
            <div className={styles.btn}>
              <img src="/c-arrow-left-small.svg" alt="" />
              Exit
            </div>
          </a>
        )}
        <div className={styles.main}>
          <div className={styles.animation}>
            <Suspense fallback={<div>Loading...</div>}>
              <MatchLottieLoading />
              {/* <Canvas>
                <Item />
              </Canvas> */}
            </Suspense>
          </div>
          <div className={styles.time}>
            <div>
              {time.toFixed(1)}/{MATCH_TIMEOUT_SECONDS}
            </div>
            <div className={styles.unit}>s</div>
          </div>
          <div className={styles.desc}>
            Hang tight! We’re finding the perfect deal for your channel...
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchPage;
