import {
  XPlatformIcon,
  InstagramPlatformIcon,
  TiktokPlatformIcon,
  YoutubePlatformIcon
} from '@/components/common/icon';
import toast from 'react-hot-toast';
import { AdsPlatform } from '@/interface/order';
import {
  youtubeLinkValidator,
  tiktokLinkValidator,
  instagramReelsLinkValidator,
  twitterLinkValidator,
  linkValidator
} from '@/biz/validator';
import {
  submitModalDescDefault,
  submitModalDescYoutube,
  submitModalDescTiktok,
  submitModalDescInstagram
} from '@/components/web/config';

export const getPlatformIcon = (platform: string) => {
  switch (platform) {
    case 'youtube':
      return <YoutubePlatformIcon />;
    case 'tiktok':
      return <TiktokPlatformIcon />;
    case 'instagram':
      return <InstagramPlatformIcon />;
    case 'twitter':
      return <XPlatformIcon />;
    default:
      return <div />;
  }
};

export const getPlatformName = (platform: string) => {
  switch (platform) {
    case 'youtube':
      return 'YouTube';
    case 'tiktok':
      return 'Tiktok';
    case 'instagram':
      return 'Instagram';
    case 'twitter':
      return 'Twitter';
    default:
      return platform;
  }
};

export const getPlatformVideoContentType = ({
  platform,
  contentType
}: {
  platform: string;
  contentType?: string;
}) => {
  if (contentType === 'Dedicated video' || contentType === 'Integrated video') {
    return contentType;
  }
  //
  switch (platform) {
    case 'youtube':
      return 'Dedicated video';
    case 'tiktok':
      return 'Tiktok video';
    case 'instagram':
      return 'Reels';
    case 'twitter':
      return 'Post';
    default:
      return platform;
  }
};

export const PlatformIconComponent: React.FC<{
  platform: string;
  className?: string;
}> = ({ platform, className }) => {
  switch (platform) {
    case 'youtube':
      return <YoutubePlatformIcon className={className} />;
    case 'tiktok':
      return <TiktokPlatformIcon className={className} />;
    case 'instagram':
      return <InstagramPlatformIcon className={className} />;
    case 'twitter':
      return <XPlatformIcon className={className} />;
    default:
      return <div />;
  }
};

export const checkPhylloConfig = (config: any) => {
  // 参数校验
  if (!config.clientDisplayName) {
    toast.error(
      'The clientDisplayName is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.environment) {
    toast.error(
      'The environment is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.userId) {
    toast.error(
      'The userId is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.token) {
    toast.error(
      'The token is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (config.redirect === true && !config.redirectURL) {
    toast.error(
      'The redirectURL is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.workPlatformId) {
    toast.error(
      'The workPlatformId is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  return true;
};

export const platformDraftLinkValidator = (url: string, platform: string) => {
  if (platform === AdsPlatform.Youtube) {
    return youtubeLinkValidator(url);
  }
  return linkValidator(url);
};

export const platformFinalLinkValidator = (url: string, platform: string) => {
  if (platform === AdsPlatform.Youtube) {
    return youtubeLinkValidator(url);
  }
  if (platform === AdsPlatform.TikTok) {
    return tiktokLinkValidator(url);
  }
  if (platform === AdsPlatform.Instagram) {
    return instagramReelsLinkValidator(url);
  }
  if (platform === AdsPlatform.Twitter) {
    return twitterLinkValidator(url);
  }
  return linkValidator(url);
};

export const getSubmitModalDesc = (platform: string) => {
  if (platform === AdsPlatform.Youtube) {
    return submitModalDescYoutube;
  } else if (platform === AdsPlatform.TikTok) {
    return submitModalDescTiktok;
  } else if (platform === AdsPlatform.Instagram) {
    return submitModalDescInstagram;
  }
  return submitModalDescDefault;
};
