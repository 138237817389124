import React from 'react';
import { StarIcon } from '../common/icon';
import { isMobile } from '@/biz/tool';
import { getShowScript } from '@/biz/order';
import styles from './progressBar.module.less';

interface Props {
  stepNum: number;
  items?: string[];
  order?: any;
}

export const ProgressBar: React.FC<Props> = ({
  stepNum,
  items = ['Script', 'Video', 'Publish', 'Payment'],
  order
}) => {
  const showScript = getShowScript(order);

  return (
    <div
      className={`${styles.container} ${!isMobile() ? styles.pcContainer : ''}`}
    >
      {items.map((item, index) => {
        if (!showScript && index === 0) {
          return null;
        }

        return (
          <div
            key={item}
            className={`${styles.item}
              ${index < stepNum ? styles.activeItem : ''}
              ${index === stepNum ? styles.focusItem : ''}
              `}
          >
            <div className={styles.content}>
              <div className={styles.circle}>
                {index + 1 === items.length ? (
                  <StarIcon
                    className={` w-[14px] h-[14px] m-[5px] ${styles.icon}`}
                  />
                ) : (
                  <span>{showScript ? index + 1 : index}</span>
                )}
              </div>
              <div className={styles.text}>{item}</div>
            </div>
            {index + 1 !== items.length && (
              <div className={showScript ? styles.line : styles.lineLong}>
                <div className={styles.lineItem}></div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
