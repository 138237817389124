import React, { useEffect, useState } from 'react';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { growthbook } from '@/index';
import axiosInstance from '../../apis/axiosInstance';
import { datafluxRum } from '@cloudcare/browser-rum';
import { fixedDetailBottomHeight } from '../../utils';
import toast from 'react-hot-toast';
import { getOrderId, hasClerkUser, getJoinCampaign } from '@/biz/tool';
import '../../components/campaign/custom.less';
import DetailPageWeb from '@/components/web/DetailPageWeb';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import ModalSelectInvitedOrder from '@/components/web/components/ModalSelectInvitedOrder';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import {
  useMultiplePricesStore,
  GbPricingSchemaFlag
} from '@/hooks/useMultiplePricesStore';
import { useOrderListStore } from '@/hooks/useOrderListStore';

const DetailPage: React.FC = () => {
  const { detailData, setDuringFirstOrder, reQueryDetailData } =
    useDetailDataStore();
  const { setShowSelectInvitedOrderModal } = useWebModalStore();
  const orderId = getOrderId();
  const { setGbPricingSchemaFlag } = useMultiplePricesStore();
  const [data, setData] = useState<any>({});
  const { reQueryOrderListData } = useOrderListStore();
  // growthbook
  const pricing_schema_value = useFeatureValue(
    'pricing_schema',
    'display_price_cannot_change'
  );

  useEffect(() => {
    // console.log(`aha-GbPricingSchemaFlag: value: ${value}`);
    setGbPricingSchemaFlag(pricing_schema_value as GbPricingSchemaFlag);
  }, [pricing_schema_value]);

  useEffect(() => {
    setData(detailData);
    if (detailData?.user?.duringFirstOrder) {
      setDuringFirstOrder(true);
    } else {
      setDuringFirstOrder(false);
    }
    // // 测试 先设为 true
    // setDuringFirstOrder(true);
  }, [detailData]);

  useEffect(() => {
    //
    afterJoinCampaign();
    //
    const handleScroll = () => {
      const tabCtn = document.getElementById('tabCtn');
      const target = document.getElementById('detailTab');

      if (tabCtn && target) {
        const top = tabCtn.getBoundingClientRect().top;

        if (top < 5) {
          target.style.position = 'fixed';
        } else {
          target.style.position = 'relative';
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (orderId) {
      getData(orderId);
    } else {
      setTimeout(() => {
        toast.error(
          'Unable to find this order, please contact customer service.'
        );
      }, 0);
    }
  }, [orderId]);

  useEffect(() => {
    if (data?.influencer) {
      let lastUsedTimezone = data?.influencer?.lastUsedTimezone;
      console.log(`lastUsedTimezone: ${lastUsedTimezone}`);
      let timezone: any;
      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(`timezone: ${timezone}`);
      } catch (e) {
        console.error('Fail to get timezone', e);
        const error = new Error('MOBILE: Fail to get timezone');
        datafluxRum && datafluxRum.addError(error);
      }
      if (hasClerkUser() && timezone && timezone !== lastUsedTimezone) {
        console.log('updating timezone');
        axiosInstance
          .post(`/order/${orderId}/updateInfluencerTimezone`, { timezone })
          .catch((e) => {
            console.error('Fail to update timezone', e);
            const error = new Error('MOBILE: Fail to update timezone');
            datafluxRum && datafluxRum.addError(error);
          });
      }
    }
  }, [data?.influencer, orderId]);

  const afterJoinCampaign = async () => {
    // 这里要弹窗 请求invited 订单，并弹窗
    const joinCampaign: any = getJoinCampaign();
    console.log('joinCampaign', joinCampaign);
    // 这里要弹窗 请求invited 订单，并弹窗
    if (joinCampaign) {
      // 弹窗 请求invited 订单，并弹窗
      const orderListObject = await reQueryOrderListData();
      const { invites = [] } = orderListObject || {};
      if (invites.length > 0) {
        setShowSelectInvitedOrderModal(true);
      }
    }
  };

  const getData = async (orderId: string) => {
    await reQueryDetailData(orderId);
  };

  fixedDetailBottomHeight();

  return (
    <>
      <DetailPageWeb data={data} orderId={orderId} refreshAllData={getData} />
      <ModalSelectInvitedOrder />
    </>
  );
};

export default DetailPage;
