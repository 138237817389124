import React, { useEffect } from 'react';
import HomeContent from './web/home';

const Home: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const vconsole = searchParams.get('vconsole') === 'true' ? 'true' : 'false';
  window.localStorage.setItem('vconsole', vconsole);

  // useEffect(() => {
  //   window.location.href = 'https://ahalab.io/';
  // }, []);

  if (process.env.REACT_APP_ENV !== 'production' && vconsole === 'true') {
    return <div className="">vconsole 开启成功</div>;
  }
  return <HomeContent />;
};

export default Home;
